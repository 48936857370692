import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full lg:w-[360px]" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppText = _resolveComponent("AppText")!
  const _component_vue_qrcode = _resolveComponent("vue-qrcode")!
  const _component_AppButton = _resolveComponent("AppButton")!

  return (_openBlock(), _createElementBlock("form", {
    onSubmit: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.$emit('continue')), ["prevent"]))
  }, [
    _createVNode(_component_AppText, {
      tag: "h1",
      size: _ctx.$appTextProps.TextSize['text-3-bold']
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('auth.twofa.enable_2fa.title')), 1)
      ]),
      _: 1
    }, 8, ["size"]),
    _createVNode(_component_AppText, { class: "mt-40" }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('auth.twofa.enable_2fa.description')), 1)
      ]),
      _: 1
    }),
    (_ctx.otpauthUrl)
      ? (_openBlock(), _createBlock(_component_vue_qrcode, {
          key: 0,
          "data-testid": "qr-code",
          id: _ctx.otpauthUrl,
          value: _ctx.otpauthUrl,
          options: { width: 215 }
        }, null, 8, ["id", "value"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_AppButton, {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('continue'))),
        class: "mt-16 mb-16",
        label: _ctx.$t('auth.twofa.actions.continue'),
        testId: "btn-continue",
        wide: ""
      }, null, 8, ["label"]),
      _createVNode(_component_AppButton, {
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('addManually'))),
        label: _ctx.$t('auth.twofa.enable_2fa.add_manually'),
        testId: "btn-add-manually",
        version: _ctx.$appButtonProps.Version.secondary,
        wide: ""
      }, null, 8, ["label", "version"])
    ])
  ], 32))
}