import i18n from '@Libraries/translations/index';
import { mutedErrorMessages } from './mutedErrors';

const _isMuted = (errorCode: string) => {
  return mutedErrorMessages.findIndex((message) => message === errorCode) !== -1;
};

const getErrorMessage = (errorCode: string, moduleName?: string): string => {
  if (!errorCode) {
    return i18n.global.t('errors.generic');
  }

  if (_isMuted(errorCode)) {
    return 'Unknown Error';
  }

  if (moduleName && i18n.global.te(`${moduleName}.errors.${errorCode}`)) {
    return i18n.global.t(`${moduleName}.errors.${errorCode}`);
  }

  if (i18n.global.te('errors.' + errorCode)) {
    return i18n.global.t('errors.' + errorCode);
  }

  return i18n.global.t('errors.generic');
};

export default getErrorMessage;
