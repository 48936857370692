
import { defineComponent } from 'vue';
import { BaseInput, inputProps } from '@BaseUi';

export default defineComponent({
  name: 'InputPassword',

  setup() {
    return {
      inputProps,
    };
  },

  components: {
    BaseInput,
  },

  props: {
    input: {
      type: String,
      default: '',
    },

    label: {
      type: String,
      required: true,
    },
  },

  emits: ['update'],

  data() {
    return {
      currentInput: '',
      isText: false,
    };
  },

  computed: {
    inputType() {
      return this.isText ? inputProps.Type.text : inputProps.Type.password;
    },

    toggleLabel() {
      const action = this.isText ? 'hide' : 'show';

      return this.$t(`action.${action}`);
    },
  },

  watch: {
    input(value) {
      if (this.currentInput !== value) {
        this.currentInput = value;
      }
    },

    currentInput(value) {
      this.$emit('update', value);
    },
  },

  methods: {
    toggleInputType() {
      this.isText = !this.isText;
    },
  },
});
