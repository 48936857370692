
import { defineComponent } from 'vue';
import BaseModal from '@/components/base/BaseModal/BaseModal.vue';

export default defineComponent({
  name: 'LoginConfirmationModal',

  components: { BaseModal },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },

    testId: {
      type: String,
      default: 'log-out',
    },
  },

  emits: ['cancel', 'onLogout'],
});
