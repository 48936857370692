import { storeToRefs } from 'pinia';
import { useWalletStore } from '../store';
import { useOperatorStore } from '@Modules/Operator';

export default function useOperatorHasVerifiedWallet() {
  const { wallets } = storeToRefs(useOperatorStore());
  const { currentAccount } = storeToRefs(useWalletStore());

  function hasVerifiedWallet() {
    const verifiedWallets = wallets.value.map((data) => data.address);
    const currentWalletAddress = currentAccount.value?.address;

    const hasVerifiedWallets = verifiedWallets.length;
    const hasValidAddress = !!currentWalletAddress;
    const currentWalletAddressIsVerified = hasValidAddress
      ? !!verifiedWallets.find(
          (wallet) => wallet.toLowerCase() === currentWalletAddress.toLowerCase()
        )
      : false;

    return hasVerifiedWallets && hasValidAddress && currentWalletAddressIsVerified;
  }

  return {
    hasVerifiedWallet,
  };
}
