import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4baaa2d0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tab-selector" }
const _hoisted_2 = { class: "primary-list" }
const _hoisted_3 = ["data-testid", "onClick"]
const _hoisted_4 = {
  key: 0,
  class: "secondary-list"
}
const _hoisted_5 = ["data-testid", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabsList, (item, i) => {
        return (_openBlock(), _createElementBlock("div", {
          class: _normalizeClass(["primary-list-item", {
          ..._ctx.defaultClasses,
          selected: _ctx.currentTab === item,
        }]),
          "data-testid": `primary-tab:${item}`,
          key: i,
          onClick: ($event: any) => (_ctx.handleClick(item))
        }, _toDisplayString(_ctx.$t(`tabs.${item}`)), 11, _hoisted_3))
      }), 128))
    ]),
    (_ctx.secondaryTabsList)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.secondaryTabsList, (item, i) => {
            return (_openBlock(), _createElementBlock("div", {
              class: _normalizeClass(["secondary-list-item", {
          selected: _ctx.currentSecondaryTab === item,
        }]),
              "data-testid": `secondary-tab:${item}`,
              key: i,
              onClick: ($event: any) => (_ctx.handleClick(item, false))
            }, _toDisplayString(_ctx.$t(`tabs.${item}`)), 11, _hoisted_5))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ]))
}