
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'YourSecureKey',

  emits: ['continue', 'copycode', 'error'],

  props: {
    secret: {
      type: String,
      required: true,
    },
    recovery: {
      type: String,
      required: true,
    },
  },

  methods: {
    unsafeCopyAction() {
      const textArea = document.createElement('textarea');
      textArea.value = this.secret;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      try {
        document.execCommand('copy');
      } catch (err) {
        console.error('Unable to copy to clipboard', err);
      }
      document.body.removeChild(textArea);
    },

    copyAction() {
      if (!navigator.clipboard) {
        this.unsafeCopyAction();
        return;
      }
      navigator.clipboard.writeText(this.secret);
      this.$emit('copycode', { message: this.$t('auth.twofa.enable_2fa.key_copy') });
    },
  },

  mounted() {
    if (!this.recovery || !this.secret) {
      this.$emit('error', {
        message: undefined,
        isRedirect: true,
      });
    }
  },
});
