enum IconPosition {
  left = 'left',
  right = 'right',
}

enum Size {
  small = 'small',
  medium = 'medium',
  large = 'large',
}

enum Version {
  primary = 'primary',
  secondary = 'secondary',
  tertiary = 'tertiary',
  destructive = 'destructive',
}

const appButtonProps = {
  IconPosition,
  Size,
  Version,
};

export { appButtonProps, IconPosition, Size, Version };
