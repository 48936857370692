import { apollo, DibbsApiException, DibbsErrorHelper, errorHandler } from '@/utils/helpers/api';

import KYC_UPLOAD_PERSONAL_ID from '../graphql/mutations/KycUploadPersonalId.gql';

interface Params {
  front: File;
  back?: File;
}

interface Result {
  kyc_upload_doc: {
    id: string;
  };
}

async function uploadPersonalId({ front, back }: Params): Promise<Result['kyc_upload_doc']> {
  return apollo
    .mutate<Result>({
      mutation: KYC_UPLOAD_PERSONAL_ID,
      variables: { front, back },
      context: {
        hasUpload: true,
      },
    })
    .then(({ data }) => DibbsErrorHelper(data?.kyc_upload_doc))
    .catch((error) => {
      throw new DibbsApiException(errorHandler(error));
    });
}

export default uploadPersonalId;
