export enum AVAILABLE_STEPS {
  INDEX = 'index',
  SELECTOR = 'selector',
  UNAVAILABLE = 'unavailable',
}

export interface ICountry {
  name: string;
  dialCode: string;
  code: string;
}

export interface IKycCountryData {
  AVAILABLE_STEPS: typeof AVAILABLE_STEPS;
  countries: Array<ICountry>;
  selectedCountry: string;
  step: string;
}
