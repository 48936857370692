import { $vfm, DynamicModalOptions } from 'vue-final-modal';
import { BaseModal, BasePrompt } from '@BaseUi';
import { ModalOptions, PromptOptions } from './types';

export const showModal = (
  key: string,
  options: ModalOptions,
  component?: DynamicModalOptions['component']
) => {
  $vfm.show({
    component: component || BaseModal,
    ...options,
    bind: {
      ...options.bind,
      name: key,
    },
    on: {
      ...options.on,
      cancel: () => {
        hideModal(key);
      },
    },
  });
};

export const showPrompt = (
  key: string,
  options: PromptOptions,
  component?: DynamicModalOptions['component']
) => {
  $vfm.show({
    component: component || BasePrompt,
    ...options,
    bind: {
      ...options.bind,
      name: key,
    },
    on: {
      ...options.on,
      cancel: options.on?.cancel || (() => hideModal(key)),
    },
  });
};

export const hideModal = (key: string) => {
  $vfm.hide(key);
};
export const hidePrompt = hideModal;

export const hideAllModals = () => $vfm.hideAll();
export const hideAllPrompts = hideAllModals;

export default {
  showModal,
  hideAllModals,
  hideModal,
  showPrompt,
  hidePrompt,
  hideAllPrompts,
};
