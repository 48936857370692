import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_KycAddressForm = _resolveComponent("KycAddressForm")!

  return (_openBlock(), _createBlock(_component_KycAddressForm, {
    modelValue: _ctx.form,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form) = $event)),
    googleAutocompleteService: _ctx.googleAutocompleteService,
    googleGeocoder: _ctx.googleGeocoder,
    showOnlyInputs: _ctx.showOnlyInputs,
    isLoading: _ctx.isLoading,
    type: _ctx.type,
    readonly: _ctx.readonly,
    onSetAddress: _ctx.setAddress
  }, null, 8, ["modelValue", "googleAutocompleteService", "googleGeocoder", "showOnlyInputs", "isLoading", "type", "readonly", "onSetAddress"]))
}