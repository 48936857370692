import { storeToRefs } from 'pinia';
import { useAuthStore } from '@Modules/Auth';
import { useOperatorStore } from '@Modules/Operator';

export default function () {
  const { isAuthenticated } = storeToRefs(useAuthStore());
  const { email } = storeToRefs(useOperatorStore());

  const isOperatorMissing = !isAuthenticated?.value || !email.value;

  return {
    isOperatorMissing,
  };
}
