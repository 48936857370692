import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppText = _resolveComponent("AppText")!
  const _component_NFTLineCard = _resolveComponent("NFTLineCard")!
  const _component_WarningBox = _resolveComponent("WarningBox")!
  const _component_AppButton = _resolveComponent("AppButton")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_AppText, {
      tag: "h1",
      size: _ctx.$appTextProps.TextSize['text-3-bold']
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('crypto.redemption.burn_nft_withdraw.title')), 1)
      ]),
      _: 1
    }, 8, ["size"]),
    (_ctx.redemptionItem)
      ? (_openBlock(), _createBlock(_component_NFTLineCard, {
          key: 0,
          class: "mb-32 mt-48",
          title: _ctx.redemptionItem.item.name,
          imageUrl: _ctx.redemptionItem.item.video_url,
          loading: _ctx.loading,
          darkerBackground: true
        }, null, 8, ["title", "imageUrl", "loading"]))
      : _createCommentVNode("", true),
    _createVNode(_component_WarningBox, null, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('crypto.redemption.burn_nft_withdraw.warning')), 1)
      ]),
      _: 1
    }),
    (_openBlock(), _createElementBlock(_Fragment, null, _renderList(3, (n, i) => {
      return _createVNode(_component_AppText, {
        key: n,
        loading: _ctx.loading,
        class: "mt-16"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t(`crypto.redemption.burn_nft_withdraw.description.${i}`)), 1)
        ]),
        _: 2
      }, 1032, ["loading"])
    }), 64)),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_AppButton, {
        class: "mt-32 w-[300px] mr-20",
        testId: "burn-token",
        label: _ctx.$t('crypto.redemption.burn_nft_withdraw.burn_token'),
        loading: _ctx.loading,
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('burnToken')))
      }, null, 8, ["label", "loading"]),
      _createVNode(_component_AppButton, {
        class: "mt-32 w-[300px] ml-20",
        testId: "cancel-withdraw",
        label: _ctx.$t('crypto.redemption.burn_nft_withdraw.cancel_withdraw'),
        loading: _ctx.loading,
        version: _ctx.$appButtonProps.Version.secondary,
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('cancelWithdraw')))
      }, null, 8, ["label", "loading", "version"])
    ])
  ]))
}