
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'NavbarButton',

  props: {
    isExternal: {
      type: Boolean,
      default: false,
    },
    label: String,
    linkTo: {
      type: String,
      required: true,
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    borderColor() {
      return this.selected ? 'border-border-active-nav' : 'border-transparent';
    },

    classes() {
      return {
        [this.borderColor]: true,
        ['border-b-2']: true,
        ['cursor-pointer']: true,
        ['default-transition']: true,
        ['flex']: true,
        ['h-64']: true,
        ['hover:opacity-80']: true,
        ['items-center']: true,
        ['leading-md']: true,
        ['text-5-medium']: true,
        ['text-text-body']: true,
        ['w-fit-content']: true,
      };
    },
  },
});
