import { defineStore } from 'pinia';

export interface State {
  isAuthenticated?: boolean;
}

export const useAuthStore = defineStore('auth', {
  state: (): State => ({
    isAuthenticated: false,
  }),
});
