import { apollo, DibbsApiException, DibbsErrorHelper, errorHandler } from '@/utils/helpers/api';
import MUTATION from '../services/mutations/Logout.mutation.gql';

interface Result {
  logout: boolean;
}

export default async function (): Promise<Result['logout']> {
  return apollo
    .mutate<Result>({
      mutation: MUTATION,
    })
    .then(({ data }) => DibbsErrorHelper(data?.logout))
    .then((data) => data)
    .catch((error) => {
      throw new DibbsApiException(errorHandler(error));
    });
}
