enum RouterGuardExceptionTypes {
  LOGOUT = 'logout',
  REDIRECTED = 'redirect',
}

interface RouteGuardError {
  name: string;
  type?: RouterGuardExceptionTypes;
  redirectUrl?: string;
}

interface ValidationError {
  type?: RouterGuardExceptionTypes;
  redirectUrl?: string;
}

export { RouterGuardExceptionTypes, RouteGuardError, ValidationError };
