export const applySSNMask = (ssn: string) => {
  if (ssn.length < 4) {
    return ssn;
  }

  const areaCode = ssn.substring(0, 3);
  const centralOfficeCode = ssn.substring(3, 5);

  if (ssn.length < 6) {
    return `${areaCode} - ${centralOfficeCode}`;
  }

  const stationCode = ssn.substring(5, 9);

  return `${areaCode} - ${centralOfficeCode} - ${stationCode}`;
};
