
import { defineComponent } from 'vue';
import { storeToRefs } from 'pinia';
import { hidePrompt, showPrompt } from '@/utils/helpers/modal';
import { BaseInput, inputProps } from '@BaseUi';
import { verify2FAEmailDisableCode } from '@Modules/Auth/modules/TwoFactorAuth';
import { useOperatorStore } from '@Modules/Operator';

export default defineComponent({
  name: 'DisableTwofaEmail',

  components: { BaseInput },

  emits: ['error', 'resendEmail'],

  setup() {
    const { isTwofaEnabled, twofaEnabled } = storeToRefs(useOperatorStore());

    return {
      inputProps,
      isTwofaEnabled,
      twofaEnabled,
    };
  },

  data() {
    return {
      secret: '',
      isLoading: false,
    };
  },

  watch: {
    twofa(newValue, oldValue) {
      if (newValue.length > 6) {
        this.secret = oldValue;
      }
    },
  },

  methods: {
    submit() {
      if (this.isTwofaEnabled) {
        return this.continueDeactivating();
      }
    },

    async continueDeactivating() {
      this.isLoading = true;

      try {
        await verify2FAEmailDisableCode(this.secret.toString());
        this.showDisableSuccessPrompt();
      } catch (err) {
        this.$emit('error', {
          message: this.$t('auth.twofa.invalid_2fa_code'),
        });
      } finally {
        this.isLoading = false;
      }
    },

    finish2FA(newTwofaStatus: boolean) {
      this.twofaEnabled = newTwofaStatus;
      this.$router.push('/security');
    },

    showDisableSuccessPrompt() {
      let vm = this;
      showPrompt('twofaHasBeenDisable', {
        bind: {
          title: this.$t('auth.twofa.disabled'),
          message: this.$t('auth.twofa.twofa_has_been_disabled'),
          isSuccess: true,
          customConfirm: this.$t('auth.twofa.actions.close'),
        },
        on: {
          confirm: () => {
            hidePrompt('twofaHasBeenDisable');
          },
          beforeClose() {
            vm.finish2FA(false);
          },
        },
      });
    },
  },

  computed: {
    shoudBeInactive() {
      return this.isLoading || !this.secret || this.secret.toString().length < 6;
    },
  },
});
