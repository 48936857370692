import ChangePasswordRoutes from '@Views/ChangePasswordView/routes';
import ConfirmEmailRoutes from '@Views/ConfirmEmailView/routes';
import CryptoViewsRoutes from '@Views/CryptoView/routes';
import DeleteAccountViewsRoutes from '@Views/DeleteAccountView/routes';
import DueDiligenceRoutes from '@Views/DueDiligenceView/routes';
import LoginViewRoutes from '@Views/LoginView/routes';
import ProfileViewRoutes from '@Views/ProfileView/routes';
import RedemptionViewsRoutes from '@Views/RedemptionsView/routes';
import RegistryViewRoutes from '@Views/RegistryView/routes';
import RegistrationViewRoutes from '@Views/RegistrationView/routes';
import ResetPasswordRoutes from '@Views/ResetPasswordView/routes';
import SecurityViewsRoutes from '@Views/SecurityView/routes';
import SignUpRoutes from '@Views/SignupView/routes';
import SupportViewsRoutes from '@Views/SupportView/routes';
import TwofaViewsRoutes from '@Views/TwofaView/routes';
import UpdateOperatorsAddressRoutes from '@Views/UpdateOperatorAddressView/routes';

export default [
  ...ChangePasswordRoutes,
  ...ConfirmEmailRoutes,
  ...CryptoViewsRoutes,
  ...DeleteAccountViewsRoutes,
  ...DueDiligenceRoutes,
  ...LoginViewRoutes,
  ...RedemptionViewsRoutes,
  ...RegistryViewRoutes,
  ...RegistrationViewRoutes,
  ...ResetPasswordRoutes,
  ...SecurityViewsRoutes,
  ...SignUpRoutes,
  ...SupportViewsRoutes,
  ...TwofaViewsRoutes,
  ...UpdateOperatorsAddressRoutes,
  ...ProfileViewRoutes,
];
