
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ToggleButton',

  props: {
    testId: {
      type: String,
      default: null,
    },

    checked: {
      type: Boolean,
      required: true,
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    onChange(event: Event) {
      const target = event.target as HTMLInputElement;

      this.$emit('change', target.checked);
    },
  },
});
