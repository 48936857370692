import { createApp } from 'vue';
import { vfmPlugin } from 'vue-final-modal';
import App from './App.vue';
import { i18n, router, pinia } from './libraries';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import VueQrcode from '@chenfengyuan/vue-qrcode';
import '@Libraries/font-awesome';
import '@Libraries/router/routes';
import Plugins from '@/plugins';
const app = createApp(App);

Plugins.forEach((plugin) => app.use(plugin));

app
  .use(i18n)
  .use(router)
  .use(pinia)
  .use(vfmPlugin())
  .component('font-awesome-icon', FontAwesomeIcon)
  .component(`VueQrcode.name`, VueQrcode)
  .mount('#app');
