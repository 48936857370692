enum Sizes {
  compact = 'compact',
  invisible = 'invisible',
  normal = 'normal',
}

const captchaProps = {
  Sizes,
};

export { Sizes, captchaProps };
