import { apollo, DibbsApiException, DibbsErrorHelper, errorHandler } from '@/utils/helpers/api';

import KYC_UPLOAD_PROOF_OF_ADDRESS from '../graphql/mutations/KycUploadProofOfAddress.gql';

interface Params {
  file: File;
}

interface Result {
  kyc_upload_doc: {
    id: string;
  };
}

async function uploadProofOfAddress({ file }: Params): Promise<Result['kyc_upload_doc']> {
  return apollo
    .mutate<Result>({
      mutation: KYC_UPLOAD_PROOF_OF_ADDRESS,
      variables: { file },
      context: {
        hasUpload: true,
      },
    })
    .then(({ data }) => DibbsErrorHelper(data?.kyc_upload_doc))
    .catch((error) => {
      throw new DibbsApiException(errorHandler(error));
    });
}

export default uploadProofOfAddress;
