import { apollo, DibbsApiException, DibbsErrorHelper, errorHandler } from '@/utils/helpers/api';

import TWO_FACTOR_AUTH_SETUP_STEP_ONE from '../graphql/mutations/TwoFactorAuthSetupStepOne.gql';

interface Result {
  twofa_setup_step1: {
    result: true;
  };
}

async function sentEmail2FAStepOne(): Promise<Result['twofa_setup_step1']> {
  return apollo
    .mutate<Result>({
      mutation: TWO_FACTOR_AUTH_SETUP_STEP_ONE,
    })
    .then(({ data }) => DibbsErrorHelper(data?.twofa_setup_step1))
    .then((data) => data)
    .catch((error) => {
      throw new DibbsApiException(errorHandler(error));
    });
}

export default sentEmail2FAStepOne;
