import { useOperatorStore, OperatorState } from '../store';
import { ICurrentOperator } from '../types';
import { ACCOUNT_TYPE } from '@Shared/enums/accountType';

export default function useFillCurrentOperator() {
  const operatorStore = useOperatorStore();

  function fillCurrentOperator(operator: ICurrentOperator) {
    const {
      account_id,
      dd_pending_question,
      email,
      email_verified,
      kyc_state,
      operator_id,
      twofa_enabled,
      account,
    } = operator;

    const address = operator.kyc_pii.address;

    let newOperator: OperatorState = {
      address,
      email,
      wallets: account.wallets,
      accountId: account_id,
      accountStatus: account.status,
      accountType: account.type,
      ddPendingQuestion: {
        questionId: dd_pending_question?.question_id,
      },
      emailVerified: email_verified,
      operatorId: operator_id,
      twofaEnabled: twofa_enabled,
      kycState: {
        status: kyc_state?.status,
        steps: kyc_state?.steps,
      },
      pickedAddress: '',
    };

    if (ACCOUNT_TYPE.INDIVIDUAL) {
      const { birthday, name, phone, first_name, sur_name, middle_name } =
        operator.kyc_pii.individual;

      newOperator = {
        ...newOperator,
        birthday,
        name,
        firstName: first_name,
        lastName: sur_name,
        middleName: middle_name,
        phone,
      };
    }

    operatorStore.$patch(newOperator);
  }

  return {
    fillCurrentOperator,
  };
}
