import { apollo, DibbsApiException, DibbsErrorHelper, errorHandler } from '@/utils/helpers/api';

import WALLET_VERIFY_SIGNATURE from '../graphql/mutations/WalletVerifySignature.gql';

interface MutationResult {
  wallet_verify_code: {
    data: string;
    id: {
      result: string;
    };
  };
}

interface Result {
  signatureMessage: string;
  verificationCode: string;
}

export async function getSignatureMessageRequest(walletAddress: string): Promise<Result> {
  return apollo
    .query<MutationResult>({
      query: WALLET_VERIFY_SIGNATURE,
      variables: {
        address: walletAddress,
      },
    })
    .then(({ data }) => {
      return DibbsErrorHelper(data?.wallet_verify_code);
    })
    .then((data) => {
      return {
        signatureMessage: data.data,
        verificationCode: data.id.result,
      };
    })
    .catch((error) => {
      throw new DibbsApiException(errorHandler(error));
    });
}

export default getSignatureMessageRequest;
