import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex justify-center w-full" }
const _hoisted_2 = { class: "w-full lg:max-w-[410px]" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "mt-24" }
const _hoisted_5 = { class: "mt-12" }
const _hoisted_6 = {
  key: 1,
  class: "mt-12"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppText = _resolveComponent("AppText")!
  const _component_BaseSelect = _resolveComponent("BaseSelect")!
  const _component_BaseRadioList = _resolveComponent("BaseRadioList")!
  const _component_BaseInput = _resolveComponent("BaseInput")!
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_AppToast = _resolveComponent("AppToast")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.currentQuestion)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_AppText, {
              size: _ctx.$appTextProps.TextSize['text-3-bold']
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.currentQuestion.question_title), 1)
              ]),
              _: 1
            }, 8, ["size"]),
            _createVNode(_component_AppText, {
              class: "mt-24",
              size: _ctx.$appTextProps.TextSize['text-5-medium']
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.currentQuestion.question_description), 1)
              ]),
              _: 1
            }, 8, ["size"]),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_AppText, {
                size: _ctx.$appTextProps.TextSize['text-5-medium']
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.currentQuestion.question_label), 1)
                ]),
                _: 1
              }, 8, ["size"]),
              (_ctx.currentQuestion.type === _ctx.DUE_DILIGENCE_QUESTION_TYPE.RADIO)
                ? (_openBlock(), _createBlock(_component_AppText, {
                    key: 0,
                    class: "text-text-inactive",
                    size: _ctx.$appTextProps.TextSize['text-6-regular']
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('kyc.kyc_due_diligence.dynamic_questions.check_box')), 1)
                    ]),
                    _: 1
                  }, 8, ["size"]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_5, [
                (_ctx.currentQuestion.type === _ctx.DUE_DILIGENCE_QUESTION_TYPE.DROPDOWN)
                  ? (_openBlock(), _createBlock(_component_BaseSelect, {
                      key: _ctx.currentQuestion.question_id,
                      modelValue: _ctx.currentQuestionSelectedOption,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentQuestionSelectedOption) = $event)),
                      testId: "current-dd-pending-question",
                      list: 
                _ctx.currentQuestion.options.map((option) => ({
                  label: option.option_label,
                  value: option.option_id,
                }))
              
                    }, null, 8, ["modelValue", "list"]))
                  : _createCommentVNode("", true),
                (_ctx.currentQuestion.type === _ctx.DUE_DILIGENCE_QUESTION_TYPE.RADIO)
                  ? (_openBlock(), _createBlock(_component_BaseRadioList, {
                      key: 1,
                      modelValue: _ctx.currentQuestionSelectedOption,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.currentQuestionSelectedOption) = $event)),
                      name: "current-dd-pending-question",
                      testId: "current-dd-pending-question",
                      items: 
                _ctx.currentQuestion.options.map((option) => {
                  return { label: option.option_label, value: option.option_id };
                })
              
                    }, null, 8, ["modelValue", "items"]))
                  : _createCommentVNode("", true)
              ]),
              (
              _ctx.currentQuestion.type === _ctx.DUE_DILIGENCE_QUESTION_TYPE.DROPDOWN &&
              _ctx.currentQuestionSelectedOption &&
              _ctx.optionsThatRequireExtraInfo.includes(_ctx.currentQuestionSelectedOptionLabel)
            )
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    _createVNode(_component_BaseInput, {
                      modelValue: _ctx.currentQuestionSelectedOptionExtraInfo,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.currentQuestionSelectedOptionExtraInfo) = $event)),
                      autofocus: "",
                      testId: "option_extra_info",
                      placeholder: "Please specify"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.currentQuestionSelectedOptionLabel), 1)
                      ]),
                      _: 1
                    }, 8, ["modelValue"])
                  ]))
                : _createCommentVNode("", true)
            ]),
            _createVNode(_component_AppButton, {
              class: "mt-24",
              wide: "",
              inactive: !_ctx.currentQuestionSelectedOption,
              label: _ctx.$t('action.next'),
              loading: _ctx.isLoading,
              onClick: _ctx.nextQuestion
            }, null, 8, ["inactive", "label", "loading", "onClick"])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_AppToast, {
      onOnDismiss: _ctx.onDismiss,
      dismissable: true,
      message: _ctx.toastMessage.message,
      open: _ctx.toastMessage.open
    }, null, 8, ["onOnDismiss", "message", "open"])
  ]))
}