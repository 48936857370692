
import { defineComponent, PropType } from 'vue';

import { BaseLoading, loadingProps } from '@BaseComponents/BaseLoading';

import { IconPosition, Size, Version } from './types';

import { AVAILABLE_ICONS } from '@Libraries/font-awesome';

export default defineComponent({
  name: 'AppButton',

  components: {
    BaseLoading,
  },

  props: {
    icon: {
      type: String as PropType<AVAILABLE_ICONS>,
    },

    iconPosition: {
      type: String as PropType<IconPosition>,
      default: IconPosition.left,
    },

    iconRotation: {
      type: String,
      default: 'rotate-0',
    },

    inactive: {
      type: Boolean,
      default: false,
    },

    isSkeletonLoading: {
      type: Boolean,
      default: false,
    },

    label: {
      type: String,
      required: true,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    size: {
      type: String as PropType<Size>,
      default: Size.large,
    },

    submit: {
      type: Boolean,
      default: false,
    },

    testId: String,

    version: {
      type: String as PropType<Version>,
      default: Version.primary,
    },

    wide: {
      type: Boolean,
      default: false,
    },
  },

  setup() {
    return {
      loadingProps,
    };
  },

  computed: {
    classes() {
      return {
        [this.backgroundColor]: true,
        [this.borderColor]: true,
        [this.borderRadius]: true,
        [this.buttonHeight]: true,
        [this.cursor]: true,
        [this.hoverTextColor]: true,
        [this.opacity]: true,
        [this.paddingHorizontal]: true,
        [this.textColor]: true,
        [this.textSize]: true,
        ['button flex border default-transition']: true,
        ['w-full']: this.wide,
      };
    },

    backgroundColor() {
      switch (this.version) {
        case Version.primary:
          return 'bg-background-primary-button';
        case Version.secondary:
          return 'bg-transparent';
        case Version.tertiary:
          return 'bg-background-primary';
        case Version.destructive:
          return 'bg-text-negative';
        default:
          return '';
      }
    },

    borderColor() {
      switch (this.version) {
        case Version.primary:
          return 'border-background-primary-button';
        case Version.secondary:
          return 'border-text-body';
        case Version.tertiary:
          return 'border-border-default';
        case Version.destructive:
          return 'border-text-negative';
        default:
          return '';
      }
    },

    borderRadius() {
      return 'rounded-4';
    },

    buttonHeight() {
      switch (this.size) {
        case Size.small:
          return 'button-height-sm';
        case Size.medium:
          return 'button-height-md';
        case Size.large:
          return 'button-height-lg';
        default:
          return 'button-height-md';
      }
    },

    cursor() {
      if (this.inactive || this.loading) {
        return 'cursor-not-allowed';
      }
      return 'cursor-pointer';
    },

    hoverTextColor() {
      return this.inactive || this.loading ? '' : `hover-${this.version}`;
    },

    iconColor() {
      switch (this.version) {
        case Version.primary:
          return 'text-text-body-inverted';
        case Version.secondary:
          return 'text-text-body';
        case Version.tertiary:
          return 'text-text-body';
        case Version.destructive:
          return 'hidden';
        default:
          return '';
      }
    },

    iconLeft() {
      return this.iconPosition === IconPosition.left;
    },

    iconPadding() {
      switch (this.size) {
        case Size.small:
          return this.iconLeft ? 'pr-4' : 'pl-4';
        case Size.medium:
          return this.iconLeft ? 'pr-8' : 'pl-8';
        case Size.large:
          return this.iconLeft ? 'pr-8' : 'pl-8';
        default:
          return '';
      }
    },

    iconSize() {
      switch (this.size) {
        case Size.small:
          return 'text-6-bold';
        case Size.medium:
          return 'text-5-bold';
        case Size.large:
          return 'text-4-bold';
        default:
          return '';
      }
    },

    loadingSpinner() {
      switch (this.size) {
        case Size.small:
          return 'w-16 h-16';
        case Size.medium:
          return 'w-24 h-24';
        case Size.large:
          return 'w-32 h-32';
        default:
          return '';
      }
    },

    opacity() {
      return this.inactive ? 'inactive-opacity' : '';
    },

    paddingHorizontal() {
      switch (this.size) {
        case Size.small:
          return 'px-12';
        case Size.medium:
          return 'px-24';
        case Size.large:
          return 'px-32';
        default:
          return '';
      }
    },

    skeletonHeight() {
      switch (this.size) {
        case Size.small:
          return '28px';
        case Size.medium:
          return '44px';
        case Size.large:
          return '54px';
        default:
          return '44px';
      }
    },

    textColor() {
      switch (this.version) {
        case Version.primary:
          return 'text-text-body-inverted';
        case Version.secondary:
          return 'text-text-body';
        case Version.tertiary:
          return 'text-text-body';
        case Version.destructive:
          return 'text-text-body-inverted dark:text-text-body';
        default:
          return '';
      }
    },

    textSize() {
      switch (this.size) {
        case Size.small:
          return 'text-6-bold';
        case Size.medium:
          return 'text-5-bold';
        case Size.large:
          return 'text-4-bold';
        default:
          return 'text-5-bold';
      }
    },

    type() {
      return this.submit ? 'submit' : 'button';
    },
  },
});
