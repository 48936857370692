
import { defineComponent } from 'vue';
import { BaseModal } from '@BaseComponents/BaseModal';

export default defineComponent({
  name: 'BasePrompt',
  components: {
    BaseModal,
  },

  emits: ['cancel', 'confirm'],

  props: {
    clickToClose: {
      type: Boolean,
      default: true,
    },

    customClose: String,

    customConfirm: String,

    disabledConfirm: {
      type: Boolean,
      default: false,
    },

    isDestructive: Boolean,

    isSuccess: Boolean,

    loading: {
      type: Boolean,
      default: false,
    },

    message: {
      type: String,
      required: true,
    },

    disclaimer: {
      type: String,
      required: false,
    },

    title: {
      type: String,
      required: true,
    },
  },

  computed: {
    confirmButtonVersion() {
      return this.isDestructive
        ? this.$appButtonProps.Version.destructive
        : this.$appButtonProps.Version.primary;
    },
  },
});
