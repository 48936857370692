import { apollo, DibbsApiException, DibbsErrorHelper, errorHandler } from '@/utils/helpers/api';

import RESEND_EMAIL_CONFIRMATION from '../graphql/mutations/ResendEmailConfirmation.gql';

interface Result {
  resend_email_confirmation: {
    result: boolean;
  };
}

async function resendEmailConfirmation(): Promise<Result['resend_email_confirmation']> {
  return apollo
    .mutate<Result>({
      mutation: RESEND_EMAIL_CONFIRMATION,
    })
    .then(({ data }) => DibbsErrorHelper(data?.resend_email_confirmation))
    .catch((error) => {
      throw new DibbsApiException(errorHandler(error));
    });
}

export default resendEmailConfirmation;
