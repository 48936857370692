import { Provider, SignTypedDataVersion, SignInTypedData3 } from './types';
import * as sigUtils from '@metamask/eth-sig-util';
import { ethers } from 'ethers';

class Ethers {
  provider: ethers.providers.Web3Provider;
  contract: ethers.Contract | null;

  constructor(provider: Provider) {
    this.provider = new ethers.providers.Web3Provider(provider);
    this.contract = null;
  }

  async requestAccounts() {
    return await this.provider.send('eth_requestAccounts', []); // returns the array of accounts address
  }

  async verifyAccounts() {
    return await this.provider.send('eth_accounts', []); // returns the array of accounts address
  }

  getSigner() {
    return this.provider.getSigner();
  }

  async getChainId() {
    const network = await this.provider.getNetwork();
    return network.chainId;
  }

  async getSignerAddress() {
    const signer = this.getSigner();
    return await signer.getAddress();
  }

  async getAccountBalance(): Promise<ethers.BigNumber> {
    const signer = this.provider.getSigner();
    const address = await signer.getAddress();
    return await this.provider.getBalance(address);
  }

  async getEthAccountBalance() {
    const accountBalance = await this.getAccountBalance();
    return ethers.utils.formatUnits(accountBalance, 0); // returns the balance in ETH
  }

  async getWeiAccountBalance() {
    const accountBalance = await this.getAccountBalance();
    const balance = ethers.utils.formatEther(accountBalance);
    return balance;
  }

  async signInPersonal(message: string) {
    const signer = await this.getSignerAddress();
    const params = [signer, message];
    const signature = await this.provider.send('personal_sign', params);
    return signature;
  }

  verifyPersonalSignature(message: string, signature: string) {
    return sigUtils.recoverPersonalSignature({
      data: message,
      signature,
    }); // returns the public address of the signer
  }

  async signInTypedDataV3({ account, message }: SignInTypedData3.Params) {
    const params = [account, JSON.stringify(message)];
    const signature = await this.provider.send('eth_signTypedData_v3', params);
    return signature;
  }

  verifySignature(message: sigUtils.TypedMessage<sigUtils.MessageTypes>, signature: string) {
    return sigUtils.recoverTypedSignature({
      data: message,
      version: SignTypedDataVersion.V3,
      signature,
    }); // returns the public address of the signer
  }

  initContract(
    abi: ethers.ContractInterface,
    address: string,
    signer: ethers.providers.JsonRpcSigner
  ) {
    return new ethers.Contract(address, abi, signer);
  }
}

export default Ethers;
