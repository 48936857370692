import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppText = _resolveComponent("AppText")!
  const _component_AppButton = _resolveComponent("AppButton")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_AppText, {
      tag: "h1",
      loading: _ctx.loading,
      size: _ctx.$appTextProps.TextSize['text-3-bold']
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('crypto.redemption.burning_process.title')), 1)
      ]),
      _: 1
    }, 8, ["loading", "size"]),
    _createVNode(_component_AppText, {
      class: "mt-32",
      loading: _ctx.loading
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t(`crypto.redemption.burning_process.description`)), 1)
      ]),
      _: 1
    }, 8, ["loading"]),
    _createVNode(_component_AppText, {
      class: "mt-32",
      loading: _ctx.loading
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t(`crypto.redemption.burning_process.transaction_hash`)), 1)
      ]),
      _: 1
    }, 8, ["loading"]),
    _createVNode(_component_AppText, { loading: _ctx.loading }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.transactionHash), 1)
      ]),
      _: 1
    }, 8, ["loading"]),
    _createVNode(_component_AppButton, {
      class: "mt-32 w-[300px]",
      testId: "open-scan-page",
      loading: _ctx.loading,
      label: _ctx.seeTransactionButton,
      onClick: _ctx.openScanPage
    }, null, 8, ["loading", "label", "onClick"])
  ]))
}