import { useRoute } from 'vue-router';
import { storeToRefs } from 'pinia';
import { useOperatorStore } from '@Modules/Operator';
import { ACCOUNT_STATUS } from '@Shared/enums/accountStatus';
import { useRouteGuardStore } from '../store';
import { RouterGuardExceptionTypes } from '../types';

export default function () {
  const ROUTE = useRoute();
  const { accountStatus, hasPendingPii } = storeToRefs(useOperatorStore());
  const routeGuardStore = useRouteGuardStore();

  function applyAccountStatusValidations(targetRoute?: string) {
    if (!accountStatus?.value) {
      routeGuardStore.$patch({
        validationError: {
          type: RouterGuardExceptionTypes.LOGOUT,
        },
      });

      return false;
    }

    if (isBlockedAccount(accountStatus.value)) {
      routeGuardStore.$patch({
        validationError: {
          type: RouterGuardExceptionTypes.LOGOUT,
        },
      });

      return false;
    }

    if (isApplicantWithPendingRegistration(accountStatus.value, targetRoute)) {
      routeGuardStore.$patch({
        validationError: {
          type: RouterGuardExceptionTypes.REDIRECTED,
          redirectUrl: 'registration',
        },
      });

      return false;
    }

    if (isFrozen(accountStatus.value, targetRoute)) {
      routeGuardStore.$patch({
        validationError: {
          type: RouterGuardExceptionTypes.REDIRECTED,
          redirectUrl: 'registration',
        },
      });
      return false;
    }

    if (hasAdminRequestPending(accountStatus.value)) {
      routeGuardStore.$patch({
        validationError: {
          type: RouterGuardExceptionTypes.REDIRECTED,
          redirectUrl: 'registration',
        },
      });

      return false;
    }

    return true;
  }

  function isBlockedAccount(accountStatus: ACCOUNT_STATUS) {
    const { CLOSED, CLOSED_AML, REJECTED } = ACCOUNT_STATUS;

    return [CLOSED, CLOSED_AML, REJECTED].includes(accountStatus);
  }

  function isApplicantWithPendingRegistration(accountStatus: ACCOUNT_STATUS, targetRoute?: string) {
    const { APPLICANT } = ACCOUNT_STATUS;
    const isApplicant = accountStatus === APPLICANT;

    if (!isApplicant) return;

    const currentRouteName = targetRoute || ROUTE.name?.toString();

    if (!currentRouteName) return;

    const allowedRoutesNames = [
      'change-password',
      'registration',
      'security',
      'due-diligence',
      'due-diligence-question',
      'delete-confirmation',
      'disable-2fa',
      'setup-2fa',
      'reset-2fa',
      'support',
      'profile',
      'registry',
    ];

    const isRegistration = currentRouteName === 'registration';

    return isRegistration || !allowedRoutesNames.includes(currentRouteName);
  }

  function isFrozen(accountStatus: ACCOUNT_STATUS, targetRoute?: string) {
    const { FROZEN } = ACCOUNT_STATUS;
    const isFrozen = accountStatus === FROZEN;

    if (!isFrozen) return;

    const currentRouteName = targetRoute || ROUTE.name?.toString();

    if (!currentRouteName) return;

    const allowedRoutesNames = [
      'registration',
      'due-diligence',
      'due-diligence-question',
      'support',
      'your-items',
      'profile',
      'registry',
    ];

    return !allowedRoutesNames.includes(currentRouteName);
  }

  function hasAdminRequestPending(accountStatus: ACCOUNT_STATUS) {
    const { OPENED } = ACCOUNT_STATUS;
    const isOpened = accountStatus === OPENED;

    if (!isOpened) return;

    return hasPendingPii.value;
  }

  return { applyAccountStatusValidations };
}
