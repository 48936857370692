import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, Transition as _Transition, withCtx as _withCtx, Teleport as _Teleport, createBlock as _createBlock, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-eb85bab0"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["data-cy", "data-testid"]
const _hoisted_2 = { class: "toast-content" }
const _hoisted_3 = ["data-cy", "data-testid"]
const _hoisted_4 = { "aria-hidden": "true" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _normalizeProps(_guardReactiveProps(_ctx.attrs)), [
    (_openBlock(), _createBlock(_Teleport, { to: "#toast-messages" }, [
      _createVNode(_Transition, { name: "toast-animation" }, {
        default: _withCtx(() => [
          (_ctx.isOpen)
            ? (_openBlock(), _createElementBlock("section", {
                key: 0,
                "data-cy": `${_ctx.testId}_container`,
                "data-testid": `${_ctx.testId}_container`,
                class: _normalizeClass(_ctx.containerClasses),
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args))),
                onMouseover: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onHoverActive && _ctx.onHoverActive(...args))),
                onMouseleave: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onHoverInactive && _ctx.onHoverInactive(...args)))
              }, [
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_font_awesome_icon, {
                    class: "toast-icon fa-icon",
                    role: "icon",
                    "data-testid": `${_ctx.testId}_icon`,
                    "data-cy": `${_ctx.testId}_icon`,
                    icon: _ctx.AVAILABLE_ICONS[_ctx.icon]
                  }, null, 8, ["data-testid", "data-cy", "icon"]),
                  _createTextVNode(" " + _toDisplayString(_ctx.message) + " ", 1),
                  (_ctx.dismissable)
                    ? (_openBlock(), _createElementBlock("button", {
                        key: 0,
                        class: "dismiss-btn",
                        "data-cy": `${_ctx.testId}_btn`,
                        "data-testid": `${_ctx.testId}_btn`,
                        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)))
                      }, [
                        _createElementVNode("i", _hoisted_4, [
                          _createVNode(_component_font_awesome_icon, {
                            class: "fa-icon h-[14px]",
                            role: "icon",
                            "data-cy": `${_ctx.testId}_dismissable-icon`,
                            "data-testid": `${_ctx.testId}_dismissable-icon`,
                            icon: _ctx.AVAILABLE_ICONS['times-fas']
                          }, null, 8, ["data-cy", "data-testid", "icon"])
                        ])
                      ], 8, _hoisted_3))
                    : _createCommentVNode("", true)
                ])
              ], 42, _hoisted_1))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]))
  ], 16))
}