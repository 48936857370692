enum Shapes {
  rectangle = 'rectangle',
  circle = 'circle',
}

interface ISkeletonLoading {
  active: boolean;
  height: string;
  width: string;
}

const appSkeletonLoadingProps = {
  Shapes,
};

export { Shapes, ISkeletonLoading, appSkeletonLoadingProps };
