import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, mergeProps as _mergeProps, vModelDynamic as _vModelDynamic, resolveDirective as _resolveDirective, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c35aeeac"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["data-testid"]
const _hoisted_2 = ["data-testid"]
const _hoisted_3 = ["for"]
const _hoisted_4 = ["id", "autofocus", "data-lpignore", "autocomplete", "data-testid", "disabled", "inputmode", "maxlength", "name", "placeholder", "type"]
const _hoisted_5 = ["data-testid"]
const _hoisted_6 = ["data-testid"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Datepicker = _resolveComponent("Datepicker")!
  const _component_Money3Component = _resolveComponent("Money3Component")!
  const _directive_maska = _resolveDirective("maska")!

  return (_openBlock(), _createElementBlock("section", {
    class: "custom-input",
    "data-testid": _ctx.testId
  }, [
    (_ctx.hasLabelSlot)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "custom-input__label-container",
          "data-testid": `${_ctx.testId}__input-label-container`
        }, [
          _createElementVNode("label", {
            class: _normalizeClass(_ctx.leftLabelClasses),
            for: _ctx.name
          }, [
            _renderSlot(_ctx.$slots, "default", {}, undefined, true)
          ], 10, _hoisted_3),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.rightLabelClasses)
          }, [
            _renderSlot(_ctx.$slots, "right-label", {}, undefined, true)
          ], 2)
        ], 8, _hoisted_2))
      : _createCommentVNode("", true),
    (_ctx.isDatepicker)
      ? (_openBlock(), _createBlock(_component_Datepicker, {
          key: 1,
          modelValue: _ctx.model,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model) = $event)),
          "auto-apply": "",
          teleport: "#modals",
          "data-testid": `${_ctx.testId}__input`,
          disabled: _ctx.disabled,
          "enable-time-picker": false,
          "max-date": _ctx.maxDate,
          name: _ctx.name,
          "start-date": _ctx.startDate,
          onBlur: _ctx.emitBlur
        }, null, 8, ["modelValue", "data-testid", "disabled", "max-date", "name", "start-date", "onBlur"]))
      : (_openBlock(), _createElementBlock("div", {
          key: 2,
          class: _normalizeClass([_ctx.containerClasses, _ctx.externalContainerClasses])
        }, [
          (_ctx.isDoubleInput)
            ? (_openBlock(), _createBlock(_component_Money3Component, _mergeProps({
                key: 0,
                modelValue: _ctx.vMoneyModel,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.vMoneyModel) = $event)),
                mas: ""
              }, _ctx.filterType, {
                id: _ctx.name,
                class: _ctx.inputClasses,
                "data-lpignore": _ctx.lpIgnore,
                autocomplete: _ctx.autocomplete,
                "data-testid": `${_ctx.testId}__input`,
                disabled: _ctx.disabled,
                inputmode: _ctx.inputmode,
                name: _ctx.name,
                placeholder: _ctx.placeholder,
                ref: "inputRef",
                type: _ctx.type,
                onBlur: _ctx.emitBlur
              }), null, 16, ["modelValue", "id", "class", "data-lpignore", "autocomplete", "data-testid", "disabled", "inputmode", "name", "placeholder", "type", "onBlur"]))
            : _withDirectives((_openBlock(), _createElementBlock("input", {
                key: 1,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.model) = $event)),
                id: _ctx.name,
                autofocus: _ctx.autofocus,
                class: _normalizeClass(_ctx.inputClasses),
                "data-lpignore": _ctx.lpIgnore,
                autocomplete: _ctx.autocomplete,
                "data-testid": `${_ctx.testId}__input`,
                disabled: _ctx.disabled,
                inputmode: _ctx.inputmode,
                maxlength: _ctx.maxlength,
                name: _ctx.name,
                placeholder: _ctx.placeholder,
                ref: "inputRef",
                type: _ctx.type,
                onBlur: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.emitBlur && _ctx.emitBlur(...args))),
                onMaska: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.onMask && _ctx.onMask(...args)))
              }, null, 42, _hoisted_4)), [
                [_vModelDynamic, _ctx.model],
                [_directive_maska, _ctx.mask]
              ]),
          (_ctx.hasIconSlot)
            ? (_openBlock(), _createElementBlock("div", {
                key: 2,
                class: _normalizeClass(_ctx.iconClasses),
                "data-testid": `${_ctx.testId}__icon`
              }, [
                _renderSlot(_ctx.$slots, "icon", {}, undefined, true)
              ], 10, _hoisted_5))
            : _createCommentVNode("", true)
        ], 2)),
    (_ctx.error.status)
      ? (_openBlock(), _createElementBlock("div", {
          key: 3,
          class: _normalizeClass(_ctx.errorClasses)
        }, [
          (_ctx.error.status)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                "data-testid": `${_ctx.testId}__input-error-container`
              }, [
                _renderSlot(_ctx.$slots, "error", {}, () => [
                  _createTextVNode(_toDisplayString(_ctx.error.message), 1)
                ], true)
              ], 8, _hoisted_6))
            : _createCommentVNode("", true)
        ], 2))
      : _createCommentVNode("", true)
  ], 8, _hoisted_1))
}