
import { defineComponent, PropType, reactive } from 'vue';

import { addSDK, removeSDK } from '@Helpers/sdk';

import { KycAddressForm, IAddressForm } from '@Modules/Kyc/components/KycAddressForm';

import { IKycAddressData } from './types';
import { ADDRESS_TYPE } from '@Shared/enums/addressType';

export default defineComponent({
  name: 'KycAddress',

  components: {
    KycAddressForm,
  },

  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },

    storedAddress: {
      type: Object as PropType<IAddressForm>,
    },

    type: {
      type: String as PropType<ADDRESS_TYPE>,
      default: ADDRESS_TYPE.PRINCIPAL,
    },

    showOnlyInputs: {
      type: Boolean,
      default: false,
    },

    readonly: {
      type: Boolean,
      default: false,
    },
  },

  emits: {
    setAddress: (addressForm: IAddressForm) => addressForm,
  },

  setup(props) {
    const initialAddress: IAddressForm = {
      addressCountry: '',
      addressComplement: '',
      addressStreet: '',
      addressCity: '',
      addressState: '',
      addressZip: '',
      addressNumber: '',
      ...props.storedAddress,
    };

    const form = reactive<IAddressForm>({ ...initialAddress });

    return {
      form,
      initialAddress,
    };
  },

  data(): IKycAddressData {
    return {
      googleSdkId: 'google-places-sdk',
      googleAutocompleteService: undefined,
      googleGeocoder: undefined,
    };
  },

  mounted() {
    this.mountGooglePlacesAutoComplete();
  },

  unmounted() {
    removeSDK(this.googleSdkId);
  },

  methods: {
    async mountGooglePlacesAutoComplete() {
      await addSDK(
        this.googleSdkId,
        `${process.env.VUE_APP_GOOGLE_PLACES_URL}?key=${process.env.VUE_APP_GOOGLE_PLACES_ID}&libraries=places&v=weekly&channel=2`
      );
      this.setTheGoogleProps();
    },

    setTheGoogleProps() {
      let timer = setTimeout(() => {
        if (window.google) {
          this.googleAutocompleteService = new window.google.maps.places.AutocompleteService();
          this.googleGeocoder = new window.google.maps.Geocoder();
          clearTimeout(timer);
        } else {
          this.setTheGoogleProps();
        }
      }, 1000);
    },

    setAddress(addressForm: IAddressForm) {
      this.$emit('setAddress', addressForm);
    },
  },
});
