import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex items-center justify-between cursor-pointer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppText = _resolveComponent("AppText")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_AppToast = _resolveComponent("AppToast")!
  const _component_BasePrompt = _resolveComponent("BasePrompt")!
  const _component_BaseInput = _resolveComponent("BaseInput")!
  const _component_BaseCard = _resolveComponent("BaseCard")!

  return (_openBlock(), _createBlock(_component_BaseCard, { onClick: _ctx.openStepOneModal }, {
    default: _withCtx(() => [
      _createElementVNode("main", _hoisted_1, [
        _createVNode(_component_AppText, {
          color: "text-text-negative",
          tag: "h2",
          size: _ctx.$appTextProps.TextSize['text-4-regular']
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('auth.delete_account.title')), 1)
          ]),
          _: 1
        }, 8, ["size"]),
        _createVNode(_component_font_awesome_icon, {
          class: "'fa-icon'",
          role: "icon",
          icon: _ctx.AVAILABLE_ICONS['chevron-right-fas']
        }, null, 8, ["icon"])
      ]),
      _createElementVNode("aside", null, [
        _createVNode(_component_AppToast, _mergeProps(_ctx.toast, { onOnDismiss: _ctx.hideToast }), null, 16, ["onOnDismiss"]),
        (_ctx.stepOneModal)
          ? (_openBlock(), _createBlock(_component_BasePrompt, {
              key: 0,
              modelValue: _ctx.stepOneModal,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.stepOneModal) = $event)),
              "click-to-close": true,
              "custom-close": _ctx.$t('auth.delete_account.support_modal.continue'),
              "custom-confirm": _ctx.$t('auth.delete_account.support_modal.talk_to_support'),
              "is-destructive": true,
              loading: _ctx.loading,
              message: _ctx.$t('auth.delete_account.support_modal.description'),
              title: _ctx.$t('auth.delete_account.support_modal.title'),
              onConfirm: _ctx.goToSupportPage,
              onCancel: _ctx.sentEmailConfirmation
            }, null, 8, ["modelValue", "custom-close", "custom-confirm", "loading", "message", "title", "onConfirm", "onCancel"]))
          : _createCommentVNode("", true),
        (_ctx.twoFaModal)
          ? (_openBlock(), _createBlock(_component_BasePrompt, {
              key: 1,
              modelValue: _ctx.twoFaModal,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.twoFaModal) = $event)),
              "custom-confirm": _ctx.$t('auth.delete_account.2fa_modal.continue'),
              disabledConfirm: !_ctx.twofa,
              message: _ctx.$t('auth.delete_account.2fa_modal.description'),
              loading: _ctx.loading,
              title: _ctx.$t('auth.delete_account.2fa_modal.title'),
              onConfirm: _ctx.sentEmailConfirmation,
              onCancel: _cache[3] || (_cache[3] = ($event: any) => (_ctx.twoFaModal = false))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_BaseInput, {
                  modelValue: _ctx.twofa,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.twofa) = $event)),
                  mask: "######",
                  "test-id": "twofa-input",
                  type: _ctx.inputProps.Type.number
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('auth.delete_account.2fa_modal.2fa_code')), 1)
                  ]),
                  _: 1
                }, 8, ["modelValue", "type"])
              ]),
              _: 1
            }, 8, ["modelValue", "custom-confirm", "disabledConfirm", "message", "loading", "title", "onConfirm"]))
          : _createCommentVNode("", true),
        (_ctx.emailSentModal)
          ? (_openBlock(), _createBlock(_component_BasePrompt, {
              key: 2,
              modelValue: _ctx.emailSentModal,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.emailSentModal) = $event)),
              customConfirm: "Close",
              "is-success": true,
              message: _ctx.$t('auth.delete_account.email_sent_modal.description'),
              loading: _ctx.loading,
              title: _ctx.$t('auth.delete_account.email_sent_modal.title'),
              onConfirm: _cache[5] || (_cache[5] = ($event: any) => (_ctx.emailSentModal = false))
            }, null, 8, ["modelValue", "message", "loading", "title"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["onClick"]))
}