
import { defineComponent } from 'vue';
import { BaseCheckbox, BaseInput, inputProps } from '@BaseUi';
import { InputPassword } from '@Ui/InputPassword';
import { isEmailValid } from '@/utils/helpers';
import { ILoginFormPayload } from '../../types';

export default defineComponent({
  name: 'LoginForm',

  setup() {
    return { inputProps, isEmailValid };
  },

  emits: {
    onLogin: (payload: ILoginFormPayload) => payload,
    onRecoveryPwdModal: () => true,
  },

  components: { BaseCheckbox, BaseInput, InputPassword },

  props: {
    canSubmit: {
      type: Boolean,
      default: false,
    },

    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      email: '',
      password: '',
      keep: false,
    };
  },

  computed: {
    isFormReady() {
      return (
        isEmailValid(this.email) &&
        !!this.email &&
        !!this.password &&
        !this.loading &&
        this.canSubmit
      );
    },
  },

  methods: {
    setPassword(password: string) {
      this.password = password;
    },

    onLogin() {
      this.$emit('onLogin', {
        email: this.email,
        password: this.password,
        keep: this.keep,
      });
    },

    onShowRecoveryPwdModal() {
      this.$emit('onRecoveryPwdModal');
    },
  },
});
