import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppText = _resolveComponent("AppText")!
  const _component_BaseInput = _resolveComponent("BaseInput")!
  const _component_AppButton = _resolveComponent("AppButton")!

  return (_openBlock(), _createElementBlock("form", {
    onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submit && _ctx.submit(...args)), ["prevent"]))
  }, [
    _createVNode(_component_AppText, {
      tag: "h1",
      size: _ctx.$appTextProps.TextSize['text-3-bold']
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('auth.twofa.verification_auth.verify_authentication')), 1)
      ]),
      _: 1
    }, 8, ["size"]),
    _createVNode(_component_AppText, { class: "mt-40" }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('auth.twofa.verification_auth.enter_code')), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_BaseInput, {
      modelValue: _ctx.twofa,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.twofa) = $event)),
      class: "mt-20 max-w-[360px]",
      ref: "twofaRef",
      mask: "######",
      autofocus: "",
      testId: "input-twofa",
      type: _ctx.inputProps.Type['text'],
      placeholder: _ctx.$t('auth.twofa.modal.input.placeholder')
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('auth.twofa.input.2fa_code')), 1)
      ]),
      _: 1
    }, 8, ["modelValue", "type", "placeholder"]),
    _createVNode(_component_AppButton, {
      testId: "btn-continue",
      class: "mt-24 max-w-[360px]",
      submit: "",
      wide: "",
      loading: _ctx.isLoading,
      inactive: _ctx.shoudBeInactive,
      label: _ctx.$t('auth.twofa.actions.continue')
    }, null, 8, ["loading", "inactive", "label"])
  ], 32))
}