import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache } from '@apollo/client/core';
import { createUploadLink } from 'apollo-upload-client';
import { createApolloProvider } from '@vue/apollo-option';
import fetch from 'cross-fetch';
import { getHeaders } from './helpers';

const cache = new InMemoryCache();

const uploadOptions = {
  uri: process.env.VUE_APP_VAULT_API,
  fetch: (uri: RequestInfo, options: RequestInit) => {
    options.headers = {
      ...getHeaders(),
    };

    return fetch(uri, options);
  },
};

const httpOptions = {
  uri: process.env.VUE_APP_VAULT_API,
  fetch: (uri: RequestInfo, options: RequestInit) => {
    options.headers = {
      ...getHeaders(),
      'Content-Type': 'application/json',
    };

    return fetch(uri, options);
  },
};

const apolloLink = ApolloLink.split(
  (operation) => operation.getContext().hasUpload,
  createUploadLink(uploadOptions),
  createHttpLink(httpOptions)
);

const apolloClient = new ApolloClient({
  cache,
  link: apolloLink,
});

const apolloProvider = createApolloProvider({
  defaultClient: apolloClient,
});
const apollo = apolloProvider.clients.defaultClient;

export { apollo };
