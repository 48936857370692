import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "mt-2" }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppText = _resolveComponent("AppText")!
  const _component_BaseIcon = _resolveComponent("BaseIcon")!

  return (_openBlock(), _createElementBlock("section", null, [
    _createVNode(_component_AppText, {
      tag: "h3",
      class: _normalizeClass(["mb-8", _ctx.hasErrorClass]),
      size: "text-6-medium"
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('auth.pwd_validation.title')), 1)
      ]),
      _: 1
    }, 8, ["class"]),
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.conditionsData, (condition, index) => {
        return (_openBlock(), _createBlock(_component_AppText, {
          key: index,
          size: "text-6-regular",
          class: _normalizeClass(condition.class)
        }, {
          default: _withCtx(() => [
            (condition.isValid)
              ? (_openBlock(), _createBlock(_component_BaseIcon, {
                  key: 0,
                  icon: _ctx.icon
                }, null, 8, ["icon"]))
              : (_openBlock(), _createElementBlock("span", _hoisted_2, "○")),
            _createTextVNode(" " + _toDisplayString(condition.text), 1)
          ]),
          _: 2
        }, 1032, ["class"]))
      }), 128))
    ])
  ]))
}