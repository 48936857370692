import { apollo, DibbsApiException, DibbsErrorHelper, errorHandler } from '@/utils/helpers/api';

import AVAILABLE_KYC_COUNTRIES from '../graphql/queries/AvailableKycCountries.gql';

interface Result {
  available_kyc_countries: Array<string>;
}

export default async (): Promise<Result['available_kyc_countries']> => {
  return apollo
    .query<Result>({
      query: AVAILABLE_KYC_COUNTRIES,
    })
    .then(({ data: { available_kyc_countries } }) => DibbsErrorHelper(available_kyc_countries))
    .catch((error) => {
      throw new DibbsApiException(errorHandler(error));
    });
};
