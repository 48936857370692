import { apollo, DibbsApiException, DibbsErrorHelper, errorHandler } from '@/utils/helpers/api';

import TWO_FACTOR_AUTH_SETUP_STEP_THREE from '../graphql/mutations/TwoFactorAuthSetupStepThree.gql';

interface Result {
  twofa_setup_step3: {
    secret: string;
    recovery: string;
    otpauthUrl: string;
  };
}

async function verify2FACode(twofa: string): Promise<Result['twofa_setup_step3']> {
  return apollo
    .mutate<Result>({
      mutation: TWO_FACTOR_AUTH_SETUP_STEP_THREE,
      variables: {
        twofa,
      },
    })
    .then(({ data }) => DibbsErrorHelper(data?.twofa_setup_step3))
    .then((data) => data)
    .catch((error) => {
      throw new DibbsApiException(errorHandler(error));
    });
}

export default verify2FACode;
