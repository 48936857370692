import { CryptoNetwork } from '../../types';
import { IAddress } from '@Shared/types/address';

interface IBurnNftOperatorData {
  email: string;
  isIndividual: boolean;
  address: IAddress;
}

enum RedeemStatus {
  requested = 'requested',
  shipped = 'shipped',
  arrived = 'arrived',
  returned = 'returned',
  confirmed = 'confirmed',
  cancelled = 'cancelled',
  approved = 'approved',
  burnt = 'burnt',
}

type RedemptionItemHistory = {
  status: RedeemStatus;
  date: Date;
  id: string;
};

export interface IRedemptionItem {
  id: string;
  item_id: string;
  status: RedeemStatus;
  shipping_address: IAddress;
  tracking_code: string;
  tracking_url: string;
  token_id: string;
  network: CryptoNetwork;
  history: RedemptionItemHistory[];
  item: {
    sku: string;
    name: string;
    video_url: string;
    thumbnail_url?: string;
  };
  operator: {
    email: string;
    name: string;
  };
  account: {
    account_name: string;
  };
}

interface ItemRegistry {
  item_name: string;
  item_video_url: string | null;
  item_id: string;
  nft_history: {
    to: {
      address: string;
      small_address: string;
    };
    from: {
      address: string;
      small_address: string;
    };
    network: string;
    transaction_hash: {
      address: string;
      small_address: string;
    };
    date: string;
  }[];
  formatted_metadata: {
    key: string;
    value: unknown;
  }[];
}

export { IBurnNftOperatorData, RedeemStatus, ItemRegistry };
