import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex items-center" }
const _hoisted_2 = { class: "flex items-center" }
const _hoisted_3 = {
  key: 0,
  class: "flex justify-center items-center"
}
const _hoisted_4 = {
  key: 0,
  class: "flex justify-center items-center"
}
const _hoisted_5 = {
  key: 1,
  class: "flex justify-center items-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseMainLogo = _resolveComponent("BaseMainLogo")!
  const _component_NavbarButton = _resolveComponent("NavbarButton")!
  const _component_NavbarDropdown = _resolveComponent("NavbarDropdown")!
  const _component_AppButton = _resolveComponent("AppButton")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_BaseMainLogo, {
        "link-to-home": "",
        class: "flex items-center mr-32"
      }),
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.desktopNavbar, (item) => {
          return (_openBlock(), _createBlock(_component_NavbarButton, {
            key: item.label,
            class: "mx-24",
            "data-cy": 'navbar-' + item.name,
            "data-testid": 'navbar-' + item.name,
            isExternal: item.isExternal,
            label: item.label,
            linkTo: item.linkTo,
            selected: _ctx.$route.name === item.name
          }, null, 8, ["data-cy", "data-testid", "isExternal", "label", "linkTo", "selected"]))
        }), 128))
      ])
    ]),
    (!_ctx.isLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (_ctx.isAuthenticated)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(_component_NavbarDropdown, {
                  class: "ml-32",
                  dropdownItems: _ctx.desktopItems,
                  onHandleAction: _ctx.handleAction
                }, null, 8, ["dropdownItems", "onHandleAction"])
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                (_ctx.showLoginButton)
                  ? (_openBlock(), _createBlock(_component_AppButton, {
                      key: 0,
                      class: "ml-16",
                      testId: "btn-login",
                      label: _ctx.$t('navigation.login'),
                      size: _ctx.$appButtonProps.Size.medium,
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push('/login')))
                    }, null, 8, ["label", "size"]))
                  : _createCommentVNode("", true),
                (_ctx.showSignupButton)
                  ? (_openBlock(), _createBlock(_component_AppButton, {
                      key: 1,
                      class: "ml-12",
                      testId: "btn-signup",
                      label: _ctx.$t('navigation.signup'),
                      size: _ctx.$appButtonProps.Size.medium,
                      version: _ctx.signUpButtonVersion,
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$router.push('/signup')))
                    }, null, 8, ["label", "size", "version"]))
                  : _createCommentVNode("", true)
              ]))
        ]))
      : _createCommentVNode("", true)
  ]))
}