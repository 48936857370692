export default [
  {
    path: '/signup',
    name: 'signup',
    component: () => import('@/views/SignupView/SignupView.vue'),
    meta: {
      isPublic: true,
    },
  },
];
