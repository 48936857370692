
import { defineComponent } from 'vue';

import BaseMainLogo from '../../../BaseMainLogo/BaseMainLogo.vue';
import NavbarButton from '../../NavbarButton/NavbarButton.vue';
import NavbarDropdown from '../../NavbarDropdown/NavbarDropdown.vue';
import { useMenu } from '../../utils/useMenu';

export default defineComponent({
  name: 'BaseNavbarDesktop',

  components: {
    BaseMainLogo,
    NavbarButton,
    NavbarDropdown,
  },

  emits: ['onOpenFeedbackModal', 'onOpenLogoutModal'],

  props: {
    isAuthenticated: {
      type: Boolean,
      default: false,
    },

    isLoading: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit }) {
    const { desktopNavbar, desktopItems, goToPage } = useMenu();

    async function handleAction(payload: { action: string; linkTo: string }) {
      switch (payload.action) {
        case 'feedback':
          emit('onOpenFeedbackModal');
          break;
        case 'logout':
          emit('onOpenLogoutModal');
          break;
        default:
          goToPage(payload.linkTo);
      }
    }

    return { desktopNavbar, desktopItems, handleAction };
  },

  computed: {
    signUpButtonVersion() {
      return this.$route.path !== '/login'
        ? this.$appButtonProps.Version.secondary
        : this.$appButtonProps.Version.primary;
    },

    showLoginButton() {
      return this.$route.path !== '/login';
    },

    showSignupButton() {
      return !this.$route.path.includes('/signup');
    },
  },
});
