
import { computed, defineComponent, PropType } from 'vue';
import {
  CryptoNetwork,
  CryptoNetworkScanner,
  CryptoNetworkScanName,
  IRedemptionItem,
} from '@Modules/Crypto';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'BurningProcessView',

  props: {
    transactionHash: {
      type: String,
      required: true,
    },
    redemptionItem: {
      type: Object as PropType<IRedemptionItem>,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const { t } = useI18n();

    const scanLink = computed(() => {
      const network = props.redemptionItem.network;
      switch (network) {
        case CryptoNetwork.ethereum:
          return `${CryptoNetworkScanner.ethereum}/tx/${props.transactionHash}`;
        case CryptoNetwork.goerli:
          return `${CryptoNetworkScanner.goerli}/tx/${props.transactionHash}`;
        case CryptoNetwork.mumbai:
          return `${CryptoNetworkScanner.mumbai}/tx/${props.transactionHash}`;
        default:
          return `${CryptoNetworkScanner.polygon}/tx/${props.transactionHash}`;
      }
    });

    const scanName = computed(() => {
      const network = props.redemptionItem.network;
      switch (network) {
        case CryptoNetwork.ethereum:
          return CryptoNetworkScanName.ethereum;
        case CryptoNetwork.goerli:
          return CryptoNetworkScanName.goerli;
        case CryptoNetwork.mumbai:
          return CryptoNetworkScanName.mumbai;
        default:
          return CryptoNetworkScanName.polygon;
      }
    });

    const seeTransactionButton = computed(
      () => `${t('crypto.redemption.burning_process.see_on')} ${scanName.value}`
    );

    const openScanPage = () => {
      window.open(scanLink.value, '_blank');
    };

    return { seeTransactionButton, openScanPage };
  },
});
