import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _component_AppText = _resolveComponent("AppText")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_RouterLink, { to: "/security" }, {
      default: _withCtx(() => [
        _createVNode(_component_font_awesome_icon, {
          class: "fa-icon mb-[35px]",
          icon: _ctx.AVAILABLE_ICONS['chevron-left-fas'],
          role: "icon"
        }, null, 8, ["icon"])
      ]),
      _: 1
    }),
    _createVNode(_component_AppText, {
      tag: "h1",
      class: "mb-[35px]",
      size: _ctx.$appTextProps.TextSize['text-3-bold']
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('auth.changePassword.viewTitle')), 1)
      ]),
      _: 1
    }, 8, ["size"])
  ], 64))
}