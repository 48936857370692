
import { defineComponent, PropType } from 'vue';

import { BaseAvatar } from '@BaseComponents/BaseAvatar';
import { DropdownList } from '@BaseComponents/dropdown';
import { IMenuItem } from '@BaseComponents/navbar/types';

export default defineComponent({
  name: 'NavbarDropdown',

  emits: ['handleAction'],

  props: {
    dropdownItems: Array as PropType<Array<IMenuItem>>,
  },

  components: {
    BaseAvatar,
    DropdownList,
  },

  methods: {
    onDropdownAction(payload: IMenuItem) {
      this.$emit('handleAction', payload);
    },
  },
});
