import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex justify-center" }
const _hoisted_2 = { class: "w-full lg:max-w-[410px]" }
const _hoisted_3 = { class: "mt-24" }
const _hoisted_4 = { class: "mt-24 ml-24 list-disc" }
const _hoisted_5 = { class: "mt-24" }
const _hoisted_6 = { class: "flex justify-center mt-24" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppText = _resolveComponent("AppText")!
  const _component_ImageUpload = _resolveComponent("ImageUpload")!
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_AppToast = _resolveComponent("AppToast")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_AppText, {
        size: _ctx.$appTextProps.TextSize['text-3-bold'],
        class: "mb-24"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('kyc.kyc_additional_docs.title')), 1)
        ]),
        _: 1
      }, 8, ["size"]),
      _createVNode(_component_AppText, {
        size: _ctx.$appTextProps.TextSize['text-5-regular']
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t(`${_ctx.currentDocStrings}.sub_title`)), 1)
        ]),
        _: 1
      }, 8, ["size"]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_AppText, {
          size: _ctx.$appTextProps.TextSize['text-5-regular']
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t(`${_ctx.currentDocStrings}.call_to_action`)), 1)
          ]),
          _: 1
        }, 8, ["size"]),
        (_ctx.currentDocDetail)
          ? (_openBlock(), _createBlock(_component_AppText, {
              key: 0,
              size: _ctx.$appTextProps.TextSize['text-5-medium']
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.currentDocDetail), 1)
              ]),
              _: 1
            }, 8, ["size"]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("ul", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.currentDocExamples, (n) => {
          return (_openBlock(), _createBlock(_component_AppText, {
            tag: "li",
            key: n,
            size: _ctx.$appTextProps.TextSize['text-5-regular']
          }, {
            default: _withCtx(() => [
              (_ctx.$t(`${_ctx.currentDocStrings}.doc_examples.${n - 1}`))
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createTextVNode(_toDisplayString(_ctx.$t(`${_ctx.currentDocStrings}.doc_examples.${n - 1}`)), 1)
                  ], 64))
                : _createCommentVNode("", true)
            ]),
            _: 2
          }, 1032, ["size"]))
        }), 128))
      ]),
      _createElementVNode("div", _hoisted_5, [
        (_openBlock(), _createBlock(_component_ImageUpload, {
          key: `file_0_${_ctx.currentDoc}`,
          label: _ctx.getCurrentDocLabel(0),
          onOnImageSelected: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleDocumentUpload($event, 0))),
          onOnReset: _cache[1] || (_cache[1] = ($event: any) => (_ctx.resetSelectedDocument(0)))
        }, null, 8, ["label"])),
        (_ctx.currentDocRequireExtraFile)
          ? (_openBlock(), _createBlock(_component_ImageUpload, {
              key: `file_1_${_ctx.currentDoc}`,
              class: "mt-24",
              label: _ctx.getCurrentDocLabel(1),
              onOnImageSelected: _cache[2] || (_cache[2] = ($event: any) => (_ctx.handleDocumentUpload($event, 1))),
              onOnReset: _cache[3] || (_cache[3] = ($event: any) => (_ctx.resetSelectedDocument(1)))
            }, null, 8, ["label"]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_AppButton, {
          onClick: _ctx.nextStep,
          wide: "",
          inactive: !_ctx.currentDocIsValid,
          label: _ctx.$t('action.continue'),
          loading: _ctx.isLoading,
          size: _ctx.$appButtonProps.Size.medium
        }, null, 8, ["onClick", "inactive", "label", "loading", "size"])
      ])
    ]),
    _createVNode(_component_AppToast, {
      onOnDismiss: _ctx.onDismiss,
      open: _ctx.toastMessage.open,
      dismissable: true,
      message: _ctx.toastMessage.message
    }, null, 8, ["onOnDismiss", "open", "message"])
  ]))
}