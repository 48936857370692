import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2cea56f7"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["data-cy", "data-testid", "disabled", "role", "title", "type"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppSkeletonLoading = _resolveComponent("AppSkeletonLoading")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_BaseLoading = _resolveComponent("BaseLoading")!

  return (_ctx.isSkeletonLoading)
    ? (_openBlock(), _createBlock(_component_AppSkeletonLoading, {
        key: 0,
        height: _ctx.skeletonHeight
      }, null, 8, ["height"]))
    : (_openBlock(), _createElementBlock("button", {
        key: 1,
        class: _normalizeClass(_ctx.classes),
        "data-cy": _ctx.testId,
        "data-testid": _ctx.testId,
        disabled: _ctx.inactive || _ctx.loading,
        role: _ctx.type,
        title: _ctx.label,
        type: _ctx.type
      }, [
        _createElementVNode("span", {
          class: _normalizeClass(["flex justify-center items-center", { 'opacity-0': _ctx.loading }])
        }, [
          (_ctx.icon && _ctx.iconLeft)
            ? (_openBlock(), _createElementBlock("span", {
                key: 0,
                class: _normalizeClass(['button-icon', _ctx.iconColor, _ctx.iconPadding, _ctx.iconSize])
              }, [
                _createVNode(_component_font_awesome_icon, {
                  class: _normalizeClass(['fa-icon', _ctx.iconRotation]),
                  icon: _ctx.icon,
                  role: "icon"
                }, null, 8, ["class", "icon"])
              ], 2))
            : _createCommentVNode("", true),
          _createElementVNode("span", {
            class: _normalizeClass(['button-label', _ctx.textSize])
          }, _toDisplayString(_ctx.label), 3),
          (_ctx.icon && !_ctx.iconLeft)
            ? (_openBlock(), _createElementBlock("span", {
                key: 1,
                class: _normalizeClass(['button-icon', _ctx.iconColor, _ctx.iconPadding, _ctx.iconSize])
              }, [
                _createVNode(_component_font_awesome_icon, {
                  role: "icon",
                  class: _normalizeClass(['fa-icon', _ctx.iconRotation]),
                  icon: _ctx.icon
                }, null, 8, ["class", "icon"])
              ], 2))
            : _createCommentVNode("", true)
        ], 2),
        _createElementVNode("span", {
          class: _normalizeClass(["absolute", { 'opacity-0': !_ctx.loading }])
        }, [
          (_ctx.loading)
            ? (_openBlock(), _createBlock(_component_BaseLoading, {
                key: 0,
                class: _normalizeClass(_ctx.loadingSpinner),
                version: _ctx.loadingProps.Version.v4
              }, null, 8, ["class", "version"]))
            : _createCommentVNode("", true)
        ], 2)
      ], 10, _hoisted_1))
}