
import { defineComponent } from 'vue';

import { storeToRefs } from 'pinia';

import { useKycStore } from '@Modules/Kyc/store';

import BaseInput from '@/components/base/BaseInput/BaseInput.vue';
import { BaseRadioList, BaseSelect } from '@Ui/index';

import ddAnswerQuestion from '@Modules/Kyc/providers/ddAnswerQuestion.request';
import ddGetAnsweredQuestion from '@Modules/Kyc/providers/ddGetAnsweredQuestion.request';

import { IKycDueDiligenceData, DUE_DILIGENCE_QUESTION_TYPE } from './types';

export default defineComponent({
  name: 'KycDueDiligenceQuestion',

  components: {
    BaseInput,
    BaseRadioList,
    BaseSelect,
  },

  setup() {
    const { dueDiligenceQuestion } = storeToRefs(useKycStore());
    const { setDueDiligenceQuestion } = useKycStore();

    return {
      dueDiligenceQuestion,
      DUE_DILIGENCE_QUESTION_TYPE,
      optionsThatRequireExtraInfo: ['Other'],
      setDueDiligenceQuestion,
    };
  },

  data(): IKycDueDiligenceData {
    return {
      currentQuestion: null,
      currentQuestionSelectedOption: undefined,
      currentQuestionSelectedOptionExtraInfo: '',
      isLoading: false,
      showingQuestions: false,
      toastMessage: {
        open: false,
        message: '',
      },
    };
  },

  async mounted() {
    await this.validateQuestion();
  },

  methods: {
    async getCurrentQuestion(questionId: string) {
      try {
        this.isLoading = true;

        this.currentQuestion = await ddGetAnsweredQuestion(questionId);

        this.setDueDiligenceQuestion(this.currentQuestion);

        if (!this.currentQuestion) {
          this.$emit('done');
          return;
        }

        const selectedOption = this.currentQuestion.options.find((option) => option.is_selected);

        this.currentQuestionSelectedOption = selectedOption?.option_id;
      } catch (err) {
        this.toastMessage = {
          open: true,
          message: this.$t('errors.generic'),
        };
      } finally {
        this.isLoading = false;
      }
    },

    async nextQuestion() {
      try {
        this.isLoading = true;

        const currentQuestion = await ddAnswerQuestion({
          question_id: this.currentQuestion?.question_id || '',
          answer: [
            {
              option_id: this.currentQuestionSelectedOption || '',
              answer_complement: this.currentQuestionSelectedOptionExtraInfo,
            },
          ],
        });

        this.setDueDiligenceQuestion(currentQuestion);
        this.currentQuestionSelectedOption = undefined;
        this.currentQuestionSelectedOptionExtraInfo = '';

        if (!currentQuestion) {
          this.$emit('done');
          return;
        }

        this.currentQuestion = currentQuestion;
        this.$router.push(`/due-diligence/${currentQuestion.question_id}`);
      } catch (err) {
        this.toastMessage = {
          open: true,
          message: this.$t('errors.generic'),
        };
      } finally {
        this.isLoading = false;
      }
    },

    onDismiss() {
      this.toastMessage.open = false;
    },

    showQuestions() {
      this.showingQuestions = true;
    },

    async validateQuestion() {
      const routeId = this.$route.params.questionId as string;

      const sameQuestion = this.dueDiligenceQuestion?.question_id === routeId;

      if (!sameQuestion) {
        await this.getCurrentQuestion(routeId);
        return;
      }

      this.currentQuestion = this.dueDiligenceQuestion;
    },
  },

  watch: {
    $route() {
      this.validateQuestion();
    },
  },

  computed: {
    currentQuestionSelectedOptionLabel(): string {
      const currentOptionId = this.currentQuestionSelectedOption as string;
      const currentOptionLabel = this.currentQuestion?.options.find(
        (option) => option.option_id === currentOptionId
      )?.option_label;
      return currentOptionLabel || '';
    },
  },
});
