
import { defineComponent, PropType } from 'vue';
import StatusLabel from '@/components/ui/StatusLabel/StatusLabel.vue';
import { KycStatusActions } from '@Modules/Kyc/types';

export default defineComponent({
  name: 'KycStatus',

  components: {
    StatusLabel,
  },

  props: {
    bulletPoints: {
      type: Array as PropType<Array<boolean>>,
      required: true,
    },

    bulletPointsText: {
      type: Array as PropType<Array<string>>,
      required: true,
    },

    currentAction: {
      type: String as PropType<KycStatusActions>,
      required: true,
    },

    isLoading: {
      type: Boolean,
    },

    isPageLoading: {
      type: Boolean,
    },
  },

  emits: ['onKycAction'],

  setup() {
    return {
      KycStatusActions,
    };
  },

  computed: {
    loading() {
      return {
        active: this.isPageLoading,
        height: 34,
      };
    },
  },
});
