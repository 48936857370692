
import { defineComponent, PropType } from 'vue';
import VueHcaptcha from '@hcaptcha/vue3-hcaptcha';
import { Sizes } from './types';

export default defineComponent({
  name: 'BaseCaptcha',

  emits: ['challengeExpired', 'closed', 'error', 'expired', 'opened', 'verify'],

  setup() {
    return {
      Sizes,
    };
  },

  components: { VueHcaptcha },

  props: {
    size: {
      type: String as PropType<Sizes>,
      default: Sizes.normal,
    },

    testId: {
      type: String,
      default: 'unamed',
    },
  },

  computed: {
    captchaKey() {
      return process.env.VUE_APP_CAPTCHA_SITE_KEY;
    },
  },

  methods: {
    handleError(err: Error): void {
      this.$emit('error', err);
    },

    handleVerify(captcha: string, eKey: string): void {
      this.$emit('verify', captcha, eKey);
    },
  },
});
