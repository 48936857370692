import { useWalletStore, useDibbsContract } from '@Modules/Crypto';

const useNFT = () => {
  const store = useWalletStore();
  const { getContractNftsByAddress } = useDibbsContract();

  const getNFTListByAddress = async (address: string) => {
    if (!address) return;

    getContractNftsByAddress(address).then((data) => {
      store.setDibbsNftList(data || []);
      store.dibbsNftsLoaded = true;
    });
  };

  return { getNFTListByAddress };
};

export default useNFT;
