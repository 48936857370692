
import { defineComponent, PropType } from 'vue';
import { IBaseRadioItem } from './types';
import BaseRadio from '../BaseRadio/BaseRadio.vue';

export default defineComponent({
  name: 'BaseRadioList',

  components: {
    BaseRadio,
  },

  props: {
    items: {
      type: Array as PropType<Array<IBaseRadioItem>>,
      required: true,
    },

    name: {
      type: String,
      required: true,
    },

    modelValue: {
      type: [String, Number],
    },
  },

  emits: {
    'update:modelValue': (value: string | number) => value,
  },

  computed: {
    model: {
      get() {
        return this.modelValue;
      },

      set(value: string) {
        this.$emit('update:modelValue', value);
      },
    },
  },

  methods: {
    getRadioInputId(index: number) {
      return `radio_${this.name}_${index}`;
    },

    isSelected(item: IBaseRadioItem) {
      return this.model === item.value;
    },
  },
});
