
import { defineComponent } from 'vue';
import { AVAILABLE_ICONS } from '@Libraries/font-awesome';

export default defineComponent({
  name: 'ChangePasswordHeader',

  setup() {
    return {
      AVAILABLE_ICONS,
    };
  },
});
