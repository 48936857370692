import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-86359fd6"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src", "alt"]
const _hoisted_2 = {
  key: 2,
  class: "lineCard__image lineCard__image--noImage"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppSkeletonLoading = _resolveComponent("AppSkeletonLoading")!
  const _component_AppText = _resolveComponent("AppText")!
  const _component_AppButton = _resolveComponent("AppButton")!

  return (_openBlock(), _createElementBlock("section", {
    class: _normalizeClass(["lineCard", { lineCard__darkerBackground: _ctx.darkerBackground }])
  }, [
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_AppSkeletonLoading, {
          key: 0,
          class: "lineCard__image",
          height: "50px",
          width: "50px"
        }))
      : (_ctx.imageUrl && !_ctx.loading)
        ? (_openBlock(), _createElementBlock("img", {
            key: 1,
            class: "lineCard__image",
            src: _ctx.imageUrl,
            alt: _ctx.title
          }, null, 8, _hoisted_1))
        : (_openBlock(), _createElementBlock("div", _hoisted_2)),
    _createVNode(_component_AppText, {
      tag: "h1",
      class: "lineCard__title",
      loading: _ctx.loading,
      size: _ctx.$appTextProps.TextSize['text-5-regular']
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.title || _ctx.$t('crypto.no_name')), 1)
      ]),
      _: 1
    }, 8, ["loading", "size"]),
    (_ctx.buttonText)
      ? (_openBlock(), _createBlock(_component_AppButton, {
          key: 3,
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('onClickButton'))),
          testId: "nft-line-card",
          class: "lineCard__button",
          label: _ctx.buttonText,
          size: _ctx.$appButtonProps.Size.small
        }, null, 8, ["label", "size"]))
      : _createCommentVNode("", true)
  ], 2))
}