export default [
  {
    path: '/confirm-email',
    name: 'confirm-email',
    component: () => import('@/views/ConfirmEmailView/ConfirmEmailView.vue'),
    meta: {
      isPublic: true,
    },
  },
];
