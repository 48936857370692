import { ref, reactive } from 'vue';
import {
  applyRegex,
  validatePasswordLength,
} from '@Utils/helpers/PasswordValidation/PasswordValidation';

export interface PasswordValidationConditions {
  hasLowerCaseLetter: boolean;
  hasNumber: boolean;
  hasSpecialCharacter: boolean;
  hasUpperCaseLetter: boolean;
  hasValidSize: boolean;
}

export const usePasswordValitation = () => {
  const isAllValid = ref(false);
  const passwordConditions = reactive<PasswordValidationConditions>({
    hasLowerCaseLetter: false,
    hasNumber: false,
    hasSpecialCharacter: false,
    hasUpperCaseLetter: false,
    hasValidSize: false,
  });

  const validatePassword = (password: string) => {
    passwordConditions.hasLowerCaseLetter = applyRegex('hasLowerCaseLetter', password);
    passwordConditions.hasNumber = applyRegex('hasNumber', password);
    passwordConditions.hasSpecialCharacter = applyRegex('hasSpecialCharacter', password);
    passwordConditions.hasUpperCaseLetter = applyRegex('hasUpperCaseLetter', password);
    passwordConditions.hasValidSize = validatePasswordLength(password);

    isAllValid.value =
      passwordConditions.hasLowerCaseLetter &&
      passwordConditions.hasNumber &&
      passwordConditions.hasSpecialCharacter &&
      passwordConditions.hasUpperCaseLetter &&
      passwordConditions.hasValidSize;

    return isAllValid.value;
  };

  return {
    validatePassword: validatePassword,
    passwordConditions: passwordConditions,
    isAllValid: isAllValid,
  };
};

export default usePasswordValitation;
