
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'TwoFactorAuthenticationBackupCode',

  emits: ['continue', 'copycode', 'error'],

  props: {
    recovery: {
      type: String,
      required: true,
    },
  },

  methods: {
    unsafeCopyAction() {
      const textArea = document.createElement('textarea');
      textArea.value = this.recovery;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      try {
        document.execCommand('copy');
      } catch (err) {
        console.error('Unable to copy to clipboard', err);
      }
      document.body.removeChild(textArea);
    },

    copyAction() {
      if (!navigator.clipboard) {
        this.unsafeCopyAction();
        return;
      }

      navigator.clipboard.writeText(this.recovery);
      this.$emit('copycode', { message: this.$t('auth.twofa.backup_code.backup_code_copy') });
    },
  },

  mounted() {
    if (!this.recovery) {
      this.$emit('error');
    }
  },
});
