import { ADDRESS_TYPE } from '@Shared/enums/addressType';
import { ACCOUNT_TYPE } from '@Shared/enums/accountType';

export const operatorStoreMock = {
  accountType: ACCOUNT_TYPE.INDIVIDUAL,
  address: [
    {
      type: ADDRESS_TYPE.PRINCIPAL,
      address_city: 'Test City',
      address_complement: 'Apt 1',
      address_country: 'EUA',
      address_state: 'LA',
      address_street: 'Principal Test Street',
      address_zip: '1111-1',
    },
    {
      type: ADDRESS_TYPE.INCORPORATED,
      address_city: 'Test City',
      address_complement: 'Apt 1',
      address_country: 'EUA',
      address_state: 'LA',
      address_street: 'Incorporated Test Street',
      address_zip: '1111-1',
    },
  ],
  email: 'operator@email.com',
};
