import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppText = _resolveComponent("AppText")!
  const _component_BaseInput = _resolveComponent("BaseInput")!
  const _component_InputPassword = _resolveComponent("InputPassword")!
  const _component_BaseCheckbox = _resolveComponent("BaseCheckbox")!
  const _component_AppButton = _resolveComponent("AppButton")!

  return (_openBlock(), _createElementBlock("form", {
    onSubmit: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onLogin && _ctx.onLogin(...args)), ["prevent"]))
  }, [
    _createVNode(_component_BaseInput, {
      modelValue: _ctx.email,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.email) = $event)),
      autofocus: "",
      class: "mb-16",
      externalContainerClasses: "relative",
      testId: "base-input_email",
      validate: [_ctx.isEmailValid(_ctx.email) || _ctx.$t('forms.errors.invalid_email')],
      lpIgnore: false,
      type: _ctx.inputProps.Type.email,
      placeholder: _ctx.$t('auth.login.form.email.placeholder')
    }, {
      default: _withCtx(() => [
        _createVNode(_component_AppText, {
          tag: "label",
          class: "mb-8",
          size: _ctx.$appTextProps.TextSize['text-5-medium']
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('auth.login.form.email.label')), 1)
          ]),
          _: 1
        }, 8, ["size"])
      ]),
      _: 1
    }, 8, ["modelValue", "validate", "type", "placeholder"]),
    _createVNode(_component_InputPassword, {
      testId: "base-input_password",
      label: _ctx.$t('auth.login.form.password.label'),
      placeholder: _ctx.$t('auth.login.form.password.placeholder'),
      onUpdate: _ctx.setPassword
    }, null, 8, ["label", "placeholder", "onUpdate"]),
    _createVNode(_component_AppText, {
      tag: "p",
      size: _ctx.$appTextProps.TextSize['text-6-medium'],
      class: "mb-24 cursor-pointer",
      color: "text-text-inactive",
      onClick: _ctx.onShowRecoveryPwdModal
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('auth.login.recovery_password.action')), 1)
      ]),
      _: 1
    }, 8, ["size", "onClick"]),
    _createVNode(_component_BaseCheckbox, {
      modelValue: _ctx.keep,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.keep) = $event)),
      class: "mb-24"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_AppText, {
          tag: "p",
          size: _ctx.$appTextProps.TextSize['text-5-regular']
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('auth.login.actions.keep')), 1)
          ]),
          _: 1
        }, 8, ["size"])
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_AppButton, {
      submit: "",
      wide: "",
      class: "mb-12",
      testId: "app-button_login",
      inactive: !_ctx.isFormReady,
      loading: _ctx.loading,
      size: _ctx.$appButtonProps.Size.medium,
      label: _ctx.$t('auth.login.actions.login')
    }, null, 8, ["inactive", "loading", "size", "label"]),
    _createVNode(_component_AppButton, {
      wide: "",
      testId: "app-button_signup",
      size: _ctx.$appButtonProps.Size.medium,
      label: _ctx.$t('auth.login.actions.signup'),
      version: _ctx.$appButtonProps.Version.secondary,
      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$router.push('/signup')))
    }, null, 8, ["size", "label", "version"])
  ], 32))
}