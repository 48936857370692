import { IToastProps } from '@/plugins/components/AppToast/types';

export interface IKycIndividualProvideIdentityData {
  availableCountries: Array<string>;
  currentAction: KycIndividualProvideIdentityActions;
  isLoading: boolean;
  toastMessage: IToastProps;
}

export enum KycIndividualProvideIdentityActions {
  selectCountry = 'select_country',
  nameFields = 'name_fields',
  provideAddress = 'provide_address',
  provideSsn = 'provide_ssn',
}
