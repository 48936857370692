import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-081cf326"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "profile-container",
  "data-testid": "navbar-dropdown"
}
const _hoisted_2 = { class: "cursor-pointer text-text-body" }
const _hoisted_3 = { class: "flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseAvatar = _resolveComponent("BaseAvatar")!
  const _component_DropdownList = _resolveComponent("DropdownList")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_DropdownList, {
      dropdownItems: _ctx.dropdownItems,
      customClasses: "navbar-dropdown",
      onDropdownAction: _ctx.onDropdownAction
    }, {
      "dropdown-button": _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_BaseAvatar)
          ])
        ])
      ]),
      _: 1
    }, 8, ["dropdownItems", "onDropdownAction"])
  ]))
}