import AppButtonPlugin from './components/AppButton/plugin';
import AppTextPlugin from './components/AppText/plugin';
import AppSkeletonLoadingPlugin from './components/AppSkeletonLoading/plugin';
import AppToastPlugin from './components/AppToast/plugin';
import BaseLayoutPlugin from './layouts/BaseLayout/plugin';

export default [
  AppButtonPlugin,
  AppSkeletonLoadingPlugin,
  AppTextPlugin,
  AppToastPlugin,
  BaseLayoutPlugin,
];
