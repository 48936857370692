import { defineStore } from 'pinia';
import { ValidationError } from './types';

export interface OperatorState {
  validationError?: ValidationError;
}

export const useRouteGuardStore = defineStore('routeGuard', {
  state: (): OperatorState => ({
    validationError: undefined,
  }),

  getters: {
    hasValidationError(state) {
      return !!state.validationError;
    },
  },
});
