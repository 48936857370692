import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0e22fa43"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex justify-center items-center" }
const _hoisted_2 = { class: "relative inline-block z-overlay" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppButton = _resolveComponent("AppButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isOpen)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onToggle && _ctx.onToggle(...args))),
          class: "dropdown-overlay",
          "data-testid": "dropdown-overlay"
        }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onToggle && _ctx.onToggle(...args))),
        "data-testid": "dropdown-button"
      }, [
        _renderSlot(_ctx.$slots, "dropdown-button", {}, () => [
          _createVNode(_component_AppButton, {
            class: "cursor-pointer",
            testId: 'btn__' + _ctx.selectedDropdown.label,
            icon: _ctx.iconProps.icon,
            iconPosition: _ctx.iconProps.position,
            iconRotation: _ctx.iconProps.rotation,
            label: _ctx.selectedDropdown.label,
            size: _ctx.$appButtonProps.Size.small,
            version: _ctx.$appButtonProps.Version.tertiary
          }, null, 8, ["testId", "icon", "iconPosition", "iconRotation", "label", "size", "version"])
        ], true)
      ]),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.dropdownClasses),
        "data-testid": "dropdown-container"
      }, [
        (_ctx.isOpen)
          ? _renderSlot(_ctx.$slots, "default", { key: 0 }, undefined, true)
          : _createCommentVNode("", true)
      ], 2)
    ])
  ]))
}