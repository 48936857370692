<template>
  <div class="flex justify-start items-center">
    <label :data-testid="`${testId}-checkbox`" class="checkbox-container" :class="isDisabled">
      <input
        v-model="model"
        id="checkbox"
        :disabled="disabled"
        data-testid="checkbox"
        data-lpignore="true"
        type="checkbox"
      />
      <span class="checkmark"></span>
    </label>
    <label v-if="showLabel" :class="isDisabled" class="text-5-regular pl-12" for="checkbox">
      <slot>
        {{ label }}
      </slot>
    </label>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'BaseCheckbox',
  emits: ['update:modelValue'],
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
    },
    testId: {
      type: String,
      default: 'test',
    },
    modelValue: {
      type: Boolean,
    },
  },
  computed: {
    model: {
      get() {
        return this.modelValue;
      },

      set(value) {
        this.$emit('update:modelValue', value);
      },
    },

    isDisabled() {
      return !this.disabled ? 'cursor-pointer' : '';
    },

    showLabel() {
      return !!this.label || !!this.$slots.default;
    },
  },
});
</script>

<style scoped>
.checkbox-container {
  @apply bg-background-page;
  display: block;
  position: relative;
  padding: 18px 0 0 18px;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  @apply border-2 border-text-body;
  border-radius: 1px;
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
}

.checkbox-container input:checked ~ .checkmark {
  @apply bg-background-active-nav border-border-active-nav;
}

.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

.checkbox-container .checkmark:after {
  @apply border-background-primary;
  left: 4px;
  top: 1px;
  width: 6px;
  height: 10px;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
</style>
