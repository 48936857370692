import { apollo, DibbsApiException, DibbsErrorHelper, errorHandler } from '@/utils/helpers/api';

import REDEMPTION_DATA from '../graphql/queries/RedemptionById.gql';
import { IRedemptionItem } from '../types';

interface Params {
  redemptionId: string;
}

interface Result {
  redemption: IRedemptionItem;
}

export async function getRedemptionData(variables: Params): Promise<Result['redemption']> {
  return apollo
    .query<Result>({
      query: REDEMPTION_DATA,
      variables: { ...variables },
      fetchPolicy: 'no-cache',
    })
    .then(({ data }) => DibbsErrorHelper(data?.redemption))
    .then((data) => data)
    .catch((error) => {
      throw new DibbsApiException(errorHandler(error));
    });
}

export default getRedemptionData;
