import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-full max-w-[468px]" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppText = _resolveComponent("AppText")!
  const _component_BaseInput = _resolveComponent("BaseInput")!
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_BaseCaptcha = _resolveComponent("BaseCaptcha")!
  const _component_BaseModal = _resolveComponent("BaseModal")!

  return (_openBlock(), _createBlock(_component_BaseModal, _mergeProps(_ctx.$attrs, { clickToClose: "" }), {
    title: _withCtx(() => [
      _createVNode(_component_AppText, {
        tag: "h1",
        class: "w-full max-w-[468px]",
        size: _ctx.$appTextProps.TextSize['text-3-bold']
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('auth.reset_password.modal.title')), 1)
        ]),
        _: 1
      }, 8, ["size"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_AppText, {
          tag: "p",
          class: "w-full max-w-[468px] mb-24",
          size: _ctx.$appTextProps.TextSize['text-5-regular']
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('auth.reset_password.modal.message')), 1)
          ]),
          _: 1
        }, 8, ["size"]),
        _createElementVNode("form", {
          class: "flex-col",
          onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onRequestPwd && _ctx.onRequestPwd(...args)), ["prevent"]))
        }, [
          _createVNode(_component_BaseInput, {
            modelValue: _ctx.email,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.email) = $event)),
            autofocus: "",
            class: "mb-[28px]",
            externalContainerClasses: "relative",
            testId: `${_ctx.testId}_email`,
            validate: [_ctx.isEmailValid(_ctx.email) || _ctx.$t('forms.errors.invalid_email')],
            lpIgnore: false,
            type: _ctx.inputProps.Type.email,
            placeholder: _ctx.$t('auth.reset_password.modal.form.placeholder')
          }, null, 8, ["modelValue", "testId", "validate", "type", "placeholder"]),
          _createVNode(_component_AppButton, {
            submit: "",
            wide: "",
            class: "mb-12",
            testId: `${_ctx.testId}_submit`,
            inactive: !_ctx.isFormReady,
            loading: _ctx.loading,
            size: _ctx.$appButtonProps.Size.medium,
            label: _ctx.$t('auth.reset_password.modal.form.submit')
          }, null, 8, ["testId", "inactive", "loading", "size", "label"])
        ], 32),
        _withDirectives(_createVNode(_component_BaseCaptcha, { onVerify: _ctx.setCaptcha }, null, 8, ["onVerify"]), [
          [_vShow, _ctx.requiresCaptcha]
        ])
      ])
    ]),
    _: 1
  }, 16))
}