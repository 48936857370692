import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col justify-center items-center" }
const _hoisted_2 = { class: "max-w-[410px]" }
const _hoisted_3 = {
  "data-testid": "socureDiv",
  id: "socureDiv",
  class: "min-w-[300px] md:min-w-[768px]"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppText = _resolveComponent("AppText")!
  const _component_AppSkeletonLoading = _resolveComponent("AppSkeletonLoading")!
  const _component_AppToast = _resolveComponent("AppToast")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_AppText, {
        class: "w-full",
        size: _ctx.$appTextProps.TextSize['text-3-bold']
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('kyc.kyc_documents.title')), 1)
        ]),
        _: 1
      }, 8, ["size"]),
      _createVNode(_component_AppText, { class: "mt-24" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('kyc.kyc_documents.sub_title')), 1)
        ]),
        _: 1
      })
    ]),
    _withDirectives(_createVNode(_component_AppSkeletonLoading, {
      class: "mt-32",
      "data-testid": "loading",
      height: "524px",
      width: "345px"
    }, null, 512), [
      [_vShow, _ctx.isLoading]
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_3, null, 512), [
      [_vShow, !_ctx.isLoading]
    ]),
    _createVNode(_component_AppToast, {
      onOnDismiss: _ctx.onDismiss,
      dismissable: true,
      message: _ctx.toastMessage.message,
      open: _ctx.toastMessage.open
    }, null, 8, ["onOnDismiss", "message", "open"])
  ]))
}