import { AVAILABLE_ICONS } from '@Libraries/font-awesome';

export enum KEYS {
  ARROW_UP = 'ArrowUp',
  ARROW_DOWN = 'ArrowDown',
  ENTER = 'Enter',
  ESC = 'Escape',
  TAB = 'Tab',
}

export interface ISelectItem {
  label: string;
  value: string;
}

export interface IBaseSelect {
  AVAILABLE_ICONS: typeof AVAILABLE_ICONS;
  input: string;
  itemSelected: boolean;
  filteredList: Array<ISelectItem>;
  highlight: boolean;
  isOpen: boolean;
  isLoading: boolean;
  totalHeight: number;
  focusedItem: number | null;
}
