
import { defineComponent } from 'vue';
import { IChangePasswordForm, IChangePasswordPayload } from '../types';
import { isPasswordValid } from '@Helpers/PasswordValidation/PasswordValidation';
import { InputPassword } from '@Ui/InputPassword';
import PasswordValidationMessage from '@Modules/Auth/components/PasswordValidationMessage/PasswordValidationMessage.vue';
import { usePasswordValitation } from '@/components/modules';

export default defineComponent({
  name: 'ChangePasswordForm',

  setup() {
    const { validatePassword, passwordConditions } = usePasswordValitation();
    return {
      isPasswordValid,
      validatePassword,
      passwordConditions,
    };
  },

  emits: {
    update: (form: IChangePasswordPayload) => form,
  },

  components: {
    InputPassword,
    PasswordValidationMessage,
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data(): IChangePasswordForm {
    return {
      form: {
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
      },
    };
  },

  computed: {
    isValidPassword() {
      const currentExists = !!this.form.currentPassword;
      const isValidPassword =
        isPasswordValid(this.form.newPassword) && isPasswordValid(this.form.confirmPassword);
      const newPasswordMatch = this.form.newPassword === this.form.confirmPassword;

      return currentExists && isValidPassword && newPasswordMatch;
    },

    hasPasswordError() {
      return !!this.form.newPassword && !this.isPasswordValid(this.form.newPassword);
    },
  },

  methods: {
    updatePassword() {
      this.$emit('update', this.form);
    },
  },
});
