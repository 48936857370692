
import { defineComponent } from 'vue';

import CountryFlag from 'vue-country-flag-next';
import { AVAILABLE_ICONS } from '@Libraries/font-awesome';

import BaseInput from '@/components/base/BaseInput/BaseInput.vue';

import { ICountry } from '../KycCountry/types';
import countries from '../KycCountry/countries.json';

export default defineComponent({
  name: 'KycCountrySelector',

  components: {
    BaseInput,
    CountryFlag,
  },

  props: {
    currentCountry: {
      type: String,
      default: 'US',
    },
  },

  emits: ['selected'],

  data() {
    return {
      AVAILABLE_ICONS,
      countrySearch: '',
    };
  },

  computed: {
    countriesFilteredByFirstLetter() {
      return this.filteredCountryList.reduce(
        (
          acc: {
            [key: string]: Array<ICountry>;
          },
          obj: ICountry
        ) => {
          let key = obj['name'].split('')[0];

          if (!acc[key]) {
            acc[key] = [];
          }

          acc[key].push(obj);

          return acc;
        },
        {}
      );
    },

    filteredCountryList() {
      document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
      const lowerCaseValue = this.countrySearch.toLowerCase();

      if (this.countrySearch) {
        return countries.filter((item) => String(item.name).toLowerCase().includes(lowerCaseValue));
      }

      return countries;
    },

    selectText(): string {
      return countries.find((item) => item.code === this.currentCountry)?.name || '';
    },
  },

  methods: {
    selectCountry(newValue: string) {
      this.$emit('selected', newValue);
    },
  },
});
