export default [
  {
    path: '/redemption',
    name: 'redemption',
    component: () => import('@/views/RedemptionsView/RedemptionView.vue'),
  },
  {
    path: '/confirm-redemption',
    name: 'confirm-redemption',
    component: () => import('@/views/RedemptionsView/RedemptionConfirmedView.vue'),
  },
  {
    path: '/burn-nft/:redemptionId',
    name: 'burn-nft',
    component: () => import('@/views/RedemptionsView/BurnNFTView.vue'),
  },
  {
    path: '/redeem-status/:redemptionId',
    name: 'redeem-status',
    component: () =>
      import('@/views/RedemptionsView/RedemptionStatusView/RedemptionStatusView.vue'),
  },
];
