import { createI18n } from 'vue-i18n';
import en from '@Libraries/translations/en.json';
import ptBr from '@Libraries/translations/pt-br.json';

const i18n = createI18n({
  locale: 'en',
  fallbackLocale: 'en',
  allowComposition: true,
  messages: {
    en,
    ptBr,
  },
});

export default i18n;
