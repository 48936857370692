import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1ed5d9a2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex justify-between max-w-[982px]" }
const _hoisted_2 = { class: "block" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppText = _resolveComponent("AppText")!
  const _component_OperatorAddress = _resolveComponent("OperatorAddress")!
  const _component_BaseRadio = _resolveComponent("BaseRadio")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.addresses, (address, index) => {
      return (_openBlock(), _createBlock(_component_BaseRadio, {
        modelValue: _ctx.model,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model) = $event)),
        class: _normalizeClass(['address-selector-item', _ctx.getSelectedClass(address)]),
        isSelected: _ctx.getSelectedStatus(address),
        key: _ctx.getAddressKey(address, index),
        radioInputId: address.type,
        value: address.type
      }, {
        default: _withCtx(() => [
          _createElementVNode("section", _hoisted_2, [
            _createVNode(_component_AppText, {
              tag: "h3",
              size: _ctx.$appTextProps.TextSize['text-4-medium']
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.getAddressHeader(address)), 1)
              ]),
              _: 2
            }, 1032, ["size"]),
            _createVNode(_component_OperatorAddress, {
              address: address,
              loading: _ctx.loading
            }, null, 8, ["address", "loading"])
          ])
        ]),
        _: 2
      }, 1032, ["modelValue", "class", "isSelected", "radioInputId", "value"]))
    }), 128))
  ]))
}