
import { defineComponent, PropType } from 'vue';
import { inputProps } from '@BaseUi';
import { IStoreKycIndividualNameFields } from '@Modules/Kyc/types';
import { IKycIndividualNameFieldsData, IIndividualNameFieldsForm } from './types';
import KycIndividualNameInputs from '../KycIndividualNameInputs/KycIndividualNameInputs.vue';
import {
  isFirstNameValid,
  isLastNameValid,
  isMiddleNameValid,
  isPhoneValid,
  hasSpecialCharacters,
} from '@/utils';

export default defineComponent({
  name: 'KycIndividualNameFields',
  props: {
    storedNameFields: Object as PropType<IStoreKycIndividualNameFields>,
  },
  emits: {
    setNameFields: (nameFieldsForm: IIndividualNameFieldsForm) => nameFieldsForm,
  },
  setup() {
    return { hasSpecialCharacters };
  },
  data(): IKycIndividualNameFieldsData {
    return {
      form: {
        firstName: '',
        middleName: '',
        lastName: '',
        phone: '',
        birthday: undefined,
        ...this.storedNameFields,
      },
      inputProps,
    };
  },
  computed: {
    isFormValid(): boolean {
      return (
        isFirstNameValid(this.form.firstName) &&
        isMiddleNameValid(this.form.middleName) &&
        isLastNameValid(this.form.lastName) &&
        !!isPhoneValid(this.form.phone) &&
        !!this.form.birthday
      );
    },
  },
  methods: {
    setNameFields() {
      this.$emit('setNameFields', { ...this.form });
    },
  },
  components: { KycIndividualNameInputs },
});
