import { apollo, resetApolloClientOnLogin } from '@Libraries/apollo';
import { useAuthStore } from '../../Auth';
import { useFillCurrentOperator } from '../composables';
import { fetchCurrentOperatorRequest } from '../providers';

export default function () {
  const authStore = useAuthStore();
  const { fillCurrentOperator } = useFillCurrentOperator();

  async function refreshOperator(token: string) {
    await resetApolloClientOnLogin(apollo, token);

    return await fetchCurrentOperatorRequest()
      .then((operator) => {
        fillCurrentOperator(operator);
        authStore.isAuthenticated = true;
      })
      .then(() => true)
      .catch((err) => {
        throw new Error(err.name);
      });
  }

  return {
    refreshOperator,
  };
}
