import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseInput = _resolveComponent("BaseInput")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_BaseInput, {
      modelValue: _ctx.form.firstName,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.firstName) = $event)),
      autofocus: "",
      class: "mt-16",
      maxlength: "240",
      name: "first_name",
      testId: "first_name",
      disabled: _ctx.readonly,
      required: true,
      "lp-ignore": false,
      validate: [
      _ctx.isFirstNameValid(_ctx.form.firstName) ||
        _ctx.invalidFieldError(_ctx.$t('kyc.kyc_name_fields_individual.form.first_name')),
    ]
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('kyc.kyc_name_fields_individual.form.first_name')), 1)
      ]),
      _: 1
    }, 8, ["modelValue", "disabled", "validate"]),
    _createVNode(_component_BaseInput, {
      modelValue: _ctx.form.middleName,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.middleName) = $event)),
      class: "mt-24",
      maxlength: "240",
      name: "middle_name",
      testId: "middle_name",
      disabled: _ctx.readonly,
      validate: [
      _ctx.isMiddleNameValid(_ctx.form.middleName) ||
        _ctx.invalidFieldError(_ctx.$t('kyc.kyc_name_fields_individual.form.middle_name')),
    ],
      "lp-ignore": false
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('kyc.kyc_name_fields_individual.form.middle_name')) + " " + _toDisplayString(!_ctx.readonly ? `(${_ctx.$t('action.optional')})` : ''), 1)
      ]),
      _: 1
    }, 8, ["modelValue", "disabled", "validate"]),
    _createVNode(_component_BaseInput, {
      modelValue: _ctx.form.lastName,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.lastName) = $event)),
      class: "mt-24",
      maxlength: "240",
      name: "last_name",
      testId: "last_name",
      required: true,
      disabled: _ctx.readonly,
      validate: [
      _ctx.isLastNameValid(_ctx.form.lastName) ||
        _ctx.invalidFieldError(_ctx.$t('kyc.kyc_name_fields_individual.form.last_name')),
    ],
      "lp-ignore": false
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('kyc.kyc_name_fields_individual.form.last_name')), 1)
      ]),
      _: 1
    }, 8, ["modelValue", "disabled", "validate"]),
    _createVNode(_component_BaseInput, {
      modelValue: _ctx.form.phone,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.form.phone) = $event)),
      class: "mt-24",
      mask: "###-###-####",
      name: "phone",
      testId: "phone",
      disabled: _ctx.readonly,
      "lp-ignore": false,
      required: true,
      validate: [
      _ctx.isPhoneValid(_ctx.form.phone) ||
        _ctx.invalidFieldError(_ctx.$t('kyc.kyc_name_fields_individual.form.phone')),
      !_ctx.hasSpecialCharacters(_ctx.form.phone) ||
        _ctx.invalidFieldError(_ctx.$t('kyc.kyc_name_fields_individual.form.phone')),
    ]
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('kyc.kyc_name_fields_individual.form.phone')), 1)
      ]),
      _: 1
    }, 8, ["modelValue", "disabled", "validate"]),
    _createVNode(_component_BaseInput, {
      modelValue: _ctx.form.birthday,
      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.form.birthday) = $event)),
      class: "mt-24",
      name: "birthday",
      testId: "birthday",
      required: true,
      disabled: _ctx.readonly,
      "lp-ignore": false,
      type: _ctx.inputProps.Type.datepicker
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('kyc.kyc_name_fields_individual.form.birthday')), 1)
      ]),
      _: 1
    }, 8, ["modelValue", "disabled", "type"])
  ], 64))
}