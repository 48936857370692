import { useI18n } from 'vue-i18n';
import { ethers as Crypto } from '@Libraries/index';
import { useGlobalToast } from '@/utils/composables';
import {
  useWalletStore,
  useNFT,
  Address,
  CryptoAccount,
  CryptoNoWalletError,
  CryptoUserDeniedAccessError,
  getMetamaskProvider,
  isValidNetwork,
  CryptoUserDeclinedSignError,
} from '@Modules/Crypto';

const useWalletProvider = () => {
  let crypto: Crypto;
  const store = useWalletStore();
  const { getNFTListByAddress } = useNFT();
  const { t } = useI18n();
  const { showError } = useGlobalToast();

  const verifyProvider = async (): Promise<Crypto> => {
    if (!crypto) {
      try {
        const provider = await getMetamaskProvider();
        crypto = new Crypto(provider);
        return crypto;
      } catch (err) {
        store.disconnectWallet();
        throw err;
      }
    }
    return crypto;
  };

  const connectToWallet = async (): Promise<Address[]> => {
    await verifyProvider().catch(showError);

    const chainId = await crypto.getChainId();
    if (!(await isValidNetwork(chainId))) {
      showError(t(`crypto.errors.unsupported_network`));
      return [];
    }
    const accounts = await crypto.requestAccounts();
    return accounts;
  };

  const checkConnection = async () => {
    try {
      await verifyProvider();
    } catch (error) {
      return;
    }
    const accounts = await crypto.verifyAccounts();
    return accounts.length > 0;
  };

  const getAccountData = async (): Promise<CryptoAccount | null> => {
    const address: Address = await crypto.getSignerAddress();
    if (address) {
      const balance = await crypto.getEthAccountBalance();
      const balanceWei = await crypto.getWeiAccountBalance();
      return {
        address,
        balance,
        balanceWei,
      };
    } else {
      throw CryptoNoWalletError;
    }
  };

  const requestSignature = async (message: string) => {
    const isAlreadyConnected = await checkConnection();

    if (!isAlreadyConnected) {
      return store.disconnectWallet();
    }

    try {
      const signature = await crypto.signInPersonal(message);
      return signature;
    } catch (error) {
      throw CryptoUserDeclinedSignError;
    }
  };

  const updateCryptoCurrentUser = async () => {
    const isAlreadyConnected = await checkConnection();

    if (!isAlreadyConnected) {
      return store.disconnectWallet();
    }

    const chainId = await crypto.getChainId();
    if (!(await isValidNetwork(chainId))) {
      store.disconnectWallet();
      showError(t(`crypto.errors.unsupported_network`));
      return;
    }

    try {
      const cryptoAccount = await getAccountData();

      if (!cryptoAccount) {
        return store.disconnectWallet();
      }

      store.setAccountInfos(cryptoAccount);
      getNFTListByAddress(cryptoAccount.address);
    } catch (error) {
      store.disconnectWallet();
      // eslint-disable-next-line no-console
      console.error(CryptoUserDeniedAccessError);
    }
  };

  return {
    updateCryptoCurrentUser,
    connectToWallet,
    checkConnection,
    verifyProvider,
    showError,
    requestSignature,
  };
};

export default useWalletProvider;
