import { defineStore } from 'pinia';
import { IVerifiedWallet } from './types';
import { IAddress } from '@Shared/types/address';
import { ADDRESS_TYPE } from '@Shared/enums/addressType';
import { ACCOUNT_TYPE } from '@Shared/enums/accountType';
import { ACCOUNT_STATUS } from '@Shared/enums/accountStatus';
import { KycStatusOptions, KycStepInfo, KycStep } from '@Modules/Kyc';

export interface OperatorState {
  accountId?: string;
  accountStatus?: ACCOUNT_STATUS;
  accountType: ACCOUNT_TYPE;
  birthday?: string;
  email: string;
  emailVerified?: boolean;
  name?: string;
  firstName?: string;
  lastName?: string;
  middleName?: string;
  phone?: string;
  operatorId?: string;
  twofaEnabled?: boolean;
  kycState: {
    status: KycStatusOptions;
    steps?: KycStepInfo[];
  };
  ddPendingQuestion?: {
    questionId: string;
  };
  address: IAddress[];
  wallets: IVerifiedWallet[];
  pickedAddress?: string | null;
}

export const useOperatorStore = defineStore('operator', {
  state: (): OperatorState => ({
    accountId: '',
    accountStatus: undefined,
    accountType: ACCOUNT_TYPE.INDIVIDUAL,
    address: [],
    birthday: '',
    email: '',
    emailVerified: undefined,
    name: '',
    firstName: '',
    lastName: '',
    middleName: '',
    phone: '',
    operatorId: '',
    twofaEnabled: undefined,
    kycState: {
      status: KycStatusOptions.pending,
      steps: [],
    },
    ddPendingQuestion: undefined,
    wallets: [],
    pickedAddress: undefined,
  }),

  getters: {
    isTwofaEnabled(state) {
      return state.twofaEnabled || false;
    },

    isOperatorIndividual(state): boolean {
      return state.accountType === ACCOUNT_TYPE.INDIVIDUAL;
    },

    hasPendingPii({ kycState }) {
      const pendingPiiStatus = [
        KycStatusOptions.requestPoa,
        KycStatusOptions.requestPersonalId,
        KycStatusOptions.personalIdReviewPending,
        KycStatusOptions.poaReviewPending,
      ];
      const hasPendingPiiStatus = pendingPiiStatus.includes(kycState.status);

      if (hasPendingPiiStatus) return true;

      let pendingPii = false;

      if (kycState?.steps?.length) {
        kycState.steps.forEach(({ status, step }) => {
          const notDDStep = step !== KycStep.ddQuestions;

          if (notDDStep) {
            const notAccept = status !== KycStatusOptions.accept;
            const notRequest = !pendingPiiStatus.includes(status);

            if (notAccept && notRequest) {
              pendingPii = true;
            }
          }
        });
      }

      return pendingPii;
    },

    hasPendingDd({ ddPendingQuestion }) {
      return !!ddPendingQuestion?.questionId;
    },

    isOperatorFrozen(state): boolean {
      return state.accountStatus === ACCOUNT_STATUS.FROZEN;
    },
    getPrincipalAddress(state): IAddress | undefined {
      return state.address.find((address) => address.type === ADDRESS_TYPE.PRINCIPAL);
    },
    getIncorporatedAddress(state): IAddress | undefined {
      return state.address.find((address) => address.type === ADDRESS_TYPE.INCORPORATED);
    },
    getPickedAddressId(state): string | null {
      return state.address.find((address) => address.type === state.pickedAddress)?.id || null;
    },
  },
});
