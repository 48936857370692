import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppText = _resolveComponent("AppText")!
  const _component_AppButton = _resolveComponent("AppButton")!

  return (_openBlock(), _createElementBlock("section", {
    class: _normalizeClass({ 'text-text-inactive': _ctx.isStatusCompleted })
  }, [
    _createVNode(_component_AppText, {
      tag: "h3",
      loading: {
        active: _ctx.loading,
        width: '150px',
      },
      size: _ctx.$appTextProps.TextSize['text-4-bold']
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.title), 1)
      ]),
      _: 1
    }, 8, ["loading", "size"]),
    _createVNode(_component_AppText, {
      class: "mt-12",
      loading: {
        active: _ctx.loading,
        width: '300px',
      }
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.description), 1)
      ]),
      _: 1
    }, 8, ["loading"]),
    (_ctx.date)
      ? (_openBlock(), _createBlock(_component_AppText, {
          key: 0,
          class: "mt-20",
          loading: {
        active: _ctx.loading,
        width: '200px',
      }
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.date) + " " + _toDisplayString(_ctx.$t('crypto.redemption.redeem_status.local_time')), 1)
          ]),
          _: 1
        }, 8, ["loading"]))
      : _createCommentVNode("", true),
    (_ctx.buttonText && !_ctx.isStatusCompleted && _ctx.shouldShowActionButton)
      ? (_openBlock(), _createBlock(_component_AppButton, {
          key: 1,
          class: "mt-20",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('onClickButton'))),
          testId: "nft-redemption-action",
          label: _ctx.buttonText,
          size: _ctx.$appButtonProps.Size.small
        }, null, 8, ["label", "size"]))
      : _createCommentVNode("", true)
  ], 2))
}