enum Variations {
  SUCCESS = 'success',
  ERROR = 'error',
}

interface IToastProps {
  dismissable?: boolean;
  expiresAfter?: number;
  message?: string;
  open?: boolean;
  testId?: string;
  type?: Variations;
  isSticky?: boolean;
}

const appToastProps = {
  Variations,
};

export { appToastProps, Variations, IToastProps };
