enum Version {
  v1 = 'v1',
  v2 = 'v2',
  v3 = 'v3',
  v4 = 'v4',
}

const loadingProps = {
  Version,
};

export { loadingProps, Version };
