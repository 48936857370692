import { useIsOperatorMissing, useRefreshOperator } from '@Modules/Operator';
import { useRouteGuardStore } from '../store';
import { RouterGuardExceptionTypes } from '../types';

export default function () {
  const { refreshOperator } = useRefreshOperator();
  const { isOperatorMissing } = useIsOperatorMissing();
  const routeGuardStore = useRouteGuardStore();

  async function applyAuthValidations(token: string) {
    if (!token) {
      routeGuardStore.$patch({
        validationError: {
          type: RouterGuardExceptionTypes.LOGOUT,
        },
      });

      return false;
    }

    if (isOperatorMissing) {
      return await refreshOperator(token).catch(() => {
        routeGuardStore.$patch({
          validationError: {
            type: RouterGuardExceptionTypes.LOGOUT,
          },
        });

        return false;
      });
    }

    return true;
  }

  return {
    applyAuthValidations,
  };
}
