
import { defineComponent } from 'vue';
import { BaseModal, BaseInput, inputProps } from '@BaseUi';
import { BaseCaptcha } from '@Ui/BaseCaptcha';
import { IResetPwdFormPayload } from '../../types';
import { isEmailValid } from '@/utils';

export default defineComponent({
  name: 'PwdRecoveryModal',

  components: { BaseCaptcha, BaseInput, BaseModal },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },

    requiresCaptcha: {
      type: Boolean,
      default: false,
    },

    testId: {
      type: String,
      default: 'pwd-recovery',
    },
  },

  emits: {
    onPwdRecoveryRequest: (payload: IResetPwdFormPayload) => payload,
  },

  setup() {
    return {
      inputProps,
      isEmailValid,
    };
  },

  data() {
    return {
      email: '',
      captcha: '',
    };
  },

  computed: {
    isFormReady() {
      return isEmailValid(this.email) && !!this.email && !this.loading && this.canSubmit;
    },

    canSubmit(): boolean {
      return this.requiresCaptcha ? !!this.captcha : true;
    },
  },

  updated() {
    this.clearState();
  },

  methods: {
    setCaptcha(captcha: string) {
      this.captcha = captcha;
    },

    clearState() {
      this.email = '';
      this.captcha = '';
    },

    onRequestPwd() {
      this.$emit('onPwdRecoveryRequest', {
        email: this.email,
        captcha: this.captcha,
      });
    },
  },
});
