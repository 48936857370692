import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputPassword = _resolveComponent("InputPassword")!
  const _component_PasswordValidationMessage = _resolveComponent("PasswordValidationMessage")!
  const _component_AppButton = _resolveComponent("AppButton")!

  return (_openBlock(), _createElementBlock("form", {
    onSubmit: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_ctx.updatePassword()), ["prevent"]))
  }, [
    _createVNode(_component_InputPassword, {
      label: _ctx.$t('auth.changePassword.form.current_password'),
      input: _ctx.form.currentPassword,
      onUpdate: _cache[0] || (_cache[0] = (newValue) => (_ctx.form.currentPassword = newValue))
    }, null, 8, ["label", "input"]),
    _createVNode(_component_InputPassword, {
      label: _ctx.$t('auth.changePassword.form.new_password'),
      input: _ctx.form.newPassword,
      onUpdate: _cache[1] || (_cache[1] = (newValue) => (_ctx.form.newPassword = newValue, _ctx.validatePassword(newValue)))
    }, null, 8, ["label", "input"]),
    _createVNode(_component_InputPassword, {
      label: _ctx.$t('auth.changePassword.form.confirm_password'),
      input: _ctx.form.confirmPassword,
      onUpdate: _cache[2] || (_cache[2] = (newValue) => (_ctx.form.confirmPassword = newValue))
    }, null, 8, ["label", "input"]),
    _createVNode(_component_PasswordValidationMessage, {
      conditions: _ctx.passwordConditions,
      class: "mb-16"
    }, null, 8, ["conditions"]),
    _createVNode(_component_AppButton, {
      submit: "",
      class: "w-full max-w-[295px]",
      inactive: !_ctx.isValidPassword,
      loading: _ctx.loading,
      size: _ctx.$appButtonProps.Size.medium,
      label: _ctx.$t('auth.changePassword.password.actions.udpate')
    }, null, 8, ["inactive", "loading", "size", "label"])
  ], 32))
}