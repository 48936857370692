
import { defineComponent } from 'vue';
import { storeToRefs } from 'pinia';

import { useGlobalToast } from '@/utils/composables';
import { BaseBackRoute } from '@BaseUi';

import {
  useOperatorStore,
  useFillCurrentOperator,
  fetchCurrentOperatorRequest,
} from '@Modules/Operator';
import {
  KycAdditionalDocs,
  KycDocuments,
  KycIndividualProvideIdentity,
  KycStatus,
} from '@Modules/Kyc/components';
import { IKycInfo, KycAdditionalDocOptions, KycStatusActions } from '@Modules/Kyc/types';
import { useKycStore } from '@Modules/Kyc/store';
import getCurrentOperatorKycInfo from '@Modules/Kyc/providers/getCurrentOperatorKycInfo.request';
import resendEmailConfirmation from '@Modules/Kyc/providers/resendEmailConfirmation.request';

import { useLogout } from '@Modules/Auth/modules/Logout/composables';
import { IAddressForm } from '../components/KycAddressForm';

export default defineComponent({
  name: 'KycIndexIndividual',

  components: {
    BaseBackRoute,
    KycAdditionalDocs,
    KycDocuments,
    KycIndividualProvideIdentity,
    KycStatus,
  },

  setup() {
    const { currentAction } = storeToRefs(useKycStore());
    const { setCurrentOperatorKycInfo, setIndividualAddress, setIndividualNameFields } =
      useKycStore();
    const operatorStore = useOperatorStore();
    const { fillCurrentOperator } = useFillCurrentOperator();
    const { hideToast, showError, showSuccess, toast } = useGlobalToast();
    const { forceLogout } = useLogout();

    return {
      currentAction,
      KycAdditionalDocOptions,
      KycStatusActions,
      operatorStore,
      toast,
      fillCurrentOperator,
      forceLogout,
      hideToast,
      setCurrentOperatorKycInfo,
      setIndividualAddress,
      setIndividualNameFields,
      showError,
      showSuccess,
    };
  },

  data() {
    return {
      isLoading: false,
      isPageLoading: true,
      showStatus: true,
    };
  },

  computed: {
    bulletPoints(): Array<boolean> {
      const { emailVerified, hasPendingPii, hasPendingDd } = this.operatorStore;

      return [!!emailVerified, !hasPendingPii, !hasPendingDd];
    },

    bulletPointsText(): Array<string> {
      return [
        this.$t('kyc.kyc_status.confirm_email'),
        this.$t('kyc.kyc_status.provide_identity'),
        this.$t('kyc.kyc_status.security_questions'),
      ];
    },

    isStatusActionHandled(): boolean {
      return (
        this.showAdditionalDocsPoa ||
        this.showAdditionalDocsPoi ||
        this.showDocuments ||
        this.showProvideIdentity
      );
    },

    showAdditionalDocsPoa(): boolean {
      return this.currentAction === KycStatusActions.provideExtraDocumentsPoa;
    },

    showAdditionalDocsPoi(): boolean {
      return this.currentAction === KycStatusActions.provideExtraDocumentsPoi;
    },

    showDocuments(): boolean {
      return (
        this.currentAction === KycStatusActions.provideDocuments ||
        this.currentAction === KycStatusActions.resubmitDocuments
      );
    },

    showProvideIdentity(): boolean {
      return (
        this.currentAction === KycStatusActions.provideIdentity ||
        this.currentAction === KycStatusActions.resubmitIdentity
      );
    },
  },

  async mounted() {
    await this.getOperatorInfo();
    await this.refreshOperator();
  },

  methods: {
    backToStatus() {
      this.showStatus = true;
    },

    async getOperatorInfo() {
      try {
        this.isPageLoading = true;

        const kycInfo = await getCurrentOperatorKycInfo();
        this.setCurrentOperatorKycInfo(kycInfo);
        this.handleIndividualAddress(kycInfo);
        this.handleIndividualNameFields(kycInfo);
      } finally {
        this.isPageLoading = false;
        this.showStatus = true;
      }
    },

    async afterKycDocs() {
      await this.refreshOperator();
      this.backToStatus();
    },

    async afterKycExtraDocs() {
      await this.getOperatorInfo();
      await this.refreshOperator();
    },

    async refreshOperator() {
      await fetchCurrentOperatorRequest()
        .then((operator) => this.fillCurrentOperator(operator))
        .catch(() => this.forceLogout());
    },

    async handleAction() {
      try {
        this.isLoading = true;

        if (this.isStatusActionHandled) {
          this.showStatus = false;
          return;
        }

        switch (this.currentAction) {
          case KycStatusActions.resendEmail:
            await resendEmailConfirmation();
            return this.showSuccess(this.$t('kyc.kyc_index.success.confirmation_email_sent'), true);
          case KycStatusActions.answerDueDiligence:
            return this.$router.push('/due-diligence');
          case KycStatusActions.resubmitDueDiligence:
            return this.$router.push('/due-diligence');
          case KycStatusActions.suspendedDismiss:
            return this.$router.push('/support');
          case KycStatusActions.contactSupport:
            return this.$router.push('/support');
          case KycStatusActions.underReviewDismiss:
            return this.$router.push('/your-items');
          case KycStatusActions.poaPendingDismiss:
            return this.$router.push('/your-items');
          case KycStatusActions.poiPendingDismiss:
            return this.$router.push('/your-items');
          case KycStatusActions.doneDismiss:
            return this.$router.push('/your-items');
          default:
            return this.$router.push('/your-items');
        }
      } catch (err) {
        this.showError();
      } finally {
        this.isLoading = false;
      }
    },

    handleIndividualAddress(kycInfo: IKycInfo) {
      const address = kycInfo.kyc_pii?.address[0];
      const addressForm: IAddressForm = {
        addressCity: address?.address_city || '',
        addressComplement: address?.address_complement || '',
        addressCountry: address?.address_country || '',
        addressState: address?.address_state || '',
        addressStreet: address?.address_street || '',
        addressZip: address?.address_zip || '',
      };

      this.setIndividualAddress(addressForm);
    },

    handleIndividualNameFields(kycInfo: IKycInfo) {
      const nameFieldsForm = {
        firstName: kycInfo.kyc_pii?.individual.first_name,
        middleName: kycInfo.kyc_pii?.individual.middle_name,
        lastName: kycInfo.kyc_pii?.individual.sur_name,
        birthday: kycInfo.kyc_pii?.individual.birthday
          ? new Date(`${kycInfo.kyc_pii?.individual.birthday}T00:00:00`)
          : undefined,
        phone: kycInfo.kyc_pii?.individual.phone?.replace(/^\+1/, ''),
        ssn: kycInfo.kyc_pii?.individual.ssn,
      };

      this.setIndividualNameFields(nameFieldsForm);
    },
  },
});
