import { IToastProps } from '@/plugins/components/AppToast/types';

export interface IKycEntityProvideIdentityData {
  availableCountries: Array<string>;
  currentAction: KycEntityProvideIdentityActions;
  isLoading: boolean;
  toastMessage: IToastProps;
}

export enum KycEntityProvideIdentityActions {
  selectCountry = 'select_country',
  nameFields = 'name_fields',
  provideIncorporatedAddress = 'provide_incorporated_address',
  providePrincipalAddress = 'provide_principal_address',
  informationSubmitted = 'information_submitted',
}
