import { apollo, DibbsApiException, DibbsErrorHelper, errorHandler } from '@/utils/helpers/api';
import { ILoginResult } from '../types';
import MUTATION from '../services/mutations/Login.mutation.gql';

interface Params {
  email: string;
  password: string;
  keep_me?: boolean;
  captcha?: string;
  twofa?: string;
}

interface Result {
  login: ILoginResult;
}

export default async function (variables: Params): Promise<Result['login']> {
  return apollo
    .mutate<Result>({
      mutation: MUTATION,
      variables: { ...variables },
    })
    .then(({ data }) => DibbsErrorHelper(data?.login))
    .then((data) => data)
    .catch((error) => {
      throw new DibbsApiException(errorHandler(error));
    });
}
