import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseRadio = _resolveComponent("BaseRadio")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "radio-item",
        key: index
      }, [
        _createVNode(_component_BaseRadio, {
          modelValue: _ctx.model,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model) = $event)),
          isSelected: _ctx.isSelected(item),
          label: item.label,
          radioInputId: _ctx.getRadioInputId(index),
          value: item.value
        }, null, 8, ["modelValue", "isSelected", "label", "radioInputId", "value"])
      ]))
    }), 128))
  ]))
}