enum Filter {
  money = 'money',
  percent = 'percent',
  double = 'double',
  letter = 'letter',
}

enum InputMode {
  none = 'none',
  text = 'text',
  tel = 'tel',
  url = 'url',
  email = 'email',
  numeric = 'numeric',
  decimal = 'decimal',
  search = 'search',
}

enum Type {
  datepicker = 'datepicker',
  email = 'email',
  number = 'number',
  password = 'password',
  tel = 'tel',
  text = 'text',
}

const inputProps = {
  Filter,
  InputMode,
  Type,
};

export { inputProps, Filter, InputMode, Type };
