
import { defineComponent } from 'vue';

import IconAvatarBlack from '@Assets/icons/avatar_black.svg';

export default defineComponent({
  name: 'BaseAvatar',

  components: {
    IconAvatarBlack,
  },

  props: {
    large: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    containerClasses() {
      return {
        ['avatar-container']: true,
        ['large-container']: this.large,
      };
    },

    iconClasses() {
      return {
        ['avatar-image']: true,
        ['large-image']: this.large,
      };
    },
  },
});
