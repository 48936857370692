
import { defineComponent } from 'vue';

import getDdPendingQuestion from '@Modules/Kyc/providers/getDdPendingQuestion.request';

import { useKycStore } from '@Modules/Kyc/store';

import { IKycDueDiligenceData } from './types';

export default defineComponent({
  name: 'KycDueDiligenceIndex',

  setup() {
    const { setDueDiligenceQuestion } = useKycStore();

    return {
      setDueDiligenceQuestion,
    };
  },

  data(): IKycDueDiligenceData {
    return {
      currentQuestion: null,
      isLoading: false,
      toastMessage: {
        open: false,
        message: '',
      },
    };
  },

  async mounted() {
    await this.getQuestion();
  },

  methods: {
    async getQuestion() {
      try {
        this.isLoading = true;

        this.currentQuestion = await getDdPendingQuestion(true);
        this.setDueDiligenceQuestion(this.currentQuestion);
      } catch (err) {
        this.toastMessage = {
          open: true,
          message: this.$t('errors.generic'),
        };
      } finally {
        this.isLoading = false;
      }
    },

    onDismiss() {
      this.toastMessage.open = false;
    },

    showQuestions() {
      this.$router.push(`/due-diligence/${this.currentQuestion?.question_id}`);
    },
  },
});
