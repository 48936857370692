
import { defineComponent } from 'vue';
import { AVAILABLE_ICONS } from '@Libraries/font-awesome';

export default defineComponent({
  name: 'BaseModal',

  inheritAttrs: false,

  setup() {
    return {
      AVAILABLE_ICONS,
    };
  },

  props: {
    clickToClose: {
      type: Boolean,
      default: true,
    },
  },
});
