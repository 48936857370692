import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { storeToRefs } from 'pinia';
import { AVAILABLE_ICONS } from '@Libraries/font-awesome';
import { INavbarItem, IMenuItem } from '../types';
import { useOperatorStore } from '../../../modules/Operator/store';

export const useMenu = () => {
  const router = useRouter();
  const { t } = useI18n();
  const { isOperatorIndividual, isOperatorFrozen } = storeToRefs(useOperatorStore());

  const navbarList = computed<INavbarItem[]>(() => {
    return [
      {
        visibility: {
          desktop: true,
          mobile: true,
        },
        key: 'home',
        icon: AVAILABLE_ICONS['house-fas'],
        isExternal: true,
        label: t('navigation.home'),
        linkTo: 'https://dibbs.io/',
        name: 'home',
      },
      {
        visibility: {
          desktop: true,
          mobile: true,
        },
        key: 'registry',
        icon: AVAILABLE_ICONS['comments-fas'],
        isExternal: false,
        label: t('navigation.registry'),
        linkTo: '/registry',
        name: 'registry',
      },
      {
        visibility: {
          desktop: true,
          mobile: true,
        },
        key: 'your-items',
        icon: AVAILABLE_ICONS['comments-fas'],
        isExternal: false,
        label: t('navigation.your_items'),
        linkTo: '/your-items',
        name: 'your-items',
      },
    ];
  });

  const menuItems = computed<IMenuItem[]>(() => {
    return [
      {
        id: 'profile',
        visibility: {
          desktop: true,
          mobile: true,
        },
        name: 'profile',
        label: t('navigation.profile'),
        icon: AVAILABLE_ICONS['user-fas'],
        linkTo: '/profile',
        action: 'redirect',
        enabled: isOperatorIndividual.value,
      },
      {
        id: 'security',
        visibility: {
          desktop: true,
          mobile: true,
        },
        name: 'security',
        label: t('navigation.security'),
        icon: AVAILABLE_ICONS['shield-halved-fas'],
        linkTo: '/security',
        action: 'redirect',
        enabled: !isOperatorFrozen.value,
      },
      {
        id: 'business',
        visibility: {
          desktop: true,
          mobile: true,
        },
        name: 'business',
        label: t('navigation.business'),
        icon: AVAILABLE_ICONS['building-columns-fas'],
        linkTo: '/business',
        action: 'redirect',
        enabled: false,
      },
      {
        id: 'support',
        visibility: {
          desktop: true,
          mobile: true,
        },
        name: 'support',
        label: t('navigation.support'),
        icon: AVAILABLE_ICONS['comments-fas'],
        linkTo: '/support',
        action: 'redirect',
        enabled: true,
      },
      {
        id: 'feedback',
        visibility: {
          desktop: true,
          mobile: true,
        },
        name: 'feedback',
        label: t('navigation.feedback'),
        icon: AVAILABLE_ICONS['message-fas'],
        action: 'feedback',
        enabled: true,
      },
      {
        id: 'logout',
        visibility: {
          desktop: true,
          mobile: true,
        },
        name: 'logout',
        label: t('navigation.logout'),
        icon: AVAILABLE_ICONS['right-from-bracket-fas'],
        action: 'logout',
        enabled: true,
      },
    ];
  });

  const enabledItems = computed(() => menuItems.value.filter((item: IMenuItem) => item.enabled));
  const desktopItems = computed(() => enabledItems.value.filter((item) => item.visibility.desktop));
  const mobileItems = computed(() => enabledItems.value.filter((item) => item.visibility.mobile));
  const desktopNavbar = computed(() => navbarList.value.filter((item) => item.visibility.desktop));
  const mobileNavbar = computed(() => navbarList.value.filter((item) => item.visibility.mobile));

  function goToPage(page: string) {
    router.push(page);

    // TODO: update this when we have theme store
    // if (this.isMediumMobileDevice) {
    //   this.hideModal();
    // }
  }

  return {
    goToPage,
    desktopItems,
    mobileItems,
    desktopNavbar,
    mobileNavbar,
  };
};
