
import { defineComponent } from 'vue';
import { storeToRefs } from 'pinia';
import { BaseInput, inputProps } from '@BaseUi';
import { verify2FADisableCode } from '@Modules/Auth/modules/TwoFactorAuth';
import { useOperatorStore } from '@Modules/Operator';

export default defineComponent({
  name: 'DisableTwofaCode',

  components: { BaseInput },

  emits: ['error', 'success'],

  setup() {
    const { isTwofaEnabled, twofaEnabled } = storeToRefs(useOperatorStore());

    return {
      inputProps,
      isTwofaEnabled,
      twofaEnabled,
    };
  },

  data() {
    return {
      twofa: '',
      isLoading: false,
    };
  },

  methods: {
    submit() {
      if (this.isTwofaEnabled) {
        return this.continueDeactivating();
      }
    },

    async continueDeactivating() {
      this.isLoading = true;

      try {
        await verify2FADisableCode(this.twofa);
        this.$emit('success');
      } catch (err) {
        this.$emit('error', {
          message: this.$t('auth.twofa.invalid_2fa_code'),
        });
      } finally {
        this.isLoading = false;
      }
    },
  },

  computed: {
    shoudBeInactive() {
      return this.isLoading || !this.twofa || this.twofa.length < 6;
    },
  },
});
