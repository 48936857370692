
import { defineComponent, PropType } from 'vue';
import { IAddress } from '@Shared/types/address';
import { BaseRadio } from '@Ui/BaseRadio';
import { OperatorAddress } from '../OperatorAddress';

export default defineComponent({
  name: 'AddressSelector',

  components: {
    BaseRadio,
    OperatorAddress,
  },

  props: {
    addresses: {
      type: Array as PropType<Array<IAddress>>,
      required: true,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    modelValue: {
      type: [String, Number],
    },
  },

  emits: {
    'update:modelValue': (value: string | number) => value,
  },

  computed: {
    model: {
      get() {
        return this.modelValue;
      },

      set(value: string) {
        this.$emit('update:modelValue', value);
      },
    },
  },

  methods: {
    getAddressKey(address: IAddress, index: number): string {
      return `${address.type}_${index}`;
    },

    getAddressHeader(address: IAddress): string {
      return this.$t(`operator.address.${address.type}`);
    },

    getSelectedStatus(address: IAddress): boolean {
      return address.type === this.model;
    },

    getSelectedClass(address: IAddress): string {
      return this.getSelectedStatus(address) ? 'isSelected' : '';
    },
  },
});
