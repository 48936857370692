import { apollo, DibbsApiException, DibbsErrorHelper, errorHandler } from '@/utils/helpers/api';

import DELETE_ACCOUNT_STEP_ONE from '../graphql/mutations/DeleteAccountStepOne.gql';

interface Params {
  twofa: string;
}

interface Result {
  delete_account_step1: {
    result: true;
  };
}

async function sendConfirmationDeleteAccountEmail(
  variables?: Params
): Promise<Result['delete_account_step1']> {
  return apollo
    .mutate<Result>({
      mutation: DELETE_ACCOUNT_STEP_ONE,
      variables: { ...variables },
    })
    .then(({ data }) => {
      return DibbsErrorHelper(data?.delete_account_step1);
    })
    .then((data) => {
      return data;
    })
    .catch((error) => {
      throw new DibbsApiException(errorHandler(error));
    });
}

export default sendConfirmationDeleteAccountEmail;
