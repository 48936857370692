import {
  apollo,
  ApolloVariables,
  DibbsApiException,
  DibbsErrorHelper,
  errorHandler,
} from '@/utils/helpers/api';

import MUTATION from '../services/mutations/Signup.mutation.gql';

export default async function (variables: ApolloVariables) {
  return apollo
    .mutate({
      mutation: MUTATION,
      variables: { ...variables },
    })
    .then(({ data: { signup } }) => DibbsErrorHelper(signup))
    .then((data) => data)
    .catch((error) => {
      throw new DibbsApiException(errorHandler(error));
    });
}
