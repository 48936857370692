
import { defineComponent, PropType, ref, computed, useSlots } from 'vue';
import { Money3Component } from 'v-money3';
import { useDebounceFn } from '@vueuse/core';
import { maska } from 'maska';
import { useI18n } from 'vue-i18n';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

import { Filter, InputMode, Type } from './types';
import { isFilled, capitalizeFirstLetter } from '@/utils';

declare interface MaskaEvent {
  target: {
    dataset: {
      maskRawValue: string;
    };
  };
}

export default defineComponent({
  name: 'BaseInput',

  components: { Datepicker, Money3Component },

  directives: { maska },

  props: {
    autocomplete: {
      type: String,
      default: 'off',
    },

    autofocus: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    externalContainerClasses: {
      type: String,
      default: '',
    },

    filter: {
      type: String as PropType<Filter>,
    },

    inputmode: {
      type: String as PropType<InputMode>,
    },

    lpIgnore: {
      type: Boolean,
      default: true,
    },

    mask: {
      type: String,
    },

    masked: {
      type: Boolean,
      default: false,
    },

    required: {
      type: Boolean,
      default: false,
    },

    maxlength: {
      type: String,
      default: '256',
    },

    modelValue: {
      type: [Date, Number, String],
      default: '',
    },

    name: {
      type: String,
    },

    placeholder: {
      type: String,
    },

    testId: {
      type: String,
      default: 'unamed',
    },

    type: {
      type: String as PropType<Type>,
      default: 'text',
    },

    validate: {
      type: Array as PropType<Array<string | boolean>>,
    },
  },

  setup(props) {
    const { t } = useI18n();
    const slots = useSlots();
    const inputRef = ref<InstanceType<typeof Money3Component>>();

    const error = ref({ status: false, message: '' });

    function clearError() {
      error.value = { status: false, message: '' };
    }

    function setError(message: string) {
      error.value = { status: true, message };
    }

    const shouldBeValidated = computed(() => {
      return props.required || (props.validate && props.validate.length > 0);
    });

    function validateInput(value: string | number | Date) {
      let hasError = false;

      if (props.validate) {
        for (let rule of props.validate) {
          if (typeof rule === 'string') {
            setError(rule);
            hasError = true;
            break;
          }
        }
      }

      if (props.required && !isFilled(value)) {
        const field_name =
          (slots?.default && slots.default()[0] && slots.default()[0].children) ||
          props.name ||
          false;

        const requiredText = field_name
          ? t('forms.errors.required_field', { field_name })
          : t('forms.errors.generic_required_field');

        setError(capitalizeFirstLetter(requiredText));
        hasError = true;
      }

      if (!hasError && error.value.status) {
        clearError();
      }
    }

    const debouncedFn = useDebounceFn((value: string | number | Date) => {
      validateInput(value);
    }, 1000);

    return { inputRef, debouncedFn, error, validateInput, shouldBeValidated };
  },

  data() {
    return {
      oldValue: this.modelValue,
    };
  },

  emits: ['blur', 'maska', 'update:modelValue'],

  computed: {
    model: {
      get() {
        return this.modelValue;
      },

      set(value: string) {
        this.$emit('update:modelValue', value);
      },
    },

    vMoneyModel: {
      get() {
        if (this.modelValue instanceof Date) {
          return '';
        }

        return this.modelValue;
      },

      set(value: string) {
        this.$emit('update:modelValue', value);
      },
    },

    containerClasses() {
      return {
        ...this.sharedClasses,
        [this.borderRadius]: true,
        [this.paddingHorizontal]: true,
        ['border']: true,
        ['flex']: true,
        ['items-center']: true,
        ['justify-between']: true,
      };
    },

    inputClasses() {
      return {
        ...this.sharedClasses,
        [this.placeholderColor]: true,
        [this.textColor]: true,
        [this.textSize]: true,
        ['border-t']: true,
        ['border-b']: true,
        ['no-arrows']: true,
      };
    },

    sharedClasses() {
      return {
        [this.backgroundColor]: true,
        [this.borderColor]: true,
        [this.cursor]: true,
        [this.inputHeight]: true,
        ['w-full']: true,
      };
    },

    backgroundColor() {
      return this.disabled ? 'bg-background-input-disabled' : 'bg-background-primary';
    },

    borderColor() {
      return this.error.status ? 'border-border-negative' : 'border-border-default';
    },

    borderRadius() {
      return 'rounded-10';
    },

    cursor() {
      return this.disabled ? 'cursor-not-allowed' : 'cursor-text';
    },

    errorClasses() {
      return 'text-6-regular text-text-negative text-left h-auto mt-4';
    },

    hasIconSlot() {
      return !!this.$slots.icon;
    },

    hasLabelSlot() {
      return !!this.$slots.default || !!this.$slots['right-label'];
    },

    iconClasses() {
      return 'ml-12 cursor-text fill-current';
    },

    inputHeight() {
      return 'h-48';
    },

    leftLabelClasses() {
      return 'text-5-medium';
    },

    paddingHorizontal() {
      return 'px-16';
    },

    placeholderColor() {
      return 'placeholder-text-inactive';
    },

    rightLabelClasses() {
      return 'text-6-regular';
    },

    textColor() {
      return 'text-text-body';
    },

    textSize() {
      return 'text-5-regular';
    },

    isDatepicker() {
      return this.type === Type.datepicker;
    },

    isDoubleInput() {
      return (
        this.filter === Filter.money ||
        this.filter === Filter.percent ||
        this.filter === Filter.double
      );
    },

    maxDate() {
      return new Date(new Date().setFullYear(new Date().getFullYear() - 18));
    },

    startDate() {
      return new Date('01/01/1990');
    },

    filterType() {
      let defaultFilter = {
        precision: 2,
        masked: this.masked,
        decimal: '.',
        thousands: ',',
      };
      switch (this.filter) {
        case 'money':
          return {
            prefix: '$',
            suffix: '',
            ...defaultFilter,
          };

        case 'percent':
          return {
            prefix: '',
            suffix: '%',
            ...defaultFilter,
          };

        case 'double':
          return {
            prefix: '',
            suffix: '',
            ...defaultFilter,
          };

        default:
          return false;
      }
    },
  },

  mounted() {
    if (this.autofocus) {
      this.handleFocus();
    }
  },

  methods: {
    handleFocus() {
      if (this.inputRef && this.isDoubleInput) {
        this.inputRef.$el.focus();
      }
    },

    emitBlur(event: Event) {
      const value = (event?.target as HTMLInputElement)?.value;

      if (this.shouldBeValidated && value) {
        this.validateInput(value);
      }

      this.$emit('blur', value);
    },

    onMask(event: MaskaEvent) {
      const value = event.target.dataset.maskRawValue;
      this.$emit('maska', value);
    },
  },

  watch: {
    modelValue(value: number | string | Date) {
      if (this.shouldBeValidated) {
        this.debouncedFn(value);
      }
    },
  },
});
