
import { defineComponent } from 'vue';
import { storeToRefs } from 'pinia';

import { useKycStore } from '@Modules/Kyc/store';

import { BaseBackRoute } from '@BaseUi';
import { KycAddress } from '@Modules/Kyc/components/KycAddress';
import { IAddressForm } from '@Modules/Kyc/components/KycAddressForm';
import { KycCountry } from '@Modules/Kyc/components/KycCountry';
import {
  KycIndividualNameFields,
  IIndividualNameFieldsForm,
} from '@Modules/Kyc/components/individual/KycIndividualNameFields';
import { KycSSN } from '@Modules/Kyc/components/individual/KycSSN';
import individualAttemptLevelUp from '@/components/modules/Kyc/providers/individualAttemptLevelUp.request';
import getAvailableCountries from '@/components/modules/Kyc/providers/getAvailableCountries.request';

import { IKycIndividualProvideIdentityData, KycIndividualProvideIdentityActions } from './types';

export default defineComponent({
  name: 'KycIndividualProvideIdentity',

  components: {
    BaseBackRoute,
    KycAddress,
    KycCountry,
    KycIndividualNameFields,
    KycSSN,
  },

  emits: ['done', 'handleBackRoute'],

  setup() {
    const { individualAttemptLevelUpParams, individual } = storeToRefs(useKycStore());
    const { setCurrentOperatorKycState, setIndividualAddress, setIndividualNameFields } =
      useKycStore();

    return {
      individualAttemptLevelUpParams,
      individual,
      KycIndividualProvideIdentityActions,
      setCurrentOperatorKycState,
      setIndividualAddress,
      setIndividualNameFields,
    };
  },

  data(): IKycIndividualProvideIdentityData {
    return {
      availableCountries: [],
      currentAction: KycIndividualProvideIdentityActions.selectCountry,
      isLoading: false,
      toastMessage: {
        open: false,
        message: '',
      },
    };
  },

  async mounted() {
    try {
      this.availableCountries = await getAvailableCountries();
    } catch (err) {
      console.error(err);
    }
  },

  methods: {
    handleBackRoute() {
      switch (this.currentAction) {
        case KycIndividualProvideIdentityActions.selectCountry:
          this.$emit('handleBackRoute');
          break;
        case KycIndividualProvideIdentityActions.nameFields:
          this.currentAction = KycIndividualProvideIdentityActions.selectCountry;
          break;
        case KycIndividualProvideIdentityActions.provideAddress:
          this.currentAction = KycIndividualProvideIdentityActions.nameFields;
          break;
        case KycIndividualProvideIdentityActions.provideSsn:
          this.currentAction = KycIndividualProvideIdentityActions.provideAddress;
          break;
        default:
          this.$emit('handleBackRoute');
      }
    },

    onDismiss() {
      this.toastMessage.open = false;
    },

    onSetAddress(addressForm: IAddressForm) {
      this.setIndividualAddress({
        ...addressForm,
      });

      this.currentAction = KycIndividualProvideIdentityActions.provideSsn;
    },

    onSetCountry(addressCountry: string) {
      this.setIndividualAddress({
        addressCountry,
      });

      this.currentAction = KycIndividualProvideIdentityActions.nameFields;
    },

    onSetNameFields(nameFields: IIndividualNameFieldsForm) {
      this.setIndividualNameFields({
        ...nameFields,
      });

      this.currentAction = KycIndividualProvideIdentityActions.provideAddress;
    },

    async onSetSSN(ssn: string) {
      this.setIndividualNameFields({
        ssn,
      });

      await this.submitUserInfo();
    },

    async submitUserInfo() {
      try {
        this.isLoading = true;

        const kycInfo = await individualAttemptLevelUp(this.individualAttemptLevelUpParams);

        if (!kycInfo) {
          throw new Error('empty object from backend');
        }

        this.setCurrentOperatorKycState(kycInfo);

        this.$emit('done');
      } catch (err) {
        this.toastMessage = {
          open: true,
          message: this.$t('errors.generic'),
        };
      } finally {
        this.isLoading = false;
      }
    },
  },

  computed: {
    showCountry() {
      return this.currentAction === KycIndividualProvideIdentityActions.selectCountry;
    },

    showNameFields() {
      return this.currentAction === KycIndividualProvideIdentityActions.nameFields;
    },

    showAddress() {
      return this.currentAction === KycIndividualProvideIdentityActions.provideAddress;
    },

    showSsn() {
      return this.currentAction === KycIndividualProvideIdentityActions.provideSsn;
    },
  },
});
