import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppText = _resolveComponent("AppText")!
  const _component_BaseInput = _resolveComponent("BaseInput")!

  return (_openBlock(), _createBlock(_component_BaseInput, _mergeProps(_ctx.$attrs, {
    modelValue: _ctx.currentInput,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.currentInput) = $event)),
    class: "mb-16",
    externalContainerClasses: "relative",
    lpIgnore: false,
    type: _ctx.inputType
  }), {
    default: _withCtx(() => [
      _createVNode(_component_AppText, {
        tag: "label",
        class: "mb-8",
        size: _ctx.$appTextProps.TextSize['text-5-medium']
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.label), 1)
        ]),
        _: 1
      }, 8, ["size"])
    ]),
    icon: _withCtx(() => [
      _createElementVNode("div", {
        class: "input-password-toggle",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleInputType && _ctx.toggleInputType(...args)))
      }, _toDisplayString(_ctx.toggleLabel), 1)
    ]),
    _: 1
  }, 16, ["modelValue", "type"]))
}