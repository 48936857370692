import { ApiError } from './interfaces';
import getErrorMessage from '../messages';
import { AppError } from '../customExceptions/AppErrorException';

const getUnknownError = (): AppError => {
  return {
    name: 'Unknown Error',
    description: 'This error is not a DibbsError, networkError or a graphQlError.',
    message: 'Please check the stacktrace.',
    code: '-',
  };
};

const getError = (err: ApiError, moduleName?: string): AppError => {
  const isNetworkError = !!err.networkError;
  const isDibbsError = !!err.dibbsError;
  const isGraphQlError = !!err.graphQLErrors;

  if (isDibbsError) {
    const { name, description, code } = err.dibbsError;

    return {
      name,
      description,
      code: code?.toString() || '-',
      message: getErrorMessage(code?.toString() || '', moduleName),
    };
  }

  if (isNetworkError) {
    const { name, message } = err.networkError;

    return {
      name,
      description: message,
      code: err.networkError.result.errors[0].extensions.code,
      message: getErrorMessage(err.networkError.result.errors[0].extensions.code, moduleName),
    };
  }

  if (isGraphQlError) {
    const { message } = err.graphQLErrors[0];
    const code = err.graphQLErrors[0].extensions?.statusCode ?? '-';
    const description = err.graphQLErrors[0].extensions?.context ?? (message || '-');

    return {
      name: 'GraphQLError',
      description,
      code: code.toString(),
      message: getErrorMessage(code.toString(), moduleName),
    };
  }

  return getUnknownError();
};

const graphqlErrorHandler = (err: ApiError, moduleName?: string) => {
  return getError({ ...err }, moduleName);
};

export default graphqlErrorHandler;
