import { apollo, DibbsApiException, DibbsErrorHelper, errorHandler } from '@/utils/helpers/api';

import REDEEM_ITEM_CONFIRM from '../graphql/mutations/RedeemItemConfirm.gql';

interface Params {
  confirmationCode: string;
  redemptionId: string;
}

interface Result {
  redeem_item_confirm: {
    result: boolean;
  };
}

export async function confirmRedeem(variables: Params): Promise<Result['redeem_item_confirm']> {
  return apollo
    .mutate<Result>({
      mutation: REDEEM_ITEM_CONFIRM,
      variables: { ...variables },
    })
    .then(({ data }) => DibbsErrorHelper(data?.redeem_item_confirm))
    .then((data) => data)
    .catch((error) => {
      throw new DibbsApiException(errorHandler(error));
    });
}

export default confirmRedeem;
