import { hideAllPrompts, showPrompt } from '@/utils';
import { useI18n } from 'vue-i18n';
import { sentEmail2FAStepOne } from '../providers';

export const useTwoFa = () => {
  const { t } = useI18n();
  async function send2FAActivationEmail() {
    await sentEmail2FAStepOne();
    showPrompt('sentEmail2FAStepOne', {
      bind: {
        message: t('auth.twofa.email_sent_modal.description'),
        title: t('auth.twofa.email_sent_modal.title'),
        name: t('emailSentName'),
        isSuccess: true,
      },
      on: {
        confirm: () => {
          hideAllPrompts();
        },
      },
    });
  }

  return {
    send2FAActivationEmail,
  };
};
