import { useWalletStore } from './../../../../Crypto/store';
import { useRouter } from 'vue-router';
import { appToastProps, persistToast } from '@/plugins/components/AppToast';
import { LogoutRequest } from '../providers';
import { useResetStore } from '@/utils/composables';
import { useI18n } from 'vue-i18n';

export const useLogout = () => {
  const router = useRouter();
  const resetStore = useResetStore();
  const { disconnectWallet } = useWalletStore();
  const { t } = useI18n();

  async function logout() {
    await LogoutRequest()
      .then(() => logoutCleanUp())
      .catch(() => logoutCleanUp());
  }

  async function forceLogout() {
    await logout();

    persistToast({
      open: true,
      dismissable: false,
      message: t('errors.protected_route'),
      type: appToastProps.Variations.SUCCESS,
      expiresAfter: 3000,
    });

    return router.push('/login');
  }

  async function logoutCleanUp() {
    localStorage.clear();
    resetStore.all();
    disconnectWallet();
  }

  return { forceLogout, logout, logoutCleanUp };
};
