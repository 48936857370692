import { apollo, DibbsApiException, DibbsErrorHelper, errorHandler } from '@/utils/helpers/api';
import { IDueDiligenceQuestion } from '@Modules/Kyc/components/individual/KycDueDiligenceQuestion/types';

import DD_GET_ANSWERED_QUESTION from '../graphql/queries/DdGetAnsweredQuestion.gql';

interface Result {
  current_operator: {
    dd_get_answered_question: IDueDiligenceQuestion;
  };
}

export default async (
  questionId: string
): Promise<Result['current_operator']['dd_get_answered_question']> => {
  return apollo
    .query<Result>({
      query: DD_GET_ANSWERED_QUESTION,
      fetchPolicy: 'no-cache',
      variables: {
        question_id: questionId,
      },
    })
    .then(({ data }) => DibbsErrorHelper(data.current_operator.dd_get_answered_question))
    .catch((error) => {
      throw new DibbsApiException(errorHandler(error));
    });
};
