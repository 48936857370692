
import { defineComponent } from 'vue';
import { IResetPasswordPayload } from '../../types';
import { isPasswordValid } from '@Helpers/PasswordValidation/PasswordValidation';
import { InputPassword } from '@Ui/InputPassword';
import PasswordValidationMessage from '@Modules/Auth/components/PasswordValidationMessage/PasswordValidationMessage.vue';
import usePasswordValitation from '@/components/modules/Auth/composable/usePasswordValidation';

export default defineComponent({
  name: 'ResetPasswordForm',

  setup() {
    const { validatePassword, passwordConditions, isAllValid } = usePasswordValitation();
    return {
      isPasswordValid,
      validatePassword,
      passwordConditions,
      isAllValid,
    };
  },

  emits: {
    update: (confirmPassword: string) => confirmPassword,
  },

  components: {
    InputPassword,
    PasswordValidationMessage,
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data(): IResetPasswordPayload {
    return {
      newPassword: '',
      confirmPassword: '',
    };
  },

  computed: {
    isValidPassword() {
      const isFilled = !!this.newPassword && !!this.confirmPassword;
      const isValidPassword =
        isPasswordValid(this.newPassword) && isPasswordValid(this.confirmPassword);
      const newPasswordMatch = this.newPassword === this.confirmPassword;

      return isFilled && isValidPassword && newPasswordMatch;
    },

    hasPasswordError() {
      return !!this.newPassword && !isPasswordValid(this.newPassword) && !this.isAllValid;
    },
  },

  methods: {
    updatePassword() {
      this.$emit('update', this.confirmPassword);
    },
  },
});
