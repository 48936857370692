
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AutocompletionContainer',

  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
});
