import { AVAILABLE_ICONS } from '@Libraries/font-awesome';
import { ACCOUNT_TYPE } from '@Shared/enums/accountType';

export default [
  {
    title: 'kyc.kyc_entity_type.cards.card_1.title',
    description: 'kyc.kyc_entity_type.cards.card_1.description',
    icon: AVAILABLE_ICONS['user-fas'],
    value: ACCOUNT_TYPE.INDIVIDUAL,
  },
  {
    title: 'kyc.kyc_entity_type.cards.card_2.title',
    description: 'kyc.kyc_entity_type.cards.card_2.description',
    icon: AVAILABLE_ICONS['university-fas'],
    value: ACCOUNT_TYPE.ENTITY,
  },
];
