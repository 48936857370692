import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-full max-w-[478px]" }
const _hoisted_2 = { class: "flex justify-between" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppText = _resolveComponent("AppText")!
  const _component_BaseInput = _resolveComponent("BaseInput")!
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_BaseModal = _resolveComponent("BaseModal")!

  return (_openBlock(), _createBlock(_component_BaseModal, _mergeProps(_ctx.$attrs, { clickToClose: false }), {
    title: _withCtx(() => [
      _createVNode(_component_AppText, {
        tag: "h1",
        class: "w-full max-w-[478px]",
        size: _ctx.$appTextProps.TextSize['text-3-bold']
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('auth.twofa.reset_modal.title')), 1)
        ]),
        _: 1
      }, 8, ["size"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_AppText, {
          tag: "p",
          class: "w-full max-w-[468px] mb-24",
          size: _ctx.$appTextProps.TextSize['text-5-regular']
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('auth.twofa.reset_modal.description_1')), 1)
          ]),
          _: 1
        }, 8, ["size"]),
        _createVNode(_component_AppText, {
          tag: "p",
          class: "w-full max-w-[468px] mb-24",
          size: _ctx.$appTextProps.TextSize['text-5-regular']
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('auth.twofa.reset_modal.description_2')), 1)
          ]),
          _: 1
        }, 8, ["size"]),
        _createElementVNode("form", {
          class: "flex-col mb-24",
          onSubmit: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onSubmit && _ctx.onSubmit(...args)), ["prevent"]))
        }, [
          _createVNode(_component_BaseInput, {
            modelValue: _ctx.code,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.code) = $event)),
            autofocus: "",
            class: "mb-[28px]",
            externalContainerClasses: "relative",
            testId: `${_ctx.testId}_code`,
            lpIgnore: false,
            type: _ctx.inputProps.Type.text,
            placeholder: _ctx.$t('auth.twofa.reset_modal.enter_backup_code')
          }, null, 8, ["modelValue", "testId", "type", "placeholder"]),
          _createElementVNode("footer", _hoisted_2, [
            _createVNode(_component_AppButton, {
              wide: "",
              class: "mr-8",
              testId: `${_ctx.testId}_back`,
              loading: _ctx.loading,
              size: _ctx.$appButtonProps.Size.medium,
              version: _ctx.$appButtonProps.Version.secondary,
              label: _ctx.$t('navigation.back'),
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('onTwoFactorResetClose')))
            }, null, 8, ["testId", "loading", "size", "version", "label"]),
            _createVNode(_component_AppButton, {
              submit: "",
              wide: "",
              testId: `${_ctx.testId}_submit`,
              inactive: !_ctx.isFormReady,
              loading: _ctx.loading,
              size: _ctx.$appButtonProps.Size.medium,
              label: _ctx.$t('navigation.submit')
            }, null, 8, ["testId", "inactive", "loading", "size", "label"])
          ])
        ], 32)
      ])
    ]),
    _: 1
  }, 16))
}