
import { defineComponent, PropType } from 'vue';
import { BaseCard } from '@BaseUi';
import { ICardSelectorItem } from './types';

export default defineComponent({
  name: 'CardSelector',

  components: {
    BaseCard,
  },

  props: {
    cards: {
      type: Array as PropType<Array<ICardSelectorItem>>,
      default: () => [],
    },

    modelValue: {
      type: [String, Number],
    },
  },

  computed: {
    model: {
      get() {
        return this.modelValue;
      },

      set(value: string | number) {
        this.$emit('update:modelValue', value);
      },
    },
  },

  methods: {
    toggleSelection(card: string | number) {
      if (this.model !== card) {
        this.model = card;
        return;
      }

      this.model = undefined;
    },
  },
});
