
import { defineComponent } from 'vue';
import { useGlobalToast, useResetStore } from '@Utils/composables';
import { getToast, persistToast } from '@/plugins/components/AppToast';
import DesktopVersion from './DesktopVersion/index.vue';
// import MobileVersion from './MobileVersion/index.vue';
import { LogoutConfirmationModal, LogoutRequest } from '@Modules/Auth/modules/Logout';
import { FeedbackModal, submitFeedback } from '@Modules/Feedback';

export default defineComponent({
  name: 'BaseNavbar',

  setup() {
    const resetStore = useResetStore();
    const { showError, showSuccess } = useGlobalToast();

    return {
      getToast,
      persistToast,
      resetStore,
      showError,
      showSuccess,
    };
  },

  components: {
    DesktopVersion,
    FeedbackModal,
    // MobileVersion,
    LogoutConfirmationModal,
  },

  data() {
    return {
      TOAST: {
        open: false,
        dismissable: false,
        message: '',
        type: this.$appToastProps.Variations.ERROR,
        expiresAfter: 3000,
      },

      feedbackModal: {
        open: false,
        loading: false,
      },

      logoutModal: {
        open: false,
        loading: false,
      },
    };
  },

  props: {
    isAuthenticated: {
      type: Boolean,
      default: false,
    },

    isLoading: {
      type: Boolean,
      default: false,
    },
  },

  mounted() {
    this.openPersistedToken();

    this.$router.beforeEach(async (to, from, next) => {
      this.openPersistedToken();
      next();
    });
  },

  methods: {
    logoutCleanUp() {
      localStorage.clear();
      this.resetStore.all();

      this.persistToast({
        open: true,
        dismissable: false,
        message: this.$t('auth.logout.confirmation_modal.feedback'),
        type: this.$appToastProps.Variations.SUCCESS,
        expiresAfter: 3000,
      });

      this.toggleLogoutModal();
      this.$router.push('/login');
    },

    onDismiss() {
      this.TOAST.open = false;
    },

    onOpenFeedbackModal() {
      this.feedbackModal.open = true;
    },

    onOpenLogoutModal() {
      this.logoutModal.open = true;
    },

    onLogout() {
      this.logoutModal.loading = true;

      LogoutRequest()
        .then(() => this.logoutCleanUp())
        .catch(() => this.logoutCleanUp())
        .finally(() => (this.logoutModal.loading = false));
    },

    openPersistedToken() {
      if (this.getToast()) {
        this.TOAST = { ...getToast() };

        this.TOAST.open = true;
      }
    },

    async onSubmitFeedback(feedback: string) {
      try {
        this.feedbackModal.loading = true;
        await submitFeedback(feedback);
        this.toggleFeedbackModal();
        this.showSuccess(this.$t('feedback.success_message'));
      } catch (err) {
        this.showError(err as AppError, true, true);
      } finally {
        this.feedbackModal.loading = false;
      }
    },

    toggleFeedbackModal() {
      this.feedbackModal.open = !this.feedbackModal.open;
    },

    toggleLogoutModal() {
      this.logoutModal.open = !this.logoutModal.open;
    },
  },
});
