
import { PropType, defineComponent, computed } from 'vue';
import { BaseIcon } from '@/components/ui';
import { AVAILABLE_ICONS } from '@Libraries/font-awesome';
import { useI18n } from 'vue-i18n';
import { PasswordValidationConditions } from '../../composable/usePasswordValidation';

export default defineComponent({
  name: 'PasswordValidationMessage',

  setup(props) {
    const { t } = useI18n();
    const icon = AVAILABLE_ICONS['check-fas'];
    const conditionsData = computed(() => [
      {
        text: t('auth.pwd_validation.pwd_rule_lower_case'),
        isValid: props.conditions?.hasLowerCaseLetter,
        class: props.conditions?.hasLowerCaseLetter && 'text-text-positive',
      },
      {
        text: t('auth.pwd_validation.pwd_rule_upper_case'),
        isValid: props.conditions?.hasUpperCaseLetter,
        class: props.conditions?.hasUpperCaseLetter && 'text-text-positive',
      },
      {
        text: t('auth.pwd_validation.pwd_rules_number'),
        isValid: props.conditions?.hasNumber,
        class: props.conditions?.hasNumber && 'text-text-positive',
      },
      {
        text: t('auth.pwd_validation.pwd_rules_symbol'),
        isValid: props.conditions?.hasSpecialCharacter,
        class: props.conditions?.hasSpecialCharacter && 'text-text-positive',
      },
      {
        text: t('auth.pwd_validation.pwd_rules_size'),
        isValid: props.conditions?.hasValidSize,
        class: props.conditions?.hasValidSize && 'text-text-positive',
      },
    ]);

    return {
      AVAILABLE_ICONS,
      conditionsData,
      icon,
    };
  },

  components: {
    BaseIcon,
  },

  props: {
    hasError: Boolean,
    conditions: Object as PropType<PasswordValidationConditions>,
  },

  computed: {
    hasErrorClass() {
      return this.hasError ? 'text-text-negative' : '';
    },
  },
});
