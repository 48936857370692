
import { defineComponent, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { AVAILABLE_ICONS } from '@Libraries/font-awesome';
import { useToast } from '@/utils/composables';
import { DibbsError } from '@/utils/helpers/errors/graphql/interfaces';
import { BaseCard, BasePrompt, BaseInput, inputProps } from '@BaseUi';
import sendConfirmationDeleteAccountEmail from '../../providers/sendConfirmationDeleteAccountEmail.request';

export default defineComponent({
  name: 'DeleteAccountPanel',

  components: {
    BaseCard,
    BasePrompt,
    BaseInput,
  },

  setup() {
    const router = useRouter();
    const { hideToast, showError, toast } = useToast();

    const loading = ref(false);
    const twofa = ref('');

    const twoFaModal = ref(false);

    watch(twoFaModal, (state: boolean) => {
      if (state) {
        twofa.value = '';
        hideToast();
      }
    });

    const openTwoFaModal = () => {
      stepOneModal.value = false;
      twoFaModal.value = true;
    };

    const stepOneModal = ref(false);

    const openStepOneModal = () => {
      stepOneModal.value = true;
    };

    const emailSentModal = ref(false);

    const openEmailSentModal = () => {
      stepOneModal.value = false;
      twoFaModal.value = false;
      emailSentModal.value = true;
    };

    const goToSupportPage = () => {
      stepOneModal.value = false;
      router.push('/support');
    };

    const sentEmailConfirmation = () => {
      loading.value = true;

      sendConfirmationDeleteAccountEmail({ twofa: twofa.value.toString() })
        .then(() => openEmailSentModal())
        .catch((err: DibbsError) => sendConfirmationDeleteAccountEmailError(err))
        .finally(() => (loading.value = false));
    };

    const sendConfirmationDeleteAccountEmailError = (error: DibbsError) => {
      const { code, message } = { ...error };

      switch (code) {
        case 'invalid_2fa_code':
          if (twoFaModal.value) {
            showError(message, false, true);
          }

          openTwoFaModal();
          break;

        default:
          showError();
      }
    };

    return {
      AVAILABLE_ICONS,
      inputProps,

      loading,
      emailSentModal,
      stepOneModal,
      toast,
      twoFaModal,
      twofa,

      goToSupportPage,
      hideToast,
      openStepOneModal,
      sentEmailConfirmation,
      showError,
    };
  },
});
