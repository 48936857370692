
import { defineComponent } from 'vue';
import { storeToRefs } from 'pinia';

import { useKycStore } from '@Modules/Kyc/store';

import { BaseBackRoute } from '@BaseUi';
import { IAddressForm } from '@Modules/Kyc/components/KycAddressForm';
import { KycAddress } from '@Modules/Kyc/components/KycAddress';
import { KycCountry } from '@Modules/Kyc/components/KycCountry';
import {
  KycEntityNameFields,
  IEntityNameFieldsForm,
} from '@Modules/Kyc/components/entity/KycEntityNameFields';
import entityAttemptLevelUp from '@/components/modules/Kyc/providers/entityAttemptLevelUp.request';
import getAvailableCountries from '@/components/modules/Kyc/providers/getAvailableCountries.request';

import { IKycEntityProvideIdentityData, KycEntityProvideIdentityActions } from './types';
import { ADDRESS_TYPE } from '@Shared/enums/addressType';

export default defineComponent({
  name: 'KycEntityProvideIdentity',

  components: {
    BaseBackRoute,
    KycAddress,
    KycCountry,
    KycEntityNameFields,
  },

  emits: ['done', 'handleBackRoute'],

  setup() {
    const { attemptLevelUpParamsEntity, entity } = storeToRefs(useKycStore());
    const {
      setCurrentOperatorKycState,
      setEntityAddresses: setEntityAddress,
      setEntityNameFields,
    } = useKycStore();

    return {
      ADDRESS_TYPE,
      attemptLevelUpParamsEntity,
      entity,
      KycEntityProvideIdentityActions,
      setCurrentOperatorKycState,
      setEntityAddress,
      setEntityNameFields,
    };
  },

  data(): IKycEntityProvideIdentityData {
    return {
      availableCountries: [],
      currentAction: KycEntityProvideIdentityActions.selectCountry,
      isLoading: false,
      toastMessage: {
        open: false,
        message: '',
      },
    };
  },

  computed: {
    showCountry() {
      return this.currentAction === KycEntityProvideIdentityActions.selectCountry;
    },

    showIncorporatedAddress() {
      return this.currentAction === KycEntityProvideIdentityActions.provideIncorporatedAddress;
    },

    showInformationSubmitted() {
      return this.currentAction === KycEntityProvideIdentityActions.informationSubmitted;
    },

    showNameFields() {
      return this.currentAction === KycEntityProvideIdentityActions.nameFields;
    },

    showPrincipalAddress() {
      return this.currentAction === KycEntityProvideIdentityActions.providePrincipalAddress;
    },
  },

  async mounted() {
    try {
      this.availableCountries = await getAvailableCountries();
    } catch (err) {
      console.error(err);
    }
  },

  methods: {
    handleBackRoute() {
      switch (this.currentAction) {
        case KycEntityProvideIdentityActions.selectCountry:
          this.$emit('handleBackRoute');
          break;
        case KycEntityProvideIdentityActions.nameFields:
          this.currentAction = KycEntityProvideIdentityActions.selectCountry;
          break;
        case KycEntityProvideIdentityActions.provideIncorporatedAddress:
          this.currentAction = KycEntityProvideIdentityActions.nameFields;
          break;
        case KycEntityProvideIdentityActions.providePrincipalAddress:
          this.currentAction = KycEntityProvideIdentityActions.provideIncorporatedAddress;
          break;
        default:
          this.$emit('handleBackRoute');
      }
    },

    onDismiss() {
      this.toastMessage.open = false;
    },

    onSetCountry(addressCountry: string) {
      this.setEntityAddress({
        newAddress: {
          addressCountry,
        },
      });

      this.currentAction = KycEntityProvideIdentityActions.nameFields;
    },

    async onSetIncorporatedAddress(addressForm: IAddressForm) {
      this.setEntityAddress({
        newAddress: {
          ...addressForm,
        },
        type: ADDRESS_TYPE.INCORPORATED,
      });

      this.currentAction = KycEntityProvideIdentityActions.providePrincipalAddress;
    },

    onSetNameFields(nameFields: IEntityNameFieldsForm) {
      this.setEntityNameFields({
        ...nameFields,
      });

      this.currentAction = KycEntityProvideIdentityActions.provideIncorporatedAddress;
    },

    async onSetPrincipalAddress(addressForm: IAddressForm) {
      this.setEntityAddress({
        newAddress: {
          ...addressForm,
        },
        type: ADDRESS_TYPE.PRINCIPAL,
      });

      await this.submitUserInfo();
    },

    async submitUserInfo() {
      try {
        this.isLoading = true;

        const kycInfo = await entityAttemptLevelUp(this.attemptLevelUpParamsEntity);

        if (!kycInfo) {
          throw new Error('empty object from backend');
        }

        this.setCurrentOperatorKycState(kycInfo);

        this.currentAction = KycEntityProvideIdentityActions.informationSubmitted;
      } catch (err) {
        this.toastMessage = {
          open: true,
          message: this.$t('errors.generic'),
        };
      } finally {
        this.isLoading = false;
      }
    },
  },
});
