
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'RedeemStatus',
  emits: ['onClickButton'],
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    date: {
      type: String,
      required: false,
    },
    isStatusCompleted: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    buttonText: {
      type: String,
      required: false,
    },
    shouldShowActionButton: {
      type: Boolean,
      default: true,
    },
  },
});
