
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'NFTLineCard',
  emits: ['onClickButton'],

  props: {
    imageUrl: {
      type: String,
      required: false,
    },
    buttonText: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      required: false,
    },
    loading: {
      type: Boolean,
      required: false,
    },
    darkerBackground: {
      type: Boolean,
      required: false,
    },
  },
});
