import { apollo, DibbsApiException, DibbsErrorHelper, errorHandler } from '@/utils/helpers/api';
import { IDueDiligenceQuestion } from '@Modules/Kyc/components/individual/KycDueDiligenceQuestion/types';

import DD_PENDING_QUESTION from '../graphql/queries/DdPendingQuestion.gql';

interface Result {
  current_operator: {
    dd_pending_question: IDueDiligenceQuestion;
  };
}

export default async (
  reset: boolean
): Promise<Result['current_operator']['dd_pending_question']> => {
  return apollo
    .query<Result>({
      query: DD_PENDING_QUESTION,
      variables: {
        reset,
      },
    })
    .then(
      ({
        data: {
          current_operator: { dd_pending_question },
        },
      }) => DibbsErrorHelper(dd_pending_question)
    )
    .catch((error) => {
      throw new DibbsApiException(errorHandler(error));
    });
};
