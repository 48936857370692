
import { defineComponent, PropType } from 'vue';

import { BaseDropdown, ISelectedDropdown } from '@BaseComponents/dropdown/BaseDropdown';
import { DropdownItem, IDropdownItem } from '@BaseComponents/dropdown/DropdownItem';

export default defineComponent({
  name: 'DropdownList',
  props: {
    alignLeft: Boolean,
    customClasses: String,
    dropdownItems: Array as PropType<Array<IDropdownItem>>,
    hideArrow: Boolean,
    iconRight: Boolean,
    selectedDropdown: Object as PropType<ISelectedDropdown>,
  },
  components: {
    BaseDropdown,
    DropdownItem,
  },

  emits: ['dropdownAction'],

  data() {
    return {
      isOpen: false,
    };
  },

  methods: {
    onToggle() {
      this.isOpen = !this.isOpen;
    },

    onDropdownAction(payload: ISelectedDropdown) {
      this.$emit('dropdownAction', payload);
    },

    onCloseDropdown() {
      this.isOpen = false;
    },
  },

  computed: {
    dropdownListClasses() {
      return {
        'open-dropdown': this.isOpen,
        'right-0': !this.alignLeft,
        'left-0': this.alignLeft,
      };
    },
  },
});
