import { apollo, DibbsApiException, DibbsErrorHelper, errorHandler } from '@/utils/helpers/api';
import { ICurrentOperator } from '../types';
import QUERY from '../services/queries/CurrentOperator.query.gql';

interface Result {
  current_operator: ICurrentOperator;
}

export default async function (): Promise<Result['current_operator']> {
  return apollo
    .query<Result>({
      query: QUERY,
      fetchPolicy: 'no-cache',
    })
    .then(({ data }) => DibbsErrorHelper(data?.current_operator))
    .then((data) => data)
    .catch((error) => {
      throw new DibbsApiException(errorHandler(error));
    });
}
