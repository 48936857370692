
import { defineComponent, PropType } from 'vue';
import { ISelectedDropdown } from './types';
import { AVAILABLE_ICONS } from '@Libraries/font-awesome';

export default defineComponent({
  name: 'BaseDropdown',

  props: {
    alignLeft: Boolean,
    hideArrow: Boolean,
    isOpen: Boolean,
    selectedDropdown: {
      type: Object as PropType<ISelectedDropdown>,
      default: () => ({
        label: '',
      }),
    },
  },

  emits: ['toggle'],

  methods: {
    onToggle() {
      this.$emit('toggle');
    },
  },

  computed: {
    dropdownClasses() {
      return {
        'dropdown-container mt-8': true,
        'open-dropdown': this.isOpen,
        'right-0': !this.alignLeft,
        'left-0': this.alignLeft,
      };
    },

    iconProps() {
      return {
        icon: !this.hideArrow ? AVAILABLE_ICONS['caret-down-fas'] : this.selectedDropdown.icon,
        position: this.$appButtonProps.IconPosition.right,
        rotation: !this.hideArrow && this.isOpen ? 'rotate-180' : 'rotate-0',
      };
    },
  },
});
