import { apollo, DibbsApiException, DibbsErrorHelper, errorHandler } from '@/utils/helpers/api';

import query from '../graphql/queries/CurrentOperatorKycInfo.gql';

import { IKycInfo } from '../types';

interface Result {
  current_operator: IKycInfo;
}

async function getCurrentOperatorKycInfo(): Promise<Result['current_operator']> {
  return apollo
    .query<Result>({
      query,
      fetchPolicy: 'no-cache',
    })
    .then(({ data: { current_operator } }) => DibbsErrorHelper(current_operator))
    .catch((error) => {
      throw new DibbsApiException(errorHandler(error));
    });
}

export default getCurrentOperatorKycInfo;
