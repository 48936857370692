import { jsDateToString } from '@/utils';
import { apollo, DibbsApiException, DibbsErrorHelper, errorHandler } from '@/utils/helpers/api';
import ITEM_REGISTRY from '../graphql/queries/ItemRegistry.gql';
import { ItemRegistry } from '../types';

interface QueryResult {
  item_registry: {
    item: {
      name: string;
      video_url?: string;
    };
    item_id: string;
    nft_history?: {
      to: string;
      from: string;
      network: string;
      transaction_hash: string;
      created_at: string;
    }[];
    metadata: {
      metadata: unknown;
    };
  };
}

export async function getItemRegistry(certification_number: string): Promise<ItemRegistry> {
  return apollo
    .query<QueryResult>({
      query: ITEM_REGISTRY,
      variables: { certification_number },
    })
    .then(({ data }) => DibbsErrorHelper(data?.item_registry))
    .then((data: QueryResult['item_registry']): ItemRegistry => {
      return {
        item_id: data.item_id,
        item_name: data.item.name,
        item_video_url: data.item.video_url || null,
        nft_history:
          data.nft_history?.map((history) => ({
            to: formatAddress(history.to),
            from: formatAddress(history.from),
            network: history.network,
            transaction_hash: formatAddress(history.transaction_hash),
            date: formatDate(history.created_at),
          })) ?? [],
        formatted_metadata: formatMetadata(data.metadata.metadata),
      };
    })
    .catch((error) => {
      throw new DibbsApiException(errorHandler(error));
    });
}

const formatMetadata = (metadata?: unknown) => {
  if (!metadata) return [];
  return (
    Object.entries(metadata as Record<string, unknown>).map(([key, value]) => ({
      key: key.charAt(0).toLocaleUpperCase() + key.slice(1),
      value,
    })) || []
  );
};

const formatDate = (date: string) => {
  const d = new Date(date);
  return jsDateToString(d);
};

const formatAddress = (address: string) => {
  return {
    address,
    small_address: `${address.slice(2, 10).toUpperCase()}...`,
  };
};

export default getItemRegistry;
