
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'BaseCard',

  props: {
    selected: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    containerClasses() {
      return {
        'base-card': true,
        'base-card-selected': this.selected,
      };
    },
  },
});
