import { IToastProps } from '@/plugins/components/AppToast/types';

export enum DUE_DILIGENCE_QUESTION_TYPE {
  DROPDOWN = 'dropdown',
  RADIO = 'radio',
}

export interface IDueDiligenceAnswerOption {
  option_id: string;
  option_label: string;
  is_selected: boolean;
  answer_complement: string | null;
}

export interface IDueDiligenceQuestion {
  question_id: string;
  question_title: string;
  question_description: string;
  question_label: string;
  type: string;
  options: Array<IDueDiligenceAnswerOption>;
}

export interface IKycDueDiligenceData {
  currentQuestion: IDueDiligenceQuestion | null;
  currentQuestionSelectedOption: string | undefined;
  currentQuestionSelectedOptionExtraInfo: string;
  isLoading: boolean;
  showingQuestions: boolean;
  toastMessage: IToastProps;
}
