export { default as ImageResize } from './ImageResize.vue';

type Size = {
  width: number;
  height: number;
};

export type ImageSize = {
  dibbs_nft: Size;
  dibbs_nft_mobile: Size;
  asset_detail: Size;
  asset_detail_mobile: Size;
  dibbs_nft_video: Size;
};

export const IMAGE_SIZES: ImageSize = {
  dibbs_nft: { width: 286, height: 300 },
  dibbs_nft_mobile: { width: 150, height: 167.9 },
  asset_detail: { width: 330, height: 558 },
  asset_detail_mobile: { width: 290, height: 485 },
  dibbs_nft_video: { width: 680, height: 480 },
};

export type ImageResizeProps = {
  src?: string;
  alt?: string;
  onImageLoad?: () => void;
  type?: keyof ImageSize;
  width?: number;
  height?: number;
  startHidden?: boolean;
};
