
import { defineComponent, PropType, ref, reactive, watch } from 'vue';

import {
  AutocompleteService,
  getGoogleGeocodeDetails,
  Geocoder,
  IGetPlacePredictionsResponse,
  usStates,
} from '@Libraries/google-maps';

import { AutocompletionContainer, AutocompletionItem } from '@Ui/Autocompletion';
import { BaseSelect } from '@Ui/BaseSelect';

import BaseInput from '@/components/base/BaseInput/BaseInput.vue';

import { IAddressForm, IKycAddressFormData } from './types';
import { ADDRESS_TYPE } from '@Shared/enums/addressType';

export default defineComponent({
  name: 'KycAddressForm',

  components: {
    AutocompletionContainer,
    AutocompletionItem,
    BaseInput,
    BaseSelect,
  },

  props: {
    googleAutocompleteService: {
      type: Object as PropType<AutocompleteService>,
    },

    googleGeocoder: {
      type: Object as PropType<Geocoder>,
    },

    isLoading: {
      type: Boolean,
      default: false,
    },

    modelValue: {
      type: Object as PropType<IAddressForm>,
      required: true,
    },

    type: {
      type: String as PropType<ADDRESS_TYPE>,
      default: ADDRESS_TYPE.PRINCIPAL,
    },

    showOnlyInputs: {
      type: Boolean,
      default: false,
    },

    readonly: {
      type: Boolean,
      default: false,
    },
  },

  emits: {
    'update:modelValue': (addressForm: IAddressForm) => addressForm,
    setAddress: (addressForm: IAddressForm) => addressForm,
  },

  setup(props, { emit }) {
    const stateSelectRef = ref<InstanceType<typeof BaseSelect>>();

    const form = reactive({ ...props.modelValue });

    watch(form, (updatedForm) => emit('update:modelValue', updatedForm));

    return {
      stateSelectRef,
      form,
    };
  },

  data(): IKycAddressFormData {
    return {
      timer: undefined,
      autocomplete: {
        active: false,
        options: [],
      },
      usStates,
    };
  },

  computed: {
    isAutocompletionReady() {
      return [
        this.autocomplete.options.length > 0,
        this.autocomplete.active,
        !!this.form.addressStreet,
      ].every((item) => item === true);
    },

    isFormInvalid() {
      return (
        !this.form.addressStreet ||
        !this.form.addressCity ||
        !this.form.addressState ||
        !this.form.addressZip ||
        this.form.addressZip.length !== 5
      );
    },

    subTitle() {
      return this.$t(`kyc.kyc_address.sub_title_${this.type}`);
    },

    title() {
      return this.$t(`kyc.kyc_address.title_${this.type}`);
    },

    usStatesList() {
      return usStates.map((option) => ({ label: option, value: option }));
    },
  },

  methods: {
    async changeTheAddressTo(obj: IGetPlacePredictionsResponse) {
      const response = await this.requestDetailsFromGeocode(obj.place_id);

      if (response.addressNumber) {
        this.form.addressStreet = `${response.addressNumber} ${response.addressStreet}`;
      } else {
        this.form.addressStreet = response.addressStreet;
      }

      this.form.addressCity = response.addressCity;
      this.form.addressState = response.addressState;
      this.form.addressZip = response.addressZip;

      if (this.stateSelectRef) {
        this.stateSelectRef.itemSelected = true;
      }

      this.clearAutocompletion();
    },

    clearAutocompletion() {
      this.autocomplete.options = [];
      this.autocomplete.active = false;
    },

    debounce(callback: () => void, timeout = 300) {
      return () => {
        window.clearTimeout(this.timer);
        this.timer = window.setTimeout(() => callback(), timeout);
      };
    },

    debounceAddressStreet() {
      this.debounce(() => this.getGoogleAddresses(this.form?.addressStreet || ''), 300)();
    },

    async getGoogleAddresses(input: string) {
      const placePredictions = await new Promise<Array<IGetPlacePredictionsResponse>>((resolve) => {
        this.googleAutocompleteService?.getPlacePredictions(
          {
            input,
            componentRestrictions: {
              country: 'us',
            },
          },
          (response) => {
            if (response) {
              resolve(response);
            }
            resolve([]);
          }
        );
      });
      if (placePredictions.length) {
        this.autocomplete.options = placePredictions;
        this.autocomplete.active = true;
      }
    },

    handleZipChange() {
      const onlyNumbers = this.form.addressZip?.replace(/\D+/g, '').substring(0, 5);
      this.form.addressZip = onlyNumbers;
    },

    onSubmit() {
      this.$emit('setAddress', { ...this.form });
    },

    async requestDetailsFromGeocode(placeId: string) {
      return new Promise<IAddressForm>((resolve) => {
        this.googleGeocoder?.geocode(
          {
            placeId,
          },
          (geocodeResponse) => {
            if (Array.isArray(geocodeResponse)) {
              const adaptedResponse = getGoogleGeocodeDetails(
                geocodeResponse[0].address_components
              );
              resolve(adaptedResponse);
            }
          }
        );
      });
    },
  },
});
