
import { defineComponent, PropType, useAttrs } from 'vue';
import { AVAILABLE_ICONS } from '@Libraries/font-awesome';
import { Variations } from './types';

export default defineComponent({
  name: 'AppToast',

  props: {
    dismissable: {
      type: Boolean,
      default: false,
    },

    isSticky: {
      type: Boolean,
      default: false,
    },

    message: {
      type: String,
      default: '',
    },

    open: {
      type: Boolean,
      default: false,
    },

    type: {
      type: String as PropType<Variations>,
      default: Variations.ERROR,
    },

    testId: {
      type: String,
      default: 'unamed',
    },

    expiresAfter: {
      type: Number,
      default: 3000,
    },
  },

  emits: ['onDismiss'],

  setup() {
    const attrs = useAttrs();

    return {
      AVAILABLE_ICONS,
      attrs,
    };
  },

  data() {
    return {
      isOpen: this.open,
      isHoveering: false,
      initialTime: 0,
      remainingTime: 0,
      expiresAt: 0,
    };
  },

  computed: {
    containerClasses() {
      return {
        [this.type]: true,
        open: this.isOpen,
        dismiss: this.dismissable,
        'toast-container': true,
        'toast-sticky': this.isSticky,
      };
    },

    icon() {
      return this.type === 'success' ? 'check-circle-far' : 'exclamation-triangle-fas';
    },

    canBeClosed() {
      return (
        !this.dismissable && this.open && !this.isHoveering && this.expiresAt >= this.initialTime
      );
    },
  },

  watch: {
    open(newValue, oldValue) {
      if (newValue != oldValue) {
        this.isOpen = newValue;
      }
    },
  },

  mounted() {
    if (this.open) {
      this.initialTime = Date.now();
      this.handleExpiration();
    }
  },

  methods: {
    onClick() {
      if (this.isOpen) {
        this.resetState();

        localStorage.removeItem('APP_TOAST');

        this.$emit('onDismiss');
      }
    },

    onHoverActive() {
      this.isHoveering = true;
      this.remainingTime = this.expiresAfter - (Date.now() - this.initialTime);
      this.handleExpiration();
    },

    onHoverInactive() {
      this.isHoveering = false;
      this.handleExpiration();
    },

    handleExpiration() {
      if (this.dismissable) {
        return;
      }

      this.expiresAt = !this.remainingTime
        ? this.initialTime + this.expiresAfter
        : Date.now() + this.remainingTime;

      const duration = this.expiresAt - Date.now();

      setTimeout(() => {
        if (this.canBeClosed) {
          this.onClick();
        }
      }, duration);
    },

    resetState() {
      this.isOpen = false;
      this.isHoveering = false;
      this.remainingTime = 0;
      this.initialTime = 0;
    },
  },
});
