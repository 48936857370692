import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col w-full max-w-[410px] gap-24" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppText = _resolveComponent("AppText")!
  const _component_CardSelector = _resolveComponent("CardSelector")!
  const _component_AppButton = _resolveComponent("AppButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_AppText, {
      size: _ctx.$appTextProps.TextSize['text-3-bold']
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('kyc.kyc_entity_type.title')), 1)
      ]),
      _: 1
    }, 8, ["size"]),
    _createVNode(_component_CardSelector, {
      modelValue: _ctx.entityType,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.entityType) = $event)),
      cards: _ctx.cards
    }, null, 8, ["modelValue", "cards"]),
    _createVNode(_component_AppButton, {
      wide: "",
      testId: "btn-continue",
      inactive: _ctx.btnDisabled,
      loading: _ctx.loading,
      label: _ctx.btnLabel || _ctx.$t('kyc.kyc_entity_type.actions.next'),
      onClick: _ctx.onClick
    }, null, 8, ["inactive", "loading", "label", "onClick"])
  ]))
}