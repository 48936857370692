import { IToastProps, Variations } from '@/plugins/components/AppToast/types';
import { createSharedComposable } from '@vueuse/core';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { AppError } from '../helpers/errors/customExceptions/AppErrorException';

type IToastPropsComposable = IToastProps & {
  bind?: object;
};

function useToastSingle() {
  const { t } = useI18n();

  const initialState: IToastPropsComposable = {
    open: false,
    dismissable: false,
    message: '',
    type: Variations.ERROR,
    expiresAfter: 3000,
    testId: '',
    bind: {},
  };

  const toast = ref<IToastPropsComposable>({
    ...initialState,
  });

  function showError(message?: string | AppError, dismissable = false, isSticky = false) {
    const customMessage = typeof message === 'string' ? message : message?.message;

    if (customMessage === 'Unknown Error') {
      return;
    }

    showToast({
      isSticky,
      message: customMessage || t('errors.generic'),
      dismissable,
      type: Variations.ERROR,
      expiresAfter: 3000,
      testId: 'global-toast-error',
    });
  }

  function showSuccess(message: string, dismissable = false, isSticky = false) {
    showToast({
      isSticky,
      message,
      dismissable,
      type: Variations.SUCCESS,
      expiresAfter: 3000,
      testId: 'global-toast-success',
    });
  }

  function showToast({
    dismissable,
    message,
    type,
    expiresAfter,
    testId,
    isSticky,
    bind,
  }: IToastPropsComposable) {
    toast.value = {
      open: true,
      dismissable: dismissable || false,
      isSticky: isSticky || false,
      message,
      type: type || Variations.SUCCESS,
      expiresAfter: expiresAfter || 3000,
      testId: testId || 'global-toast',
      ...bind,
    };
  }

  function hideToast() {
    toast.value = { ...initialState };
  }

  return {
    showToast,
    showError,
    showSuccess,
    hideToast,
    toast,
  };
}

export const useToast = useToastSingle;
export const useGlobalToast = createSharedComposable(useToastSingle);
