
import { defineComponent } from 'vue';
import { storeToRefs } from 'pinia';
import { hidePrompt, showPrompt } from '@/utils/helpers/modal';
import { BaseInput, inputProps } from '@BaseUi';
import { verify2FACode } from '@Modules/Auth/modules/TwoFactorAuth';
import { useOperatorStore } from '@Modules/Operator';

export default defineComponent({
  name: 'VerifyAuthentication',

  components: { BaseInput },

  emits: ['error', 'success'],

  setup() {
    const { isTwofaEnabled, twofaEnabled } = storeToRefs(useOperatorStore());

    return {
      inputProps,
      isTwofaEnabled,
      twofaEnabled,
    };
  },

  data() {
    return {
      twofa: '',
      isLoading: false,
    };
  },

  methods: {
    async submit() {
      if (!this.isTwofaEnabled) {
        return await this.continueActivating();
      }
    },

    async continueActivating() {
      this.isLoading = true;
      try {
        await verify2FACode(this.twofa);
        this.showEnableSuccessPrompt();
      } catch (err) {
        this.$emit('error', {
          message: this.$t('auth.twofa.invalid_2fa_code'),
        });
      } finally {
        this.isLoading = false;
      }
    },

    finish2FA(newTwofaStatus: boolean) {
      this.twofaEnabled = newTwofaStatus;
      this.$router.push('/security');
    },

    showEnableSuccessPrompt() {
      let vm = this;
      showPrompt('twofaHasBeenEnabled', {
        bind: {
          title: this.$t('auth.twofa.enabled_modal.title'),
          message: this.$t('auth.twofa.enabled_modal.description'),
          isSuccess: true,
          customConfirm: this.$t('auth.twofa.enabled_modal.close'),
        },
        on: {
          confirm: () => {
            hidePrompt('twofaHasBeenEnabled');
          },
          beforeClose() {
            vm.finish2FA(true);
          },
        },
      });
    },
  },

  computed: {
    shoudBeInactive() {
      return this.isLoading || !this.twofa || this.twofa.length < 6;
    },
  },
});
