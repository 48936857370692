import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TwoFactorInputModal = _resolveComponent("TwoFactorInputModal")!
  const _component_TwoFactorResetModal = _resolveComponent("TwoFactorResetModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_TwoFactorInputModal, _mergeProps(_ctx.$attrs, {
      modelValue: _ctx.$attrs.twoAuthInputModal,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.$attrs.twoAuthInputModal) = $event)),
      onOnTwoFactorInputSubmit: _ctx.onSubmitInput,
      onOnTwoFactorInputClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('onTwoFactorInputClose'))),
      onOnForgotTwofa: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('onForgotTwofa')))
    }), null, 16, ["modelValue", "onOnTwoFactorInputSubmit"]),
    _createVNode(_component_TwoFactorResetModal, _mergeProps(_ctx.$attrs, {
      modelValue: _ctx.$attrs.twoAuthResetModal,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.$attrs.twoAuthResetModal) = $event)),
      onOnTwoFactorResetSubmit: _ctx.onSubmitReset,
      onOnTwoFactorResetClose: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('onTwoFactorResetClose')))
    }), null, 16, ["modelValue", "onOnTwoFactorResetSubmit"])
  ], 64))
}