import { apollo, DibbsApiException, DibbsErrorHelper, errorHandler } from '@/utils/helpers/api';
import MUTATION from '../services/mutations/PasswordRecovery.mutation.gql';

interface Params {
  email: string;
  captcha: string;
}

interface Result {
  password_reset_step1: boolean;
}

export default async function (variables: Params): Promise<Result['password_reset_step1']> {
  return apollo
    .mutate<Result>({
      mutation: MUTATION,
      variables: { ...variables },
    })
    .then(({ data }) => DibbsErrorHelper(data?.password_reset_step1))
    .then((data) => data)
    .catch((error) => {
      throw new DibbsApiException(errorHandler(error));
    });
}
