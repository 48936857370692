
import { defineComponent } from 'vue';
import { storeToRefs } from 'pinia';

import { ISocureConfig, ISocureHandler, ISocureSuccess } from '@Libraries/socure/types';
import { addSDK, removeSDK } from '@/utils/helpers/sdk';

import individualAttemptLevelUp from '@Modules/Kyc/providers/individualAttemptLevelUp.request';
import { useKycStore } from '@Modules/Kyc/store';

import { IKycDocumentsData } from './types';

export default defineComponent({
  name: 'KycDocuments',

  emits: ['done'],

  setup() {
    const { currentOperatorKycInfo } = storeToRefs(useKycStore());
    const { setCurrentOperatorKycState } = useKycStore();

    return {
      currentOperatorKycInfo,
      setCurrentOperatorKycState,
    };
  },

  data(): IKycDocumentsData {
    return {
      processEnum: 1,
      socureHandler: undefined,
      socurePlugin: undefined,
      socureScriptId: 'socure-script-dom',
      toastMessage: {
        open: false,
        message: '',
      },
    };
  },

  computed: {
    isLoading(): boolean {
      return !this.socureHandler;
    },
  },

  mounted() {
    this.mountSocureWidget();
  },

  unmounted() {
    this.unmountSocureWidget();
  },

  methods: {
    async addSocureToLocalHandler() {
      const token = process.env.VUE_APP_SOCURE_KEY || '';

      if (this.socureHandler) {
        await this.socureHandler.unmount();
      }

      this.socureHandler = await this.createSocureWidget(token);

      const socureConfig: ISocureConfig = {
        onError: this.onError,
        onSuccess: this.onSuccess,
        qrCodeNeeded: true,
      };

      this.socureHandler?.init(token, '#socureDiv', socureConfig);

      this.socureHandler?.start(this.processEnum, {
        customerUserId: this.currentOperatorKycInfo?.kyc_state?.id || '',
      });
    },

    async createSocureWidget(token: string): Promise<ISocureHandler | undefined> {
      if (!this.socurePlugin) {
        return;
      }

      return await this.socurePlugin.init(token);
    },

    async mountSocureWidget() {
      try {
        await addSDK(this.socureScriptId, process.env.VUE_APP_SOCURE_SCRIPT || '');
        this.socurePlugin = window.SocureInitializer;
        this.addSocureToLocalHandler();
      } catch (err) {
        this.onError();
      }
    },

    onDismiss() {
      this.toastMessage.open = false;
    },

    onError() {
      this.toastMessage = {
        open: true,
        message: this.$t('errors.generic'),
      };
    },

    async onSuccess(event: ISocureSuccess) {
      if (event.status !== 'DOCUMENTS_UPLOADED') {
        return;
      }

      try {
        const kycInfo = await individualAttemptLevelUp({ docv: event.documentUuid });

        if (kycInfo) {
          this.setCurrentOperatorKycState(kycInfo);

          return this.$emit('done');
        }

        throw new Error('empty object from backend');
      } catch (err) {
        this.onError();
      }
    },

    removeSocureLinks() {
      document.querySelectorAll<HTMLLinkElement>('head > link').forEach((link) => {
        if (link.href.includes('socure')) {
          if (link) {
            link.remove();
          }
        }
      });
    },

    removeSocureScripts() {
      document.querySelectorAll<HTMLScriptElement>('head > script').forEach((script) => {
        if (script.src.includes('socure')) {
          if (script) {
            script.remove();
          }
        }
      });
    },

    unmountSocureWidget() {
      removeSDK(`#${this.socureScriptId}`);
      removeSDK('#embedSocure');
      this.removeSocureLinks();
      this.removeSocureScripts();

      if (window.SocureInitializer) {
        window.SocureInitializer = undefined;
      }
      if (window.Socure) {
        window.Socure = undefined;
      }
    },
  },
});
