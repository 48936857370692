import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4c015886"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "w-full mt-16 p-0" }
const _hoisted_2 = { class: "flex w-full" }
const _hoisted_3 = {
  class: "country-option subheadline-medium",
  "data-testid": "current-country"
}
const _hoisted_4 = { class: "ml-8" }
const _hoisted_5 = {
  class: "flex flex-col overflow-y-scroll h-screen",
  "data-testid": "country-list"
}
const _hoisted_6 = { class: "bg-background-primary py-16 px-20 subheadline-medium" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { class: "ml-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_BaseInput = _resolveComponent("BaseInput")!
  const _component_CountryFlag = _resolveComponent("CountryFlag")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_BaseInput, {
        modelValue: _ctx.countrySearch,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.countrySearch) = $event)),
        autofocus: "",
        class: "w-full",
        testId: "country-search"
      }, {
        icon: _withCtx(() => [
          _createVNode(_component_font_awesome_icon, {
            class: "w-20 h-20 text-text-body fill-current cursor-pointer",
            icon: _ctx.AVAILABLE_ICONS['search-fas']
          }, null, 8, ["icon"])
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    _createElementVNode("h4", _hoisted_3, [
      _createVNode(_component_CountryFlag, {
        rounded: "",
        size: "big",
        country: _ctx.currentCountry
      }, null, 8, ["country"]),
      _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.selectText), 1)
    ]),
    _createElementVNode("div", _hoisted_5, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.countriesFilteredByFirstLetter, (results, letter) => {
        return (_openBlock(), _createElementBlock(_Fragment, { key: letter }, [
          _createElementVNode("p", _hoisted_6, _toDisplayString(letter), 1),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(results, (item) => {
            return (_openBlock(), _createElementBlock("button", {
              key: item.iso2,
              class: "country-option subheadline-medium",
              type: "button",
              onClick: ($event: any) => (_ctx.selectCountry(item.code))
            }, [
              _createVNode(_component_CountryFlag, {
                rounded: "",
                size: "big",
                country: item.code
              }, null, 8, ["country"]),
              _createElementVNode("span", _hoisted_8, _toDisplayString(item.name), 1)
            ], 8, _hoisted_7))
          }), 128))
        ], 64))
      }), 128))
    ])
  ]))
}