
import { defineComponent } from 'vue';
import { useMenu } from '../utils/useMenu';

export default defineComponent({
  name: 'MobileBottomMenu',
  setup() {
    const { mobileNavbar } = useMenu();
    return { mobileNavbar };
  },
});
