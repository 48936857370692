export function inputToPath(input: string) {
  if (input.indexOf('https://') === 0 || input.indexOf('http://') === 0) {
    input = new URL(input).pathname;
  }
  const regexp = new RegExp(/^\/cdn-cgi\/image\/(.*?)\/(.*)/g);
  const match = regexp.exec(input);
  if (match) {
    return match[2];
  } else {
    return input.replace(/^\/|\/$/g, '');
  }
}

export type CDNImage = {
  width?: string | number;
  height?: string | number;
  format?: string | number;
};

export function getCdnImage(file: string, { width, height, format }: CDNImage) {
  if (!file) {
    return;
  }
  const w = width ? `,width=${width}` : '';
  const h = height ? `,height=${height}` : '';
  const f = format ? `,format=${format}` : ',format=webp';
  const n = inputToPath(file);
  return `https://img.dibbscdn.com/cdn-cgi/image/${w}${h}${f}/${n}`;
}
