
import { defineComponent, PropType } from 'vue';
import { IRedemptionItem } from '@Modules/Crypto/';
import NFTLineCard from '@Modules/Crypto/components/NFTLineCard/NFTLineCard.vue';
import WarningBox from '@Modules/Crypto/modules/Redemption/components/WarningBox/WarningBox.vue';

export default defineComponent({
  name: 'BurnToken',

  emits: {
    burnToken: () => true,
    cancelWithdraw: () => true,
  },

  components: {
    NFTLineCard,
    WarningBox,
  },

  props: {
    redemptionItem: {
      type: Object as PropType<IRedemptionItem>,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
});
