import { ApolloClient, NormalizedCacheObject } from '@apollo/client/core';

const tokenName = 'AUTH_TOKEN';

const getHeaders = () => {
  const headers: {
    Authorization?: string;
  } = {};

  const token = localStorage.getItem(tokenName);
  if (token) {
    headers['Authorization'] = `Bearer ${token}`;
  }

  return headers;
};

const resetApolloClientOnLogin = async (
  client: ApolloClient<NormalizedCacheObject>,
  token: string
) => {
  if (typeof localStorage !== 'undefined' && token) {
    localStorage.setItem(tokenName, token);
  }

  try {
    await client.stop();
    await client.resetStore();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    // eslint-disable-next-line no-console
    console.log('%cError on cache reset (login)', 'color: orange;', e.message);
  }
};

export { getHeaders, resetApolloClientOnLogin };
