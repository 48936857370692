import { apollo, DibbsApiException, DibbsErrorHelper, errorHandler } from '@/utils/helpers/api';
import { IDueDiligenceQuestion } from '@Modules/Kyc/components/individual/KycDueDiligenceQuestion/types';

import DD_ANSWER_QUESTION from '../graphql/mutations/DdAnswerQuestion.gql';

interface Params {
  question_id: string;
  answer: Array<{
    option_id: string;
    answer_complement: string;
  }>;
}

interface Result {
  dd_answer_question: IDueDiligenceQuestion | null;
}

export default async ({ question_id, answer }: Params): Promise<Result['dd_answer_question']> => {
  return apollo
    .mutate<Result>({
      mutation: DD_ANSWER_QUESTION,
      variables: {
        question_id,
        answer,
      },
    })
    .then(({ data }) =>
      data && data.dd_answer_question ? DibbsErrorHelper(data.dd_answer_question) : null
    )
    .catch((error) => {
      throw new DibbsApiException(errorHandler(error));
    });
};
