import { vModelRadio as _vModelRadio, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5322c21c"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["data-testid", "id", "name", "value"]
const _hoisted_2 = ["for"]
const _hoisted_3 = { class: "radio-item__icon-wrapper" }
const _hoisted_4 = {
  key: 1,
  class: "w-full h-full border-2 border-text-body rounded-full"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_AppText = _resolveComponent("AppText")!

  return (_openBlock(), _createElementBlock("fieldset", null, [
    _withDirectives(_createElementVNode("input", {
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model) = $event)),
      class: "hidden",
      type: "radio",
      "data-testid": _ctx.radioInputId,
      id: _ctx.radioInputId,
      name: _ctx.radioInputId,
      value: _ctx.value
    }, null, 8, _hoisted_1), [
      [_vModelRadio, _ctx.model]
    ]),
    _createElementVNode("label", {
      for: _ctx.radioInputId,
      class: "flex items-center cursor-pointer"
    }, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.isSelected)
          ? (_openBlock(), _createBlock(_component_BaseIcon, {
              key: 0,
              icon: "check-circle-fas",
              class: "w-full h-full text-text-active-2"
            }))
          : (_openBlock(), _createElementBlock("div", _hoisted_4))
      ]),
      (_ctx.label && !_ctx.$slots.default?.length)
        ? (_openBlock(), _createBlock(_component_AppText, {
            key: 0,
            tag: "legend",
            size: _ctx.$appTextProps.TextSize['text-5-medium']
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.label), 1)
            ]),
            _: 1
          }, 8, ["size"]))
        : _createCommentVNode("", true),
      (_ctx.$slots.default)
        ? _renderSlot(_ctx.$slots, "default", { key: 1 }, undefined, true)
        : _createCommentVNode("", true)
    ], 8, _hoisted_2)
  ]))
}