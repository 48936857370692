import { IToastProps } from './types';

const hasToast = () => {
  return !!localStorage.getItem('APP_TOAST');
};

const getToast = () => {
  if (!hasToast()) {
    return false;
  }

  return JSON.parse(localStorage.APP_TOAST);
};

const persistToast = (props: IToastProps) => {
  localStorage.setItem('APP_TOAST', JSON.stringify({ ...props, open: false }));
};

export { getToast, persistToast };
