import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-119baeb0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex justify-center" }
const _hoisted_2 = { class: "w-full min-w-[335px] lg:max-w-[626px]" }
const _hoisted_3 = { class: "border-t" }
const _hoisted_4 = { class: "flex justify-center mt-48" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppText = _resolveComponent("AppText")!
  const _component_StatusLabel = _resolveComponent("StatusLabel")!
  const _component_AppButton = _resolveComponent("AppButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_AppText, {
        class: "mb-24",
        loading: _ctx.loading,
        size: _ctx.$appTextProps.TextSize['text-3-bold']
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t(`kyc.kyc_status.title_labels.${_ctx.currentAction}`)), 1)
        ]),
        _: 1
      }, 8, ["loading", "size"]),
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.bulletPoints, (bullet, i) => {
          return (_openBlock(), _createBlock(_component_StatusLabel, {
            key: i,
            class: "status-label",
            active: bullet,
            label: _ctx.bulletPointsText[i],
            loading: _ctx.isPageLoading
          }, null, 8, ["active", "label", "loading"]))
        }), 128))
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_AppButton, {
          wide: "",
          "is-skeleton-loading": _ctx.isPageLoading,
          label: _ctx.$t(`kyc.kyc_status.action_button_labels.${_ctx.currentAction}`),
          loading: _ctx.isLoading,
          size: _ctx.$appButtonProps.Size.large,
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('onKycAction')))
        }, null, 8, ["is-skeleton-loading", "label", "loading", "size"])
      ])
    ])
  ]))
}