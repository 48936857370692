import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-50cb5172"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["data-testid"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_AppText = _resolveComponent("AppText")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!_ctx.dropdownItem.action)
      ? (_openBlock(), _createBlock(_component_router_link, {
          key: 0,
          class: _normalizeClass(_ctx.itemClasses),
          "data-testid": `dropdown-route-item-${_ctx.dropdownItem.key}`,
          to: _ctx.dropdownItem.linkTo!
        }, {
          default: _withCtx(() => [
            (_ctx.dropdownItem.icon)
              ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                  key: 0,
                  class: "dropdown-icon",
                  icon: _ctx.dropdownItem.icon,
                  role: "icon"
                }, null, 8, ["icon"]))
              : _createCommentVNode("", true),
            _createVNode(_component_AppText, {
              size: _ctx.$appTextProps.TextSize['text-4-medium']
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.dropdownItem.label), 1)
              ]),
              _: 1
            }, 8, ["size"])
          ]),
          _: 1
        }, 8, ["class", "data-testid", "to"]))
      : (_openBlock(), _createElementBlock("span", {
          key: 1,
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.action && _ctx.action(...args))),
          class: _normalizeClass(_ctx.itemClasses),
          "data-testid": `dropdown-action-item-${_ctx.dropdownItem.key}`
        }, [
          (_ctx.dropdownItem.icon)
            ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                key: 0,
                class: "dropdown-icon",
                icon: _ctx.dropdownItem.icon,
                role: "icon"
              }, null, 8, ["icon"]))
            : _createCommentVNode("", true),
          _createVNode(_component_AppText, {
            size: _ctx.$appTextProps.TextSize['text-4-medium']
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.dropdownItem.label), 1)
            ]),
            _: 1
          }, 8, ["size"])
        ], 10, _hoisted_1))
  ]))
}