export enum CryptoNetwork {
  ethereum = 1,
  goerli = 5,
  mumbai = 80001,
  amoy = 80002,
  polygon = 137,
}

export enum CryptoNetworkScanner {
  ethereum = 'https://etherscan.io',
  goerli = 'https://goerli.etherscan.io',
  mumbai = 'https://mumbai.polygonscan.com',
  amoy = 'https://amoy.polygonscan.com',
  polygon = 'https://polygonscan.com',
}

export enum CryptoNetworkScanName {
  ethereum = 'Etherscan',
  goerli = 'Etherscan',
  mumbai = 'Explorer',
  polygon = 'Explorer',
  amoy = 'Explorer',
}

export enum CryptoNetworkName {
  polygon = 'polygon',
}
