import { resolveComponent as _resolveComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, openBlock as _openBlock, createBlock as _createBlock, mergeProps as _mergeProps, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppSkeletonLoading = _resolveComponent("AppSkeletonLoading")!

  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.tag), {
    class: _normalizeClass(_ctx.componentClasses)
  }, {
    default: _withCtx(() => [
      (_ctx.loadingOptions.active)
        ? (_openBlock(), _createBlock(_component_AppSkeletonLoading, _normalizeProps(_mergeProps({ key: 0 }, _ctx.loadingOptions)), null, 16))
        : _renderSlot(_ctx.$slots, "default", { key: 1 })
    ]),
    _: 3
  }, 8, ["class"]))
}