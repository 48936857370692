import { apollo, DibbsApiException, DibbsErrorHelper, errorHandler } from '@/utils/helpers/api';
import SUBMIT_FEEDBACK from '../services/mutations/SubmitFeedback.gql';

interface Result {
  submit_feedback: string;
}

async function submitFeedback(feedback: string): Promise<Result['submit_feedback']> {
  return apollo
    .mutate<Result>({
      mutation: SUBMIT_FEEDBACK,
      variables: {
        feedback,
      },
    })
    .then(({ data }) => DibbsErrorHelper(data?.submit_feedback))
    .catch((error) => {
      throw new DibbsApiException(errorHandler(error, 'feedback'));
    });
}

export default submitFeedback;
