import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_ctx.isExternal)
    ? (_openBlock(), _createElementBlock("a", {
        key: 0,
        rel: "noopener",
        role: "link",
        target: "_blank",
        class: _normalizeClass(_ctx.classes),
        href: _ctx.linkTo
      }, _toDisplayString(_ctx.label), 11, _hoisted_1))
    : (_openBlock(), _createBlock(_component_router_link, {
        key: 1,
        role: "link",
        class: _normalizeClass(_ctx.classes),
        to: _ctx.linkTo
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.label), 1)
        ]),
        _: 1
      }, 8, ["class", "to"]))
}