import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7b495070"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "navbar-container sticky" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DesktopVersion = _resolveComponent("DesktopVersion")!
  const _component_AppToast = _resolveComponent("AppToast")!
  const _component_FeedbackModal = _resolveComponent("FeedbackModal")!
  const _component_LogoutConfirmationModal = _resolveComponent("LogoutConfirmationModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_DesktopVersion, {
        class: "navbar",
        isAuthenticated: _ctx.isAuthenticated,
        isLoading: _ctx.isLoading,
        onOnOpenLogoutModal: _ctx.onOpenLogoutModal,
        onOnOpenFeedbackModal: _ctx.onOpenFeedbackModal
      }, null, 8, ["isAuthenticated", "isLoading", "onOnOpenLogoutModal", "onOnOpenFeedbackModal"])
    ]),
    (_ctx.TOAST.open)
      ? (_openBlock(), _createBlock(_component_AppToast, {
          key: 0,
          open: _ctx.TOAST.open,
          dismissable: _ctx.TOAST.dismissable,
          message: _ctx.TOAST.message,
          type: _ctx.TOAST.type,
          expiresAfter: _ctx.TOAST.expiresAfter,
          onOnDismiss: _ctx.onDismiss
        }, null, 8, ["open", "dismissable", "message", "type", "expiresAfter", "onOnDismiss"]))
      : _createCommentVNode("", true),
    (_ctx.feedbackModal.open)
      ? (_openBlock(), _createBlock(_component_FeedbackModal, {
          key: 1,
          modelValue: _ctx.feedbackModal.open,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.feedbackModal.open) = $event)),
          loading: _ctx.feedbackModal.loading,
          onCancel: _ctx.toggleFeedbackModal,
          onOnSubmitFeedback: _ctx.onSubmitFeedback
        }, null, 8, ["modelValue", "loading", "onCancel", "onOnSubmitFeedback"]))
      : _createCommentVNode("", true),
    (_ctx.logoutModal.open)
      ? (_openBlock(), _createBlock(_component_LogoutConfirmationModal, {
          key: 2,
          modelValue: _ctx.logoutModal.open,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.logoutModal.open) = $event)),
          loading: _ctx.logoutModal.loading,
          onCancel: _ctx.toggleLogoutModal,
          onOnLogout: _ctx.onLogout
        }, null, 8, ["modelValue", "loading", "onCancel", "onOnLogout"]))
      : _createCommentVNode("", true)
  ], 64))
}