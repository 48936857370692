
import { PropType } from 'vue';

export default {
  name: 'DeleteAccountMessage',
  props: {
    title: {
      type: String,
      required: true,
    },
    message: {
      type: Array as PropType<string[]>,
      required: true,
    },
  },
};
