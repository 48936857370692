import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, mergeProps as _mergeProps, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c267eb96"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "modal__title" }
const _hoisted_2 = { class: "modal__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_vue_final_modal = _resolveComponent("vue-final-modal")!

  return (_openBlock(), _createBlock(_component_vue_final_modal, _mergeProps(_ctx.$attrs, {
    attach: "#modals",
    classes: "modal-container",
    "content-class": "modal-content",
    "click-to-close": _ctx.clickToClose
  }), {
    default: _withCtx(({ close }) => [
      _createElementVNode("span", _hoisted_1, [
        (_ctx.clickToClose)
          ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
              key: 0,
              onClick: close,
              class: "modal__close-btn",
              icon: _ctx.AVAILABLE_ICONS['times-fas']
            }, null, 8, ["onClick", "icon"]))
          : _createCommentVNode("", true),
        (_ctx.$slots.title)
          ? _renderSlot(_ctx.$slots, "title", { key: 1 }, undefined, true)
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_2, [
        (_ctx.$slots.default)
          ? _renderSlot(_ctx.$slots, "default", { key: 0 }, undefined, true)
          : _createCommentVNode("", true)
      ])
    ]),
    _: 3
  }, 16, ["click-to-close"]))
}