import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppText = _resolveComponent("AppText")!
  const _component_BaseInput = _resolveComponent("BaseInput")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_InfoContainer = _resolveComponent("InfoContainer")!
  const _component_AppButton = _resolveComponent("AppButton")!

  return (_openBlock(), _createElementBlock("form", {
    class: "max-w-[410px]",
    onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onSubmit && _ctx.onSubmit(...args)), ["prevent"]))
  }, [
    _createVNode(_component_AppText, {
      size: _ctx.$appTextProps.TextSize['text-3-bold']
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('kyc.kyc_ssn.title')), 1)
      ]),
      _: 1
    }, 8, ["size"]),
    _createVNode(_component_BaseInput, {
      modelValue: _ctx.form.ssn,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.ssn) = $event)),
      autofocus: "",
      class: "mt-24",
      testId: "ssn",
      validate: [_ctx.isValidSSN || _ctx.$t('kyc.kyc_ssn.invalid_ssn')],
      placeholder: _ctx.$t('kyc.kyc_ssn.form.placeholder'),
      onInput: _ctx.handleInputChange
    }, null, 8, ["modelValue", "validate", "placeholder", "onInput"]),
    _createVNode(_component_InfoContainer, { class: "mt-16" }, {
      icon: _withCtx(() => [
        _createVNode(_component_font_awesome_icon, {
          class: "text-text-active-2 w-20 h-20",
          icon: _ctx.AVAILABLE_ICONS['lock-fas']
        }, null, 8, ["icon"])
      ]),
      title: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('kyc.kyc_ssn.info_container.title')), 1)
      ]),
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('kyc.kyc_ssn.info_container.description')), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_AppButton, {
      class: "mt-24",
      submit: "",
      testId: "btn-continue",
      wide: "",
      inactive: !_ctx.isValidSSN,
      label: _ctx.$t('kyc.kyc_ssn.actions.continue'),
      loading: _ctx.isLoading
    }, null, 8, ["inactive", "label", "loading"])
  ], 32))
}