const initChat = (userId: string, email: string) => {
  window.Intercom('boot', {
    user_id: userId,
    email,
  });
};
const anonymousInitChat = () => {
  window.Intercom('boot');
};
const showChat = () => {
  window.Intercom('show');
};
const hideChat = () => {
  window.Intercom('hide');
};
const destroyChat = () => {
  window.Intercom('shutdown');
};

export default {
  initChat,
  showChat,
  hideChat,
  destroyChat,
  anonymousInitChat,
};
