import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "flex flex-col gap-24 mt-24" }
const _hoisted_2 = { class: "relative" }
const _hoisted_3 = { class: "flex gap-24" }
const _hoisted_4 = { class: "flex flex-col gap-8" }
const _hoisted_5 = { class: "flex flex-col w-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppText = _resolveComponent("AppText")!
  const _component_BaseInput = _resolveComponent("BaseInput")!
  const _component_AutocompletionItem = _resolveComponent("AutocompletionItem")!
  const _component_AutocompletionContainer = _resolveComponent("AutocompletionContainer")!
  const _component_BaseSelect = _resolveComponent("BaseSelect")!
  const _component_AppButton = _resolveComponent("AppButton")!

  return (_openBlock(), _createElementBlock("form", {
    class: "max-w-[410px]",
    onSubmit: _cache[5] || (_cache[5] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onSubmit && _ctx.onSubmit(...args)), ["prevent"]))
  }, [
    (!_ctx.showOnlyInputs)
      ? (_openBlock(), _createBlock(_component_AppText, {
          key: 0,
          size: _ctx.$appTextProps.TextSize['text-3-bold']
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.title), 1)
          ]),
          _: 1
        }, 8, ["size"]))
      : _createCommentVNode("", true),
    (!_ctx.showOnlyInputs)
      ? (_openBlock(), _createBlock(_component_AppText, {
          key: 1,
          class: "mt-24"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.subTitle), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_BaseInput, {
          modelValue: _ctx.form.addressStreet,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.addressStreet) = $event)),
          autofocus: "",
          maxlength: "1024",
          testId: "address",
          "lp-ignore": false,
          disabled: _ctx.readonly,
          onInput: _ctx.debounceAddressStreet
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('kyc.kyc_address.form.address')), 1)
          ]),
          _: 1
        }, 8, ["modelValue", "disabled", "onInput"]),
        _createVNode(_component_AutocompletionContainer, {
          "data-testid": `autocomplete-${_ctx.isAutocompletionReady ? 'visible' : 'hidden'}`,
          visible: _ctx.isAutocompletionReady,
          onClose: _ctx.clearAutocompletion
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.autocomplete.options, (item, key) => {
              return (_openBlock(), _createBlock(_component_AutocompletionItem, {
                "data-cy": "autocomplete-option",
                "data-testid": "autocomplete-option",
                tabindex: "0",
                key: key,
                onClick: () => _ctx.changeTheAddressTo(item)
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(item.description), 1)
                ]),
                _: 2
              }, 1032, ["onClick"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["data-testid", "visible", "onClose"])
      ]),
      _createVNode(_component_BaseInput, {
        modelValue: _ctx.form.addressComplement,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.addressComplement) = $event)),
        maxlength: "255",
        testId: "complement",
        disabled: _ctx.readonly,
        "lp-ignore": false
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('kyc.kyc_address.form.apartment')), 1)
        ]),
        _: 1
      }, 8, ["modelValue", "disabled"]),
      _createVNode(_component_BaseInput, {
        modelValue: _ctx.form.addressCity,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.addressCity) = $event)),
        testId: "city",
        disabled: _ctx.readonly,
        "lp-ignore": false
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('kyc.kyc_address.form.city')), 1)
        ]),
        _: 1
      }, 8, ["modelValue", "disabled"]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_AppText, {
            tag: "label",
            size: _ctx.$appTextProps.TextSize['text-5-medium'],
            textContent: _toDisplayString(_ctx.$t('kyc.kyc_address.form.state'))
          }, null, 8, ["size", "textContent"]),
          _createVNode(_component_BaseSelect, {
            modelValue: _ctx.form.addressState,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.form.addressState) = $event)),
            "data-testid": "state-select",
            ref: "stateSelectRef",
            testId: "state-select",
            disabled: _ctx.readonly,
            list: _ctx.usStatesList
          }, null, 8, ["modelValue", "disabled", "list"])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_BaseInput, {
            modelValue: _ctx.form.addressZip,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.form.addressZip) = $event)),
            mask: "#####",
            maxlength: "16",
            testId: "zip",
            "lp-ignore": false,
            disabled: _ctx.readonly,
            onInput: _ctx.handleZipChange
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('kyc.kyc_address.form.zip')), 1)
            ]),
            _: 1
          }, 8, ["modelValue", "disabled", "onInput"])
        ])
      ])
    ]),
    (!_ctx.showOnlyInputs)
      ? (_openBlock(), _createBlock(_component_AppButton, {
          key: 2,
          submit: "",
          wide: "",
          class: "mt-24",
          testId: "button-continue",
          inactive: _ctx.isFormInvalid,
          label: _ctx.$t('kyc.kyc_address.actions.continue'),
          loading: _ctx.isLoading
        }, null, 8, ["inactive", "label", "loading"]))
      : _createCommentVNode("", true)
  ], 32))
}