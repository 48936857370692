import { DibbsErrorException } from '../../customExceptions';
import { ApiDibbsError } from '../interfaces';

type Props<T> = T & {
  __typename?: string;
};

const DibbsErrorHelper = <T>(data?: Props<T>): T => {
  if (!data) {
    throw new DibbsErrorException({
      name: 'DibbsError',
      description: `Return value is null or undefined.`,
      code: 'no_value_returned',
      message: 'no_value_returned',
    });
  }

  if (data && data.__typename === 'DibbsError') {
    const { error_info, error_code } = data as ApiDibbsError;

    throw new DibbsErrorException({
      name: 'DibbsError',
      description: error_info,
      code: error_code,
      message: error_code ? error_code : '',
    });
  }

  return data;
};

export { DibbsErrorHelper };
