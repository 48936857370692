import { App } from 'vue';
import AppText from './AppText.vue';
import { appTextProps } from './types';

export default {
  install: (app: App) => {
    app.component('AppText', AppText);
    app.config.globalProperties.$appTextProps = appTextProps;
  },
};
