import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex justify-center w-full" }
const _hoisted_2 = { class: "w-full lg:max-w-[410px]" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppText = _resolveComponent("AppText")!
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_AppToast = _resolveComponent("AppToast")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_AppText, {
        size: _ctx.$appTextProps.TextSize['text-3-bold']
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('kyc.kyc_due_diligence.start_message.title')), 1)
        ]),
        _: 1
      }, 8, ["size"]),
      _createVNode(_component_AppText, {
        class: "mt-24",
        size: _ctx.$appTextProps.TextSize['text-5-medium']
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('kyc.kyc_due_diligence.start_message.subtitle')), 1)
        ]),
        _: 1
      }, 8, ["size"]),
      _createVNode(_component_AppButton, {
        class: "mt-24",
        loading: _ctx.isLoading,
        wide: "",
        label: _ctx.$t('kyc.kyc_due_diligence.start_message.get_started'),
        onClick: _ctx.showQuestions
      }, null, 8, ["loading", "label", "onClick"])
    ]),
    _createVNode(_component_AppToast, {
      onOnDismiss: _ctx.onDismiss,
      dismissable: true,
      message: _ctx.toastMessage.message,
      open: _ctx.toastMessage.open
    }, null, 8, ["onOnDismiss", "message", "open"])
  ]))
}