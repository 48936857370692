import { library } from '@fortawesome/fontawesome-svg-core';

import {
  faBuildingColumns,
  faCaretDown,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircleXmark,
  faComments,
  faCreditCard,
  faDollarSign,
  faEdit,
  faEnvelope,
  faExchangeAlt,
  faExclamationTriangle,
  faEye,
  faEyeSlash,
  faHouse,
  faInfoCircle,
  faLock,
  faLongArrowAltDown,
  faLongArrowAltUp,
  faMessage,
  faRightFromBracket,
  faSearch,
  faShareAlt,
  faShieldHalved,
  faStar,
  faSync,
  faTimes,
  faTrashAlt,
  faUniversity,
  faUser,
  faArrowUpRightFromSquare,
} from '@fortawesome/free-solid-svg-icons';

import {
  faCalendar as faCalendarRegular,
  faCheckCircle as faCheckCircleRegular,
  faCircle as faCircleRegular,
  faImage as faImageRegular,
  faStar as faStarRegular,
} from '@fortawesome/free-regular-svg-icons';

import {
  faApple as faAppleBrand,
  faCcMastercard,
  faCcVisa,
  faDiscord,
  faFacebook,
  faFacebookF,
  faGoogle as faGoogleBrand,
  faInstagram,
  faTwitch,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons';

export const availableIcons = {
  faAppleBrand,
  faBuildingColumns,
  faCalendarRegular,
  faCaretDown,
  faCcMastercard,
  faCcVisa,
  faCheck,
  faCheckCircle,
  faCheckCircleRegular,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircleRegular,
  faCircleXmark,
  faComments,
  faCreditCard,
  faDiscord,
  faDollarSign,
  faEdit,
  faEnvelope,
  faExchangeAlt,
  faExclamationTriangle,
  faEye,
  faEyeSlash,
  faFacebook,
  faFacebookF,
  faGoogleBrand,
  faHouse,
  faImageRegular,
  faInfoCircle,
  faInstagram,
  faLock,
  faLongArrowAltDown,
  faLongArrowAltUp,
  faMessage,
  faRightFromBracket,
  faSearch,
  faShareAlt,
  faShieldHalved,
  faStar,
  faStarRegular,
  faSync,
  faTimes,
  faTrashAlt,
  faTwitch,
  faTwitter,
  faUniversity,
  faUser,
  faArrowUpRightFromSquare,
};

enum AVAILABLE_ICONS_MAP {
  'apple-fab' = 'fa-brands fa-apple',
  'building-columns-fas' = 'fa-solid fa-building-columns',
  'calendar-far' = 'fa-regular fa-calendar',
  'caret-down-fas' = 'fa-solid fa-caret-down',
  'cc-mastercard-fab' = 'fa-brands fa-cc-mastercard',
  'cc-visa-fab' = 'fa-brands fa-cc-visa',
  'check-fas' = 'fa-solid fa-check',
  'check-circle-fas' = 'fa-solid fa-check-circle',
  'check-circle-far' = 'fa-regular fa-check-circle',
  'chevron-left-fas' = 'fa-solid fa-chevron-left',
  'chevron-right-fas' = 'fa-solid fa-chevron-right',
  'chevron-up-fas' = 'fa-solid fa-chevron-up',
  'chevron-down-fas' = 'fa-solid fa-chevron-down',
  'circle-far' = 'fa-regular fa-circle',
  'circle-xmark' = 'fa-solid fa-circle-xmark',
  'comments-fas' = 'fa-solid fa-comments',
  'credit-card-fas' = 'fa-solid fa-credit-card',
  'discord-fab' = 'fa-brands fa-discord',
  'dollar-sign-fas' = 'fa-solid fa-dollar-sign',
  'edit-fas' = 'fa-solid fa-edit',
  'envelope-fas' = 'fa-solid fa-envelope',
  'exchange-alt-fas' = 'fa-solid fa-exchange-alt',
  'exclamation-triangle-fas' = 'fa-solid fa-exclamation-triangle',
  'eye-fas' = 'fa-solid fa-eye',
  'eye-slash-fas' = 'fa-solid fa-eye-slash',
  'facebook-fab' = 'fa-brands fa-facebook',
  'facebook-f-fab' = 'fa-brands fa-facebook-f',
  'google-fab' = 'fa-brands fa-google',
  'house-fas' = 'fa-solid fa-house',
  'info-circle-fas' = 'fa-solid fa-info-circle',
  'image-far' = 'fa-regular fa-image',
  'instagram-fab' = 'fa-brands fa-instagram',
  'lock-fas' = 'fa-solid fa-lock',
  'long-arrow-alt-down-fas' = 'fa-solid fa-long-arrow-alt-down',
  'long-arrow-alt-up-fas' = 'fa-solid fa-long-arrow-alt-up',
  'message-fas' = 'fa-solid fa-message',
  'right-from-bracket-fas' = 'fa-solid fa-right-from-bracket',
  'search-fas' = 'fa-solid fa-search',
  'sync-fas' = 'fa-solid fa-sync',
  'share-alt-fas' = 'fa-solid fa-share-alt',
  'shield-halved-fas' = 'fa-solid fa-shield-halved',
  'star-fas' = 'fa-solid fa-star',
  'star-far' = 'fa-regular fa-star',
  'trash-alt-fas' = 'fa-solid fa-trash-alt',
  'twitch-fab' = 'fa-brands fa-twitch',
  'twitter-fab' = 'fa-brands fa-twitter',
  'times-fas' = 'fa-solid fa-times',
  'university-fas' = 'fa-solid fa-university',
  'user-fas' = 'fa-solid fa-user',
  'external-link-fas' = 'fa-solid fa-arrow-up-right-from-square',
}

library.add(availableIcons);

export type AVAILABLE_ICONS = keyof typeof AVAILABLE_ICONS_MAP | AVAILABLE_ICONS_MAP;
export const AVAILABLE_ICONS = AVAILABLE_ICONS_MAP;
