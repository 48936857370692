export { default as RedeemStatusCard } from './RedeemStatusCard.vue';

export enum RedeemStatusPageTypes {
  requested = 'requested',
  ready_to_redeem = 'ready_to_redeem',
  processing = 'processing',
  in_transit = 'in_transit',
  delivered = 'delivered',
  processed = 'processed',
}
