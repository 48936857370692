import { useRoute, useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useGlobalToast } from '@Composables';
import { appToastProps, persistToast } from '@/plugins/components/AppToast';
import { useLogout } from '@Modules/Auth/modules/Logout';
import { RouteGuardException } from '../exeptions';
import { RouterGuardExceptionTypes } from '../types';

export default function () {
  const ROUTE = useRoute();
  const ROUTER = useRouter();
  const { t } = useI18n();

  const { logoutCleanUp } = useLogout();
  const { showError } = useGlobalToast();

  async function handleValidationError(error: RouteGuardException) {
    const { type, redirectUrl } = error;
    const currentRoute = ROUTE.name?.toString() ?? 'registration';
    const needForceLogout = type === RouterGuardExceptionTypes.LOGOUT;

    if (needForceLogout) {
      return softLogout(currentRoute);
    }

    return needRedirect(currentRoute, redirectUrl);
  }

  function setPersistentRouteToast(errorMessage: string) {
    persistToast({
      open: true,
      dismissable: false,
      message: t(errorMessage),
      type: appToastProps.Variations.ERROR,
      expiresAfter: 3000,
    });
  }

  function softLogout(currentRoute: string) {
    const isLogin = currentRoute === 'login';

    logoutCleanUp();

    if (isLogin) {
      showError(t('errors.protected_route'));
      return;
    }

    setPersistentRouteToast('errors.protected_route');

    return ROUTER.push('/login');
  }

  function needRedirect(currentRoute: string, redirectUrl?: string) {
    const redirectToCurrentRoute = redirectUrl === currentRoute;

    if (redirectToCurrentRoute) {
      showError(t('errors.registration_pending')); // This can be dynamic if necessary
      return;
    }

    setPersistentRouteToast('errors.registration_pending');

    const url = redirectUrl ?? 'registration';

    ROUTER.push({ name: url });
    return;
  }

  return {
    handleValidationError,
  };
}
