import { IAddress } from '@Shared/types/address';
import { apollo, DibbsApiException, DibbsErrorHelper, errorHandler } from '@/utils/helpers/api';
import MUTATION from '../services/mutations/UpdateOperatorAddress.mutation.gql';

interface Result {
  update_address: boolean;
}
interface Params {
  address?: IAddress;
}
export default async function (variables?: IAddress): Promise<Result['update_address']> {
  const params: Params = {};
  params.address = variables;

  return apollo
    .mutate<Result>({
      mutation: MUTATION,
      variables: { ...params },
    })
    .then(({ data }) => DibbsErrorHelper(data?.update_address))
    .then((data) => data)
    .catch((error) => {
      throw new DibbsApiException(errorHandler(error));
    });
}
