import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full" }
const _hoisted_2 = { class: "flex justify-center items-center w-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseBackRoute = _resolveComponent("BaseBackRoute")!
  const _component_KycCountry = _resolveComponent("KycCountry")!
  const _component_KycEntityNameFields = _resolveComponent("KycEntityNameFields")!
  const _component_KycAddress = _resolveComponent("KycAddress")!
  const _component_AppToast = _resolveComponent("AppToast")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_BaseBackRoute, { onHandleBackRoute: _ctx.handleBackRoute }, null, 8, ["onHandleBackRoute"]),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.showCountry)
        ? (_openBlock(), _createBlock(_component_KycCountry, {
            key: 0,
            availableCountries: _ctx.availableCountries,
            onSetCountry: _ctx.onSetCountry
          }, null, 8, ["availableCountries", "onSetCountry"]))
        : _createCommentVNode("", true),
      (_ctx.showNameFields)
        ? (_openBlock(), _createBlock(_component_KycEntityNameFields, {
            key: 1,
            storedNameFields: _ctx.entity.nameFieldsForm,
            onSetNameFields: _ctx.onSetNameFields
          }, null, 8, ["storedNameFields", "onSetNameFields"]))
        : (_ctx.showIncorporatedAddress)
          ? (_openBlock(), _createBlock(_component_KycAddress, {
              key: 2,
              storedAddress: _ctx.entity.incorporatedAddressForm,
              type: _ctx.ADDRESS_TYPE.INCORPORATED,
              onSetAddress: _ctx.onSetIncorporatedAddress
            }, null, 8, ["storedAddress", "type", "onSetAddress"]))
          : (_ctx.showPrincipalAddress)
            ? (_openBlock(), _createBlock(_component_KycAddress, {
                key: 3,
                isLoading: _ctx.isLoading,
                storedAddress: _ctx.entity.principalAddressForm,
                type: _ctx.ADDRESS_TYPE.PRINCIPAL,
                onSetAddress: _ctx.onSetPrincipalAddress
              }, null, 8, ["isLoading", "storedAddress", "type", "onSetAddress"]))
            : _createCommentVNode("", true),
      _createVNode(_component_AppToast, {
        dismissable: true,
        message: _ctx.toastMessage.message,
        open: _ctx.toastMessage.open,
        onOnDismiss: _ctx.onDismiss
      }, null, 8, ["message", "open", "onOnDismiss"])
    ])
  ]))
}