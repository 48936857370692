import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconAvatarBlack = _resolveComponent("IconAvatarBlack")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.containerClasses),
    "data-testid": "base-avatar",
    role: "avatar-container"
  }, [
    _createVNode(_component_IconAvatarBlack, {
      class: _normalizeClass(_ctx.iconClasses)
    }, null, 8, ["class"])
  ], 2))
}