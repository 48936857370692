import { useI18n } from 'vue-i18n';
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { useGlobalToast } from '@/utils/composables';
import { useLoadRedemption, RedeemStatus, cancelWithdraw, IRedemptionItem } from '@Modules/Crypto';
import { hideModal, showPrompt } from '@/utils';

export default function useRedemptionStatus() {
  const { showError } = useGlobalToast();
  const { onPageLoad, redirectToYourItemsError, currentRedemption, redirectToYourItemsSuccess } =
    useLoadRedemption();

  const { t } = useI18n();

  const router = useRouter();
  const redemptionId = computed(() => currentRedemption.value?.id);

  function navigateToBurnNftPage() {
    if (!redemptionId.value) {
      return redirectToYourItemsError();
    }

    router.push({ name: 'burn-nft', params: { redemptionId: redemptionId.value } });
  }

  function redirectToTrackingUrl() {
    const redemptionItem: IRedemptionItem | undefined = currentRedemption.value;
    if (redemptionItem) {
      const trackingUrl = redemptionItem.tracking_url;
      if (trackingUrl) {
        window.open(trackingUrl);
      }
    }
  }

  type RedeemStatusAttributes = {
    [K in keyof typeof RedeemStatus]: {
      title: string;
      description: string;
      buttonLabel?: string;
      action?: () => Promise<unknown> | void;
      showButton?: boolean;
    };
  };

  const redeemStatus = computed<RedeemStatusAttributes>(() => ({
    requested: {
      title: t('crypto.redemption.redeem_status.requested'),
      description: t('crypto.redemption.redeem_status.requested_description'),
      buttonLabel: t('crypto.redemption.redeem_status.cancel_withdraw'),
      action: () => cancelWithdrawAction(),
    },
    confirmed: {
      title: t('crypto.redemption.redeem_status.confirmed'),
      description: t('crypto.redemption.redeem_status.confirmed_description'),
      buttonLabel: t('crypto.redemption.redeem_status.cancel_withdraw'),
      action: () => cancelWithdrawAction(),
    },
    approved: {
      title: t('crypto.redemption.redeem_status.approved'),
      description: t('crypto.redemption.redeem_status.approved_description'),
      buttonLabel: t('crypto.redemption.redeem_status.burn_nft'),
      action: () => navigateToBurnNftPage(),
    },
    shipped: {
      title: t('crypto.redemption.redeem_status.shipped'),
      description: t('crypto.redemption.redeem_status.shipped_description'),
      buttonLabel: t('crypto.redemption.redeem_status.track_shipment'),
      action: () => redirectToTrackingUrl(),
      showButton: currentRedemption.value?.tracking_url ? true : false,
    },
    returned: {
      title: t('crypto.redemption.redeem_status.returned'),
      description: t('crypto.redemption.redeem_status.returned_description'),
      buttonLabel: t('crypto.redemption.redeem_status.track_shipment'),
      action: () => redirectToTrackingUrl(),
      showButton: currentRedemption.value?.tracking_url ? true : false,
    },
    arrived: {
      title: t('crypto.redemption.redeem_status.arrived'),
      description: t('crypto.redemption.redeem_status.arrived_description'),
    },
    cancelled: {
      title: t('crypto.redemption.redeem_status.cancelled'),
      description: t('crypto.redemption.redeem_status.cancelled_description'),
    },
    burnt: {
      title: t('crypto.redemption.redeem_status.burnt'),
      description: t('crypto.redemption.redeem_status.burnt_description'),
    },
  }));

  async function cancelWithdrawAction() {
    if (!redemptionId.value) {
      return redirectToYourItemsError();
    }

    showPrompt('cancel-withdraw', {
      bind: {
        title: t('crypto.redemption.cancel_withdraw.title'),
        message: t('crypto.redemption.cancel_withdraw.description'),
      },
      on: {
        cancel: () => hideModal('cancel-withdraw'),
        confirm: () => {
          cancelWithdraw({ redemptionId: redemptionId.value as string })
            .then(() => {
              redirectToYourItemsSuccess(t('crypto.redemption.cancel_withdraw.success_message'));
              hideModal('cancel-withdraw');
            })
            .catch(() => {
              hideModal('cancel-withdraw');
              showError(t('crypto.redemption.cancel_withdraw.failed_message'));
            });
        },
      },
    });
  }

  return {
    redeemStatus,
    navigateToBurnNftPage,
    redirectToYourItemsError,
    onPageLoad,
    currentRedemption,
  };
}
