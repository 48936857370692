
import { defineComponent, PropType } from 'vue';
import { IDropdownItem } from './types';

export default defineComponent({
  name: 'DropdownItem',
  props: {
    dropdownItem: {
      type: Object as PropType<IDropdownItem>,
      required: true,
    },
    iconRight: Boolean,
  },

  emits: ['dropdownAction'],

  computed: {
    itemClasses() {
      return {
        'dropdown-item hover:text-text-positive': true,
        'justify-start': !this.iconRight,
        'flex-row-reverse justify-between': this.iconRight,
      };
    },
  },

  methods: {
    async action() {
      this.$emit('dropdownAction', this.dropdownItem);
    },
  },
});
