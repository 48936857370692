import { IAddress } from '@Shared/types/address';

export function formatAddress(address: IAddress): string {
  if (!address) {
    return '';
  }

  const complement = address.address_complement || '';

  return `${address.address_street} ${complement} - ${address.address_city}, ${address.address_state} ${address.address_zip}, ${address.address_country}`;
}
