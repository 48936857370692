import { renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DropdownItem = _resolveComponent("DropdownItem")!
  const _component_BaseDropdown = _resolveComponent("BaseDropdown")!

  return (_openBlock(), _createBlock(_component_BaseDropdown, {
    alignLeft: _ctx.alignLeft,
    selectedDropdown: _ctx.selectedDropdown,
    hideArrow: _ctx.hideArrow,
    isOpen: _ctx.isOpen,
    onCloseDropdown: _ctx.onCloseDropdown,
    onToggle: _ctx.onToggle
  }, {
    "dropdown-button": _withCtx(() => [
      _renderSlot(_ctx.$slots, "dropdown-button", {}, undefined, true)
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(["dropdown-container", [_ctx.dropdownListClasses, _ctx.customClasses]])
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dropdownItems, (item, index) => {
          return (_openBlock(), _createBlock(_component_DropdownItem, {
            onClick: _ctx.onCloseDropdown,
            onDropdownAction: _ctx.onDropdownAction,
            key: index,
            dropdownItem: item,
            iconRight: _ctx.iconRight
          }, null, 8, ["onClick", "onDropdownAction", "dropdownItem", "iconRight"]))
        }), 128)),
        _renderSlot(_ctx.$slots, "footer", {}, undefined, true)
      ], 2)
    ]),
    _: 3
  }, 8, ["alignLeft", "selectedDropdown", "hideArrow", "isOpen", "onCloseDropdown", "onToggle"]))
}