import { App } from 'vue';
import AppSkeletonLoading from './AppSkeletonLoading.vue';
import { appSkeletonLoadingProps } from './types';

export default {
  install: (app: App) => {
    app.component('AppSkeletonLoading', AppSkeletonLoading);
    app.config.globalProperties.$appSkeletonLoadingProps = appSkeletonLoadingProps;
  },
};
