
import { defineComponent } from 'vue';
import { storeToRefs } from 'pinia';

import { ACCOUNT_TYPE } from '@Shared/enums/accountType';

import { useKycStore } from '../store';

import KycEntityIndex from './KycEntityIndex.vue';
import KycIndividualIndex from './KycIndividualIndex.vue';

export default defineComponent({
  name: 'KycIndex',

  components: {
    KycEntityIndex,
    KycIndividualIndex,
  },

  setup() {
    const { currentOperatorKycInfo } = storeToRefs(useKycStore());

    return {
      currentOperatorKycInfo,
    };
  },

  computed: {
    kycIndex(): string {
      return this.currentOperatorKycInfo?.account.type === ACCOUNT_TYPE.ENTITY
        ? 'KycEntityIndex'
        : 'KycIndividualIndex';
    },
  },
});
