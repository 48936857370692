
import { defineComponent, PropType, ref } from 'vue';

import { AVAILABLE_ICONS } from '@Libraries/font-awesome';

import { KEYS, IBaseSelect, ISelectItem } from './types';

export default defineComponent({
  name: 'BaseSelect',

  emits: ['update:modelValue'],

  data(): IBaseSelect {
    const itemFound = this.list.find((item) => item.value === this.modelValue);

    return {
      AVAILABLE_ICONS,
      input: itemFound?.label || '',
      itemSelected: !!this.modelValue,
      filteredList: this.list,
      highlight: this.highlightOnSelect,
      isOpen: false,
      isLoading: false,
      totalHeight: 0,
      focusedItem: null,
    };
  },

  setup() {
    const inputRef = ref<InstanceType<typeof HTMLInputElement>>();
    const optionsRef = ref<Array<InstanceType<typeof HTMLDivElement>>>();

    return { inputRef, optionsRef };
  },

  props: {
    highlightOnSelect: {
      type: Boolean,
    },

    list: {
      type: Array as PropType<Array<ISelectItem>>,
      required: true,
    },

    modelValue: {
      type: String,
      default: '',
    },

    placeholder: {
      type: String,
    },

    testId: {
      type: String,
      default: 'unamed',
    },

    visibleItems: {
      type: Number,
      default: 5,
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    containerClasses() {
      return {
        'select-container': true,
        'cursor-text': this.isOpen,
        'cursor-pointer': !this.isOpen,
        'field-error': this.showFieldError,
        [this.backgroundColor]: true,
      };
    },

    backgroundColor() {
      return this.disabled ? 'bg-background-input-disabled' : 'bg-background-primary';
    },

    maxHeight() {
      return `${this.visibleItems * 40 + 24}px`;
    },

    showFieldError() {
      return !this.itemSelected && !this.isOpen && !!this.input && !this.modelValue;
    },
  },

  watch: {
    modelValue(value) {
      const itemFound = this.list.find((item) => item.value === value);

      if (itemFound) {
        this.input = itemFound.label;
      }
    },
  },

  methods: {
    closeList() {
      this.filteredList = this.list;
      this.isOpen = false;
    },

    filter() {
      this.isOpen = true;
      this.itemSelected = false;
      this.focusedItem = null;
      this.$emit('update:modelValue', '');
      this.filteredList = this.list.filter((item) =>
        item.label.toLowerCase().includes(this.input.toLowerCase())
      );
    },

    fixScrolling() {
      return (
        this.optionsRef &&
        this.focusedItem !== null &&
        this.optionsRef[this.focusedItem] &&
        this.optionsRef[this.focusedItem].scrollIntoView({
          block: 'center',
        })
      );
    },

    handleKeyboardActions: function (event: KeyboardEvent) {
      switch (event.key) {
        case KEYS.TAB:
          this.handleTabPressed();
          break;
        case KEYS.ESC:
          this.closeList();
          break;
        case KEYS.ENTER:
          event.preventDefault();
          this.handleEnterPressed();
          break;
        case KEYS.ARROW_UP:
          this.handleArrowUpPressed();
          break;
        case KEYS.ARROW_DOWN:
          this.handleArrowDownPressed();
          break;
      }
    },

    handleArrowDownPressed() {
      if (this.focusedItem === null) {
        this.focusedItem = 0;
      } else if (this.focusedItem < this.filteredList.length - 1) {
        this.focusedItem++;
      }
      this.fixScrolling();
    },

    handleArrowUpPressed() {
      if (this.focusedItem === null) {
        this.focusedItem = 0;
      } else if (this.focusedItem > 0) {
        this.focusedItem--;
      }
      this.fixScrolling();
    },

    handleEnterPressed() {
      if (this.focusedItem !== null) {
        this.selectItem(this.filteredList[this.focusedItem]);
      }
    },

    handleTabPressed() {
      if (!this.isOpen) {
        return;
      }

      if (this.focusedItem !== null) {
        return this.selectItem(this.filteredList[this.focusedItem]);
      }

      if (this.filteredList.length === 1) {
        return this.selectItem(this.filteredList[0]);
      }

      this.closeList();
    },

    openList() {
      this.isOpen = true;
      this.inputRef?.focus();
      this.inputRef?.setSelectionRange(0, 99999);
      this.focusedItem = null;
      this.totalHeight = 0;
    },

    selectItem(item: ISelectItem) {
      this.itemSelected = true;
      this.input = item.label;
      this.$emit('update:modelValue', item.value);
      this.closeList();
    },
  },
});
