import { Provider } from '@/libraries/ethers/types';
import {
  CryptoMoreThanOneWalletError,
  CryptoNoWalletError,
  CryptoWalletIsLockedError,
} from '@Modules/Crypto';

const walletIsLocked = async () => {
  if (window?.ethereum.isMetaMask) {
    if (typeof window?.ethereum?._metamask?.isUnlocked === 'function') {
      const isUnlocked = await window?.ethereum?._metamask?.isUnlocked();
      if (!isUnlocked) {
        return true;
      }
    }
  }
  return false;
};

export const getMetamaskProvider = async (): Promise<Provider> => {
  let provider;

  if (window?.ethereum?.isMetaMask) {
    if (await walletIsLocked()) {
      throw CryptoWalletIsLockedError;
    }
    provider = window.ethereum;
  } else if (window?.web3?.currentProvider) {
    provider = window.web3.currentProvider;
  } else {
    throw CryptoNoWalletError;
  }

  if (provider) {
    if (provider !== window?.ethereum) {
      throw CryptoMoreThanOneWalletError;
    }

    return provider;
  }

  throw CryptoNoWalletError;
};
