import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "max-w-[410px] my-16 mr-16" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "flex justify-between" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppText = _resolveComponent("AppText")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", null, [
      _createVNode(_component_AppText, {
        class: "my-12",
        size: _ctx.$appTextProps.TextSize['text-5-bold']
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('operator.address.address')), 1)
        ]),
        _: 1
      }, 8, ["size"]),
      _createVNode(_component_AppText, {
        class: _normalizeClass({ 'mt-12': _ctx.loading }),
        loading: _ctx.skeletonLoading
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.address.address_street), 1)
        ]),
        _: 1
      }, 8, ["class", "loading"])
    ]),
    (_ctx.address.address_complement)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_AppText, {
            class: "my-12",
            size: _ctx.$appTextProps.TextSize['text-5-bold']
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('operator.address.complement')), 1)
            ]),
            _: 1
          }, 8, ["size"]),
          _createVNode(_component_AppText, {
            class: _normalizeClass({ 'mt-12': _ctx.loading }),
            loading: _ctx.skeletonLoading
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.address.address_complement), 1)
            ]),
            _: 1
          }, 8, ["class", "loading"])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("section", _hoisted_3, [
      _createElementVNode("div", null, [
        _createVNode(_component_AppText, {
          class: "my-12",
          size: _ctx.$appTextProps.TextSize['text-5-bold']
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('operator.address.city')), 1)
          ]),
          _: 1
        }, 8, ["size"]),
        _createVNode(_component_AppText, { loading: _ctx.skeletonLoading }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.address.address_city), 1)
          ]),
          _: 1
        }, 8, ["loading"])
      ]),
      _createElementVNode("div", null, [
        _createVNode(_component_AppText, {
          class: "my-12",
          size: _ctx.$appTextProps.TextSize['text-5-bold']
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('operator.address.state')), 1)
          ]),
          _: 1
        }, 8, ["size"]),
        _createVNode(_component_AppText, { loading: _ctx.skeletonLoading }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.address.address_state), 1)
          ]),
          _: 1
        }, 8, ["loading"])
      ])
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_AppText, {
        class: "my-12",
        tag: "legend",
        size: _ctx.$appTextProps.TextSize['text-5-bold']
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('operator.address.zip')), 1)
        ]),
        _: 1
      }, 8, ["size"]),
      _createVNode(_component_AppText, { loading: _ctx.skeletonLoading }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.address.address_zip), 1)
        ]),
        _: 1
      }, 8, ["loading"])
    ])
  ]))
}