import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppText = _resolveComponent("AppText")!
  const _component_KycIndividualNameInputs = _resolveComponent("KycIndividualNameInputs")!
  const _component_AppButton = _resolveComponent("AppButton")!

  return (_openBlock(), _createElementBlock("form", {
    class: "flex flex-col gap-8 max-w-[410px]",
    onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.setNameFields && _ctx.setNameFields(...args)), ["prevent"]))
  }, [
    _createVNode(_component_AppText, {
      size: _ctx.$appTextProps.TextSize['text-3-bold']
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('kyc.kyc_name_fields_individual.title')), 1)
      ]),
      _: 1
    }, 8, ["size"]),
    _createVNode(_component_AppText, { class: "mt-16" }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('kyc.kyc_name_fields_individual.sub_title')), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_KycIndividualNameInputs, {
      modelValue: _ctx.form,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form) = $event))
    }, null, 8, ["modelValue"]),
    _createVNode(_component_AppButton, {
      submit: "",
      wide: "",
      class: "mt-48",
      inactive: !_ctx.isFormValid,
      label: _ctx.$t('kyc.kyc_name_fields_individual.actions.continue'),
      size: _ctx.$appButtonProps.Size.large
    }, null, 8, ["inactive", "label", "size"])
  ], 32))
}