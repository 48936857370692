import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppText = _resolveComponent("AppText")!
  const _component_BaseInput = _resolveComponent("BaseInput")!
  const _component_AppButton = _resolveComponent("AppButton")!

  return (_openBlock(), _createElementBlock("form", {
    class: "flex flex-col gap-8 max-w-[410px]",
    onSubmit: _cache[4] || (_cache[4] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.setNameFields && _ctx.setNameFields(...args)), ["prevent"]))
  }, [
    _createVNode(_component_AppText, {
      size: _ctx.$appTextProps.TextSize['text-3-bold']
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('kyc.kyc_name_fields_entity.title')), 1)
      ]),
      _: 1
    }, 8, ["size"]),
    _createVNode(_component_AppText, { class: "mt-16" }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('kyc.kyc_name_fields_entity.sub_title')), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_BaseInput, {
      modelValue: _ctx.form.individualName,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.individualName) = $event)),
      autofocus: "",
      class: "mt-16",
      maxlength: "240",
      name: "individual_name",
      testId: "individual_name",
      required: "",
      validate: [
        !_ctx.hasSpecialCharacters(_ctx.form.individualName) ||
          _ctx.invalidField(_ctx.$t('kyc.kyc_name_fields_entity.form.individual_name')),
      ],
      "lp-ignore": false
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('kyc.kyc_name_fields_entity.form.individual_name')), 1)
      ]),
      _: 1
    }, 8, ["modelValue", "validate"]),
    _createVNode(_component_BaseInput, {
      modelValue: _ctx.form.legalName,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.legalName) = $event)),
      class: "mt-24",
      maxlength: "240",
      name: "legal_name",
      testId: "legal_name",
      required: "",
      validate: [
        !_ctx.isInvalidCompanyName(_ctx.form.legalName) ||
          _ctx.invalidField(_ctx.$t('kyc.kyc_name_fields_entity.form.legal_name')),
      ],
      "lp-ignore": false
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('kyc.kyc_name_fields_entity.form.legal_name')), 1)
      ]),
      _: 1
    }, 8, ["modelValue", "validate"]),
    _createVNode(_component_BaseInput, {
      modelValue: _ctx.form.dba,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.dba) = $event)),
      class: "mt-24",
      maxlength: "240",
      name: "dba",
      testId: "dba",
      required: "",
      validate: [
        !_ctx.isInvalidCompanyName(_ctx.form.dba) || _ctx.invalidField(_ctx.$t('kyc.kyc_name_fields_entity.form.dba')),
      ],
      "lp-ignore": false
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('kyc.kyc_name_fields_entity.form.dba')), 1)
      ]),
      _: 1
    }, 8, ["modelValue", "validate"]),
    _createVNode(_component_BaseInput, {
      modelValue: _ctx.form.type,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.form.type) = $event)),
      class: "mt-24 mb-16",
      maxlength: "50",
      name: "type",
      testId: "type",
      validate: [
        !_ctx.hasSpecialCharacters(_ctx.form.type) ||
          _ctx.invalidField(_ctx.$t('kyc.kyc_name_fields_entity.form.type')),
      ],
      "lp-ignore": false
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('kyc.kyc_name_fields_entity.form.type')), 1)
      ]),
      _: 1
    }, 8, ["modelValue", "validate"]),
    _createVNode(_component_AppButton, {
      submit: "",
      wide: "",
      inactive: !_ctx.isFormValid,
      label: _ctx.$t('kyc.kyc_name_fields_entity.actions.continue'),
      size: _ctx.$appButtonProps.Size.large
    }, null, 8, ["inactive", "label", "size"])
  ], 32))
}