
import { defineComponent, PropType } from 'vue';

import { inputProps } from '@BaseComponents/BaseInput';
import BaseInput from '@/components/base/BaseInput/BaseInput.vue';

import { IStoreKycEntityNameFields } from '@Modules/Kyc/types';

import { IKycEntityNameFieldsData, IEntityNameFieldsForm } from './types';

export default defineComponent({
  name: 'KycEntityNameFields',

  components: { BaseInput },

  props: {
    storedNameFields: Object as PropType<IStoreKycEntityNameFields>,
  },

  emits: {
    setNameFields: (nameFieldsForm: IEntityNameFieldsForm) => nameFieldsForm,
  },

  data(): IKycEntityNameFieldsData {
    return {
      form: {
        individualName: '',
        legalName: '',
        dba: '',
        type: '',
        ...this.storedNameFields,
      },
      inputProps,
    };
  },

  computed: {
    isFormValid(): boolean {
      return (
        this.isIndividualNameValid && this.isTypeValid && this.isLegalNameValid && this.isDbaValid
      );
    },

    isIndividualNameValid() {
      return !!this.form.individualName && !this.hasSpecialCharacters(this.form.individualName);
    },

    isLegalNameValid() {
      return !!this.form.legalName && !this.isInvalidCompanyName(this.form.legalName);
    },

    isDbaValid() {
      return !!this.form.dba && !this.isInvalidCompanyName(this.form.dba);
    },

    isTypeValid() {
      return !!this.form.type && !this.hasSpecialCharacters(this.form.type);
    },
  },

  methods: {
    hasSpecialCharacters(input: string) {
      return /[\\^±!@£$%&*_+¡€#¢§¶•ªº«<>?/:;|=]/.test(input);
    },

    isInvalidCompanyName(input: string) {
      return /[\\^±_¡¢§¶•ªº«<>/|]/.test(input);
    },

    invalidField(fieldName: string) {
      return this.$t('forms.errors.invalid_field', { field_name: fieldName });
    },

    setNameFields() {
      this.$emit('setNameFields', { ...this.form });
    },
  },
});
