
import { defineComponent, PropType } from 'vue';
import { ISkeletonLoading } from '@/plugins/components/AppSkeletonLoading';
import { IAddress } from '@Shared/types/address';

export default defineComponent({
  name: 'OperatorAddress',

  props: {
    address: {
      type: Object as PropType<IAddress>,
      required: true,
    },

    loading: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    skeletonLoading(): ISkeletonLoading {
      return {
        active: this.loading,
        height: '20px',
        width: '90%',
      };
    },
  },
});
