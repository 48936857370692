
import { defineComponent } from 'vue';
import { BaseModal, BaseInput, inputProps } from '@BaseUi';
import { ITwoFactorResetPayload } from '../../../../types';

export default defineComponent({
  name: 'TwoFactorResetModal',

  components: { BaseModal, BaseInput },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },

    requiresCaptcha: {
      type: Boolean,
      default: false,
    },

    testId: {
      type: String,
      default: 'twofaReset',
    },
  },

  emits: {
    onTwoFactorResetClose: () => true,

    onTwoFactorResetSubmit: (payload: ITwoFactorResetPayload) => payload,
  },

  setup() {
    return { inputProps };
  },

  data() {
    return {
      code: '',
      captcha: '',
    };
  },

  computed: {
    isFormReady(): boolean {
      return !!this.code && !this.loading && this.canSubmit;
    },

    canSubmit(): boolean {
      return this.requiresCaptcha ? !!this.captcha : true;
    },
  },

  watch: {
    '$attrs.twoAuthResetModal'() {
      this.code = '';
      this.captcha = '';
    },
  },

  methods: {
    onSubmit() {
      this.$emit('onTwoFactorResetSubmit', {
        twofa_recover: this.code,
        captcha: this.captcha,
      });
    },
  },
});
