import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-54484c32"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "flex items-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppSkeletonLoading = _resolveComponent("AppSkeletonLoading")!
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_AppText = _resolveComponent("AppText")!

  return (_ctx.loading)
    ? (_openBlock(), _createBlock(_component_AppSkeletonLoading, {
        key: 0,
        height: "26px"
      }))
    : (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_BaseIcon, {
          class: _normalizeClass(["status-icon", _ctx.active ? 'text-text-active-2' : 'text-text-inactive']),
          icon: _ctx.active ? 'check-circle-fas' : 'circle-xmark'
        }, null, 8, ["class", "icon"]),
        _createVNode(_component_AppText, {
          class: "ml-8",
          size: _ctx.$appTextProps.TextSize['text-4-medium']
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.label), 1)
          ]),
          _: 1
        }, 8, ["size"])
      ]))
}