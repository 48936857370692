import { App } from 'vue';
import AppButton from './AppButton.vue';
import { appButtonProps } from './types';

export default {
  install: (app: App) => {
    app.component('AppButton', AppButton);
    app.config.globalProperties.$appButtonProps = appButtonProps;
  },
};
