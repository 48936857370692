import { apollo, DibbsApiException, DibbsErrorHelper, errorHandler } from '@/utils/helpers/api';

import REDEMPTION_LIST from '../graphql/queries/RedemptionList.gql';
import { IRedemptionItem } from '../types';

interface Result {
  redemptions_list: IRedemptionItem[];
}

export async function getRedemptionList(): Promise<Result['redemptions_list']> {
  return apollo
    .query<Result>({
      query: REDEMPTION_LIST,
      fetchPolicy: 'no-cache',
    })
    .then(({ data }) => DibbsErrorHelper(data?.redemptions_list))
    .then((data) => data)
    .catch((error) => {
      throw new DibbsApiException(errorHandler(error));
    });
}

export default getRedemptionList;
