import { RouteGuardError } from '../types';

export default class RouteGuardException extends Error {
  type;
  redirectUrl;

  constructor(error: RouteGuardError) {
    super(error.name);
    this.name = error.name;
    this.type = error.type;
    this.redirectUrl = error.redirectUrl;
  }
}
