import { IAddressForm } from '@Modules/Kyc/components/KycAddressForm';
import { IGeocodeResponse } from './types';

export const getGoogleGeocodeDetails = (response: IGeocodeResponse['address_components']) => {
  return response.reduce(
    (acc: IAddressForm, current: IGeocodeResponse['address_components'][0]) => {
      switch (current.types[0]) {
        case 'street_number':
          acc.addressNumber = current.long_name;
          break;
        case 'route':
          acc.addressStreet = current.long_name;
          break;
        case 'administrative_area_level_2':
          acc.addressCity = current.long_name;
          break;
        case 'administrative_area_level_1':
          acc.addressState = current.short_name;
          break;
        case 'country':
          acc.addressCountry = current.short_name;
          break;
        case 'postal_code':
          acc.addressZip = current.long_name;
          break;
        default:
          break;
      }

      return acc;
    },
    {
      addressNumber: '',
      addressCountry: '',
      addressComplement: '',
      addressStreet: '',
      addressCity: '',
      addressState: '',
      addressZip: '',
    }
  );
};
