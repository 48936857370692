import { DibbsError } from '../graphql/interfaces/DibbsError';

export class DibbsApiException extends Error {
  description;
  code;
  message;

  constructor(error: DibbsError) {
    super(error.name);
    this.name = error.name;
    this.description = error.description;
    this.code = error.code;
    this.message = error.message;
  }
}
