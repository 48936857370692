import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4a331129"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  "data-testid": "main-logo",
  href: "https://dibbs.io",
  rel: "noopener",
  role: "link",
  target: "_blank"
}
const _hoisted_2 = {
  key: 1,
  role: "svg",
  "data-testid": "main-logo"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MainLogoDark = _resolveComponent("MainLogoDark")!
  const _component_MainLogoLight = _resolveComponent("MainLogoLight")!

  return (_ctx.linkToHome)
    ? (_openBlock(), _createElementBlock("a", _hoisted_1, [
        (_ctx.darkMode)
          ? (_openBlock(), _createBlock(_component_MainLogoDark, {
              key: 0,
              class: _normalizeClass([_ctx.classes, "main-logo"])
            }, null, 8, ["class"]))
          : (_openBlock(), _createBlock(_component_MainLogoLight, {
              key: 1,
              class: _normalizeClass([_ctx.classes, "main-logo"])
            }, null, 8, ["class"]))
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        (_ctx.darkMode)
          ? (_openBlock(), _createBlock(_component_MainLogoDark, {
              key: 0,
              class: _normalizeClass([_ctx.classes, "main-logo"])
            }, null, 8, ["class"]))
          : (_openBlock(), _createBlock(_component_MainLogoLight, {
              key: 1,
              class: _normalizeClass([_ctx.classes, "main-logo"])
            }, null, 8, ["class"]))
      ]))
}