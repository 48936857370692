
import { defineComponent } from 'vue';
import { storeToRefs } from 'pinia';

import { useGlobalToast } from '@/utils/composables';
import {
  KycEntityProvideIdentity,
  KycInformationSubmitted,
  KycStatus,
} from '@Modules/Kyc/components';
import { KycAdditionalDocOptions, KycStatusActions } from '@Modules/Kyc/types';
import { useKycStore } from '@Modules/Kyc/store';
import getCurrentOperatorKycInfo from '@Modules/Kyc/providers/getCurrentOperatorKycInfo.request';
import resendEmailConfirmation from '@Modules/Kyc/providers/resendEmailConfirmation.request';

export default defineComponent({
  name: 'KycIndexEntity',

  components: {
    KycEntityProvideIdentity,
    KycStatus,
    KycInformationSubmitted,
  },

  setup() {
    const { currentAction } = storeToRefs(useKycStore());
    const { setCurrentOperatorKycInfo, setIndividualAddress, setIndividualNameFields } =
      useKycStore();
    const { hideToast, showError, showSuccess, toast } = useGlobalToast();

    return {
      currentAction,
      KycAdditionalDocOptions,
      KycStatusActions,
      toast,
      hideToast,
      setCurrentOperatorKycInfo,
      setIndividualAddress,
      setIndividualNameFields,
      showError,
      showSuccess,
    };
  },

  data() {
    return {
      isLoading: false,
      isPageLoading: true,
      showStatus: true,
    };
  },

  computed: {
    bulletPoints(): Array<boolean> {
      switch (this.currentAction) {
        case KycStatusActions.resendEmail:
          return [false, false, false];
        case KycStatusActions.suspendedDismiss:
          return [false, false, false];
        case KycStatusActions.contactSupport:
          return [true, true, false];
        case KycStatusActions.underReviewDismiss:
          return [true, true, false];
        case KycStatusActions.provideExtraDocumentsPoa:
          return [true, false, false];
        case KycStatusActions.poaPendingDismiss:
          return [true, false, false];
        case KycStatusActions.provideExtraDocumentsPoi:
          return [true, false, false];
        case KycStatusActions.poiPendingDismiss:
          return [true, false, false];
        case KycStatusActions.doneDismiss:
          return [true, true, true];
        case KycStatusActions.provideIdentity:
          return [true, false, false];
        case KycStatusActions.provideDocuments:
          return [true, false, false];
        case KycStatusActions.answerDueDiligence:
          return [true, true, false];
        case KycStatusActions.resubmitIdentity:
          return [true, false, false];
        case KycStatusActions.resubmitDocuments:
          return [true, false, false];
        case KycStatusActions.resubmitDueDiligence:
          return [true, true, false];
        default:
          return [true, true, true];
      }
    },

    bulletPointsText(): Array<string> {
      return [
        this.$t('kyc.kyc_status.confirm_email'),
        this.$t('kyc.kyc_status.provide_identity'),
        this.$t('kyc.kyc_status.wait_internal_approval'),
      ];
    },

    isStatusActionHandled(): boolean {
      return this.showProvideIdentity;
    },

    showProvideIdentity(): boolean {
      return (
        this.currentAction === KycStatusActions.provideIdentity ||
        this.currentAction === KycStatusActions.resubmitIdentity
      );
    },

    showInformationSubmitted(): boolean {
      return (
        this.currentAction === KycStatusActions.answerDueDiligence ||
        this.currentAction === KycStatusActions.underReviewDismiss
      );
    },
  },

  async mounted() {
    await this.getOperatorInfo();
  },

  methods: {
    backToStatus() {
      this.showStatus = true;
    },

    async getOperatorInfo() {
      try {
        this.isPageLoading = true;

        const kycInfo = await getCurrentOperatorKycInfo();
        this.setCurrentOperatorKycInfo(kycInfo);
      } finally {
        this.isPageLoading = false;
      }
    },

    async handleAction() {
      try {
        this.isLoading = true;

        if (this.isStatusActionHandled) {
          this.showStatus = false;
          return;
        }

        switch (this.currentAction) {
          case KycStatusActions.resendEmail:
            await resendEmailConfirmation();
            return this.showSuccess(this.$t('kyc.kyc_index.success.confirmation_email_sent'), true);
          case KycStatusActions.suspendedDismiss:
            return this.$router.push('/support');
          case KycStatusActions.contactSupport:
            return this.$router.push('/support');
          case KycStatusActions.underReviewDismiss:
            return this.$router.push('/your-items');
          case KycStatusActions.poaPendingDismiss:
            return this.$router.push('/your-items');
          case KycStatusActions.poiPendingDismiss:
            return this.$router.push('/your-items');
          case KycStatusActions.doneDismiss:
            return this.$router.push('/your-items');
          default:
            return this.$router.push('/your-items');
        }
      } catch (err) {
        this.showError(this.$t('errors.generic'));
      } finally {
        this.isLoading = false;
      }
    },

    async handleDueDiligenceDone() {
      await this.getOperatorInfo();
      this.backToStatus();
    },

    redirectAfterSubmit() {
      this.backToStatus();
    },
  },
});
