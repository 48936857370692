const CONDITIONS_REQUIRED = 4;
const MAX_LENGTH = 64;
const MIN_LENGTH = 8;

interface RegexConditions {
  [key: string]: RegExp;
}

const regexConditions: RegexConditions = {
  hasLowerCaseLetter: /[a-z]/,
  hasNumber: /\d/,
  // eslint-disable-next-line no-useless-escape
  hasSpecialCharacter: /[-._!"`'#%&,:;<>=@{}~\$\(\)\*\+\/\\\?\[\]\^\|]+/,
  hasUpperCaseLetter: /[A-Z]/,
};

const applyRegex = (regexType: string, password: string) => {
  return regexConditions[regexType] ? regexConditions[regexType].test(password) : false;
};

const isPasswordValid = (password: string) => {
  let conditionsMet = 0;

  Object.keys(regexConditions).forEach(
    (condition) => applyRegex(condition, password) && conditionsMet++
  );

  return validatePasswordLength(password) && conditionsMet >= CONDITIONS_REQUIRED;
};

const validatePasswordLength = (password: string) => {
  return password.length >= MIN_LENGTH && password.length <= MAX_LENGTH;
};

export {
  CONDITIONS_REQUIRED,
  MAX_LENGTH,
  MIN_LENGTH,
  applyRegex,
  isPasswordValid,
  validatePasswordLength,
  regexConditions,
};
