
import { defineComponent, PropType } from 'vue';

import { BaseMainLogo } from '@BaseComponents/BaseMainLogo';
import { Version } from './types';

export default defineComponent({
  name: 'BaseLoading',
  components: {
    BaseMainLogo,
  },

  setup() {
    return {
      Version,
    };
  },

  props: {
    version: {
      type: String as PropType<Version>,
      default: 'v1',
    },
  },
});
