import { apollo, DibbsApiException, DibbsErrorHelper, errorHandler } from '@/utils/helpers/api';
import MUTATION from '../graphql/mutations/TwoFactorResetStepTwo.gql';

interface Params {
  secret: string;
}

interface Result {
  twofa_reset_step2: {
    result: boolean;
  };
}

export default async function (variables: Params): Promise<Result['twofa_reset_step2']> {
  return apollo
    .mutate<Result>({
      mutation: MUTATION,
      variables: { ...variables },
    })
    .then(({ data }) => DibbsErrorHelper(data?.twofa_reset_step2))
    .then((data) => data)
    .catch((error) => {
      throw new DibbsApiException(errorHandler(error));
    });
}
