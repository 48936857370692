import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppText = _resolveComponent("AppText")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_BaseCard = _resolveComponent("BaseCard")!
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createBlock(_component_RouterLink, { to: "change-password" }, {
    default: _withCtx(() => [
      _createVNode(_component_BaseCard, { class: "flex items-center justify-between" }, {
        default: _withCtx(() => [
          _createElementVNode("section", null, [
            _createVNode(_component_AppText, {
              tag: "h2",
              size: _ctx.$appTextProps.TextSize['text-4-regular']
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('auth.changePassword.title')), 1)
              ]),
              _: 1
            }, 8, ["size"]),
            _createVNode(_component_AppText, {
              tag: "h2",
              size: _ctx.$appTextProps.TextSize['text-5-regular']
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('auth.changePassword.description')), 1)
              ]),
              _: 1
            }, 8, ["size"])
          ]),
          _createVNode(_component_font_awesome_icon, {
            class: "'fa-icon'",
            icon: _ctx.AVAILABLE_ICONS['chevron-right-fas'],
            role: "icon"
          }, null, 8, ["icon"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}