import { apollo, DibbsApiException, DibbsErrorHelper, errorHandler } from '@/utils/helpers/api';

import TWO_FACTOR_AUTH_DISABLE_STEP_ONE from '../graphql/mutations/TwoFactorAuthDisableStepOne.gql';

interface Result {
  twofa_disable_step1: {
    result: boolean;
  };
}

async function verify2FADisableCode(twofa: string): Promise<Result['twofa_disable_step1']> {
  return apollo
    .mutate<Result>({
      mutation: TWO_FACTOR_AUTH_DISABLE_STEP_ONE,
      variables: {
        twofa,
      },
    })
    .then(({ data }) => DibbsErrorHelper(data?.twofa_disable_step1))
    .then((data) => data)
    .catch((error) => {
      throw new DibbsApiException(errorHandler(error));
    });
}

export default verify2FADisableCode;
