
import { defineComponent, PropType } from 'vue';
import { KycAdditionalDocOptions } from '@Modules/Kyc/types';
import { ImageUpload } from '@Ui/ImageUpload';
import { useKycStore } from '@Modules/Kyc/store';
import uploadPersonalId from '@Modules/Kyc/providers/uploadPersonalId.request';
import uploadProofOfAddress from '@Modules/Kyc/providers/uploadProofOfAddress.request';

import { IKycAdditionalDocsData } from './types';

export default defineComponent({
  name: 'KycAdditionalDocs',

  components: {
    ImageUpload,
  },

  props: {
    requiredDocs: {
      type: Array as PropType<Array<KycAdditionalDocOptions>>,
      required: true,
    },
  },

  emits: ['done'],

  setup() {
    const { readableAddress } = useKycStore();
    return {
      readableAddress,
      KycAdditionalDocOptions,
    };
  },

  data(): IKycAdditionalDocsData {
    return {
      currentDocIndex: 0,
      filesToUpload: [],
      isLoading: false,
      toastMessage: {
        open: false,
        message: '',
      },
    };
  },

  computed: {
    currentDoc(): KycAdditionalDocOptions {
      return this.requiredDocs[this.currentDocIndex];
    },

    currentDocStrings(): string {
      return `kyc.kyc_additional_docs.${this.currentDoc}`;
    },

    currentDocExamples(): number {
      const poaDocExamplesIndex = 9;
      const poiDocExamplesIndex = 4;

      return this.currentDoc === KycAdditionalDocOptions.proofOfAddress
        ? poaDocExamplesIndex
        : poiDocExamplesIndex;
    },

    currentDocDetail(): string {
      if (this.currentDoc === KycAdditionalDocOptions.proofOfAddress) {
        return this.readableAddress;
      }
      return '';
    },

    currentDocRequireExtraFile(): boolean {
      return this.currentDoc === KycAdditionalDocOptions.proofOfIdentity;
    },

    currentDocIsValid(): boolean {
      return typeof this.filesToUpload[0] === 'object';
    },

    isDocAddress(): boolean {
      return this.currentDoc === KycAdditionalDocOptions.proofOfAddress;
    },
  },

  methods: {
    getCurrentDocLabel(index = 0) {
      if (!this.currentDocRequireExtraFile) {
        return '';
      }

      return this.$t(`${this.currentDocStrings}.label_${index}`);
    },

    handleDocumentUpload(file: File, index = 0) {
      this.filesToUpload[index] = file;
    },

    async handleUpload() {
      if (typeof this.filesToUpload[0] !== 'object') {
        return;
      }

      if (this.isDocAddress) {
        return await uploadProofOfAddress({
          file: this.filesToUpload[0],
        });
      }

      return await uploadPersonalId({
        front: this.filesToUpload[0],
        back: this.filesToUpload[1],
      });
    },

    async nextStep() {
      if (!this.currentDocIsValid) {
        return;
      }

      try {
        this.isLoading = true;

        await this.handleUpload();

        if (this.currentDocIndex + 1 >= this.requiredDocs.length) {
          return this.$emit('done');
        }

        this.currentDocIndex++;
        this.resetSelectedDocument();
      } catch (err) {
        this.toastMessage = {
          open: true,
          message: this.$t('errors.generic'),
        };
      } finally {
        this.isLoading = false;
      }
    },

    onDismiss() {
      this.toastMessage.open = false;
    },

    resetSelectedDocument(index?: number) {
      if (index !== undefined) {
        this.filesToUpload[index] = undefined;

        return;
      }

      this.filesToUpload = [];
    },
  },
});
