import { AppError } from '@/utils/helpers/errors/customExceptions/AppErrorException';

const MODULE_NAME = 'crypto';

export const CryptoMoreThanOneWalletError = new AppError({
  code: 'more_than_one_wallet_extension',
  module: MODULE_NAME,
});

export const CryptoNoWalletError = new AppError({
  code: 'no_wallet',
  module: MODULE_NAME,
});

export const CryptoUserDeniedAccessError = new AppError({
  code: 'user_denied_access',
  module: MODULE_NAME,
});

export const CryptoUserDeclinedSignError = new AppError({
  code: 'user_denied_sign',
  module: MODULE_NAME,
});

export const CryptoWalletIsLockedError = new AppError({
  code: 'wallet_locked',
  module: MODULE_NAME,
});

export const CryptoDisconnectedWalletError = new AppError({
  code: 'disconnected_user',
  module: MODULE_NAME,
});
