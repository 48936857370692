import { mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = ["alt", "src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppSkeletonLoading = _resolveComponent("AppSkeletonLoading")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (!_ctx.hideImage && !_ctx.loading)
    ? (_openBlock(), _createElementBlock("img", _mergeProps({
        key: 0,
        onError: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onError && _ctx.onError(...args))),
        onLoad: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onLoad && _ctx.onLoad(...args))),
        alt: _ctx.alt,
        class: "default-transition",
        src: _ctx.source
      }, _ctx.imgTagSizeAttributes), null, 16, _hoisted_1))
    : (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_AppSkeletonLoading, {
          key: 1,
          height: _ctx.imgTagSizeAttributes.height,
          width: _ctx.imgTagSizeAttributes.width
        }, null, 8, ["height", "width"]))
      : (_openBlock(), _createElementBlock("div", {
          key: 2,
          class: "bg-gray-200 flex items-center justify-center",
          style: _normalizeStyle({
      width: _ctx.imgTagSizeAttributes.width + 'px',
      height: _ctx.imgTagSizeAttributes.height + 'px',
    })
        }, [
          _createVNode(_component_font_awesome_icon, {
            class: "w-64 h-64 text-gray-400",
            icon: _ctx.AVAILABLE_ICONS['image-far']
          }, null, 8, ["icon"])
        ], 4))
}