import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VueHcaptcha = _resolveComponent("VueHcaptcha")!

  return (_openBlock(), _createBlock(_component_VueHcaptcha, {
    ref: "hCaptchaRef",
    challengeContainer: "captcha",
    "data-testid": _ctx.testId,
    sitekey: _ctx.captchaKey,
    size: _ctx.size,
    onChallengeExpired: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('challengeExpired'))),
    onClosed: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('closed'))),
    onExpired: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('expired'))),
    onOpened: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('opened'))),
    onError: _ctx.handleError,
    onVerify: _ctx.handleVerify
  }, null, 8, ["data-testid", "sitekey", "size", "onError", "onVerify"]))
}