
import { defineComponent, PropType } from 'vue';
import { Loading, TextPosition, TextSize } from './types';
import { appTextProps } from './types';

export default defineComponent({
  name: 'AppText',

  props: {
    color: {
      type: String,
      default: '',
    },

    loading: {
      type: [Object, Boolean] as PropType<Loading | boolean>,
    },

    position: {
      type: String as PropType<TextPosition>,
      default: appTextProps.TextPosition.left,
    },

    tag: {
      type: String,
      default: 'p',
    },

    size: {
      type: String as PropType<TextSize>,
      default: '',
    },
  },
  computed: {
    componentClasses() {
      return {
        [this.color]: true,
        [this.position]: true,
        [this.size]: true,
      };
    },

    loadingOptions(): Loading {
      if (this.loading) {
        if (typeof this.loading === 'boolean') {
          return {
            active: true,
            width: '100%',
            height: '20px',
          };
        } else {
          const loading = this.loading as Loading;
          return {
            active: loading.active || false,
            width: loading.width || '100%',
            height: loading.height || '20px',
          };
        }
      }

      return {
        active: false,
        width: '100%',
        height: '20px',
      };
    },
  },
});
