import { onMounted, onUnmounted } from 'vue';
import useWalletProvider from './useWalletProvider';

export default function useWatchWallet() {
  const { updateCryptoCurrentUser } = useWalletProvider();

  onMounted(async () => {
    updateCryptoCurrentUser().then().catch();

    window.ethereum?.addListener('chainChanged', () => {
      window.location.reload();
    });

    window.ethereum?.addListener('accountsChanged', async () => {
      updateCryptoCurrentUser().then().catch();
    });
  });

  onUnmounted(() => {
    window.ethereum?.removeAllListeners('accountsChanged');
    window.ethereum?.removeAllListeners('chainChanged');
  });
}
