import { DibbsError } from '../graphql/interfaces/DibbsError';

export class DibbsErrorException extends Error {
  dibbsError;

  constructor(error: DibbsError) {
    super(error.name);
    this.dibbsError = error;
  }
}
