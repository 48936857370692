import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.$slots.default)
    ? (_openBlock(), _createElementBlock("button", {
        key: 0,
        class: "autocomplete-button text-6-medium focus:bg-background-inverted hover:bg-background-inverted bg-opacity-10",
        type: "button",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click')))
      }, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ]))
    : _createCommentVNode("", true)
}