import { apollo, DibbsApiException, DibbsErrorHelper, errorHandler } from '@/utils/helpers/api';

import TWO_FACTOR_AUTH_SETUP_STEP_TWO from '../graphql/mutations/TwoFactorAuthSetupStepTwo.gql';

interface Result {
  twofa_setup_step2: {
    secret: string;
    recovery: string;
    otpauthUrl: string;
  };
}

async function generateTwoFaSecret(secret: string): Promise<Result['twofa_setup_step2']> {
  return apollo
    .mutate<Result>({
      mutation: TWO_FACTOR_AUTH_SETUP_STEP_TWO,
      variables: {
        secret,
      },
    })
    .then(({ data }) => DibbsErrorHelper(data?.twofa_setup_step2))
    .then((data) => data)
    .catch((error) => {
      throw new DibbsApiException(errorHandler(error));
    });
}

export default generateTwoFaSecret;
