
import { defineComponent, PropType } from 'vue';
import { useWindowSize } from '@vueuse/core';
import { computed } from '@vue/reactivity';
import { IMAGE_SIZES, ImageSize } from './index';
import { AVAILABLE_ICONS } from '@/libraries/font-awesome';

export default defineComponent({
  name: 'ImageResize',

  props: {
    src: {
      type: String,
      required: true,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    alt: {
      type: String,
      default: '',
    },

    onImageLoad: {
      type: Function,
    },

    type: {
      type: String as PropType<keyof ImageSize>,
      default: 'dibbs_nft',
    },

    width: Number,

    height: Number,

    startHidden: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const { width: windowWidth } = useWindowSize();

    const isSmallMobileDevice = computed(() => {
      return windowWidth.value < 500;
    });

    const responsiveType = computed(() => {
      const isSmallScreen = isSmallMobileDevice.value && !props.type.includes('_mobile');
      const typeMobile = `${props.type}_mobile` as keyof ImageSize;
      return isSmallScreen ? typeMobile : props.type;
    });

    return {
      isSmallMobileDevice,
      responsiveType,
      AVAILABLE_ICONS,
    };
  },

  data() {
    return {
      hideImage: false,
    };
  },

  created() {
    if (this.startHidden) {
      this.hideImage = true;
    }
  },

  computed: {
    imgWidth() {
      return this.width || IMAGE_SIZES[this.responsiveType].width;
    },

    imgHeight() {
      return this.height || IMAGE_SIZES[this.responsiveType].height;
    },

    source() {
      const validCdns = /(https:\/\/img.dibbscdn.com\/|https:\/\/img.dibbs.io\/)/g;
      const cdnResize = /cdn-cgi\/image\/width=\d*,height=\d*\//g;
      if (this.src && validCdns.test(this.src)) {
        const [, cdn, file] = this.src.split(validCdns);
        const fileWithoutResize = file.split(cdnResize);
        const filePath = fileWithoutResize[fileWithoutResize.length - 1];
        return `${cdn}cdn-cgi/image/width=${this.adjustSizeWithDpi(
          this.imgWidth
        )},height=${this.adjustSizeWithDpi(this.imgHeight)},format=webp/${filePath}`;
      }
      return this.src;
    },

    imgTagSizeAttributes() {
      const sizeAttributes = {
        height: this.imgHeight,
        width: this.imgWidth,
      };

      return sizeAttributes;
    },
  },

  methods: {
    adjustSizeWithDpi(size: number) {
      if (!size) {
        return;
      }
      const dpi = window.devicePixelRatio;
      return Math.round(size * dpi);
    },

    onError() {
      this.hideImage = true;
    },

    onLoad() {
      this.hideImage = false;
      this.onImageLoad && this.onImageLoad();
    },
  },
});
