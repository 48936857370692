import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex justify-center items-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_KycStatus = _resolveComponent("KycStatus")!
  const _component_BaseBackRoute = _resolveComponent("BaseBackRoute")!
  const _component_KycIndividualProvideIdentity = _resolveComponent("KycIndividualProvideIdentity")!
  const _component_KycDocuments = _resolveComponent("KycDocuments")!
  const _component_KycAdditionalDocs = _resolveComponent("KycAdditionalDocs")!

  return (_ctx.showStatus)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_KycStatus, {
          bulletPoints: _ctx.bulletPoints,
          bulletPointsText: _ctx.bulletPointsText,
          currentAction: _ctx.currentAction,
          isLoading: _ctx.isLoading,
          isPageLoading: _ctx.isPageLoading,
          onOnKycAction: _ctx.handleAction
        }, null, 8, ["bulletPoints", "bulletPointsText", "currentAction", "isLoading", "isPageLoading", "onOnKycAction"])
      ]))
    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
        (!_ctx.showProvideIdentity)
          ? (_openBlock(), _createBlock(_component_BaseBackRoute, {
              key: 0,
              onHandleBackRoute: _ctx.backToStatus
            }, null, 8, ["onHandleBackRoute"]))
          : _createCommentVNode("", true),
        (_ctx.showProvideIdentity)
          ? (_openBlock(), _createBlock(_component_KycIndividualProvideIdentity, {
              key: 1,
              onDone: _ctx.handleAction,
              onHandleBackRoute: _ctx.backToStatus
            }, null, 8, ["onDone", "onHandleBackRoute"]))
          : (_ctx.showDocuments)
            ? (_openBlock(), _createBlock(_component_KycDocuments, {
                key: 2,
                onDone: _ctx.afterKycDocs
              }, null, 8, ["onDone"]))
            : (_ctx.showAdditionalDocsPoi)
              ? (_openBlock(), _createBlock(_component_KycAdditionalDocs, {
                  key: 3,
                  requiredDocs: [_ctx.KycAdditionalDocOptions.proofOfIdentity],
                  onDone: _ctx.afterKycExtraDocs
                }, null, 8, ["requiredDocs", "onDone"]))
              : (_ctx.showAdditionalDocsPoa)
                ? (_openBlock(), _createBlock(_component_KycAdditionalDocs, {
                    key: 4,
                    requiredDocs: [_ctx.KycAdditionalDocOptions.proofOfAddress],
                    onDone: _ctx.afterKycExtraDocs
                  }, null, 8, ["requiredDocs", "onDone"]))
                : _createCommentVNode("", true)
      ], 64))
}