import DibbsContract from '@Modules/Crypto/DibbsABI.json';
import { DibbsNft, getMetamaskProvider, useWalletStore } from '@Modules/Crypto';
import { ethers as Crypto } from '@Libraries/index';
import { bigNumberToNumber } from '@Libraries/bignumber';
import { Contract } from '@/libraries/ethers/types';
import { AppError } from '@/utils/helpers/errors/customExceptions/AppErrorException';
import { useI18n } from 'vue-i18n';
import { useGlobalToast } from '@/utils/composables';

export default function useDibbsContract() {
  const { t } = useI18n();
  const { showError } = useGlobalToast();
  const { getNftByTokenId } = useWalletStore();

  let crypto: Crypto;
  let contract: Contract;
  const metadataUrl = process.env.VUE_APP_SMART_CONTRACT_METADATA || '';

  async function initContract() {
    const contractAddress = process.env.VUE_APP_SMART_CONTRACT_ADDRESS || '';
    const provider = await getMetamaskProvider();

    try {
      crypto = new Crypto(provider);
      const signer = crypto.getSigner();
      contract = crypto.initContract(DibbsContract.abi, contractAddress, signer);
    } catch (error) {
      throw new AppError({
        message: t('errors.generic'),
        description: 'Error initializing contract: ' + (error as Error).message,
        code: 'contract_connection',
        module: 'crypto',
      });
    }
  }

  // TODO: Remove this function once the contract is updated
  function formatURI(uri: string) {
    if (!uri.startsWith('ipfs') && !uri.startsWith('https://') && !uri.startsWith('http://')) {
      return metadataUrl + uri;
    }

    if (uri.startsWith('ipfs')) {
      return null; //'https://ipfs.io/ipfs/' + uri.substring(7);
    }

    return uri;
  }

  async function getSymbol() {
    await initContract();
    const symbol = await contract.symbol();
    return symbol;
  }

  async function getContractNftsByAddress(address: string) {
    await initContract();

    try {
      const chainId = await crypto.getChainId();
      const signer = await crypto.getSignerAddress();
      const supply = await contract.balanceOf(signer);
      const nfts: DibbsNft[] = [];

      for (let i = 0; i < supply; i++) {
        const token = await contract.tokenOfOwnerByIndex(address, i);
        const nftUri: string = await contract.tokenURI(token);
        let formattedURI: string | null = null;

        if (nftUri && typeof nftUri === 'string' && nftUri.length > 6) {
          formattedURI = formatURI(nftUri);
        }

        const tokenId = bigNumberToNumber(token);
        if (formattedURI) {
          try {
            const result = await getNftMetadata(formattedURI, tokenId);
            nfts.push({
              tokenId,
              name: result.name,
              description: result.description,
              image: result.image,
              image_url: result.external_url ?? result.image,
              attributes: result.attributes,
              network: chainId,
            });
          } catch (error) {
            // SHOULD DO NOTHING
          }
        }
      }

      return nfts;
    } catch (error) {
      const err = new AppError({
        message: t('errors.generic'),
        description: 'Error get NFTs from the contract: ' + (error as Error).message,
        code: 'contract_list_methods',
        module: 'crypto',
      });

      showError(err);
    }
  }

  async function getNftMetadata(metadataUrl: string, tokenId: number) {
    const metadata = getNftByTokenId(tokenId.toString());
    if (metadata) {
      return metadata;
    }

    const nftDataResult = await fetch(metadataUrl, {
      method: 'GET',
      headers: {
        accept: 'application/json',
      },
    });

    if (nftDataResult.ok) {
      return await nftDataResult.json();
    }
  }

  async function burnNft(
    tokenId: number,
    signature: string,
    expirationBlock: number
  ): Promise<string> {
    await initContract();

    try {
      const burnTransaction = await contract.burn(tokenId, signature, expirationBlock);
      return burnTransaction.hash;
    } catch (error) {
      const err = error as Error;

      if (err.message.includes('caller is not token owner nor approved')) {
        throw new AppError({
          description: 'User is not the owner of the NFT',
          code: 'invalid_burn_wallet',
          module: 'crypto',
        });
      }

      if (err.message.includes('user rejected transaction')) {
        throw new AppError({
          description: 'User denied transaction signature',
          code: 'user_denied_tx',
          module: 'crypto',
        });
      }

      throw new AppError({
        description: 'Error burning NFT: ' + (error as Error).message,
        code: 'contract_burn',
        module: 'crypto',
      });
    }
  }

  return {
    getSymbol,
    getContractNftsByAddress,
    burnNft,
  };
}
