export default [
  {
    path: '/reset-password',
    name: 'reset-password',
    component: () => import('@/views/ResetPasswordView/ResetPasswordView.vue'),
    meta: {
      isPublic: true,
    },
  },
];
