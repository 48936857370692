
import { defineComponent, PropType, ref } from 'vue';
import { getCdnImage } from '@/utils';
import { ImageResizeProps, IMAGE_SIZES, ImageResize } from '@Ui/ImageResize';

type StringObject = { [key: string]: string };

export default defineComponent({
  name: 'SquareImage',

  components: {
    ImageResize,
  },

  props: {
    image: {
      type: String,
      required: true,
    },

    loading: {
      type: Boolean,
    },

    coverClasses: String,

    imageResizeProps: Object as PropType<ImageResizeProps>,

    width: Number,

    height: Number,
  },

  setup(props) {
    const squareImage = ref<InstanceType<typeof HTMLPictureElement>>();
    const picWidth = IMAGE_SIZES[props.imageResizeProps?.type || 'dibbs_nft']?.width;
    const maxWidth = props.width || picWidth || 100;

    return {
      squareImage,
      maxWidth,
    };
  },

  computed: {
    imageProps() {
      let props = {
        src: this.image,
        style: [this.maxHeight],
        class: ['front '],
        height: this.height,
        width: this.width,
      };

      if (this.imageResizeProps) {
        props = {
          ...props,
          ...this.imageResizeProps,
          src: this.imageResizeProps.src || this.image,
          class: props.class,
        };
      }

      return props;
    },

    backgroundImage(): StringObject {
      const image = getCdnImage(this.image, {});

      return {
        backgroundImage: `url(${image})`,
      };
    },

    coverProps(): { styles: StringObject; classes: string[] } {
      return {
        styles: {
          ...this.backgroundImage,
          ...this.maxHeight,
          'max-width': `${this.maxWidth}px`,
        },

        classes: ['cover', this.coverClasses as string],
      };
    },

    squareSize(): number {
      return this.height || this.maxWidth;
    },

    maxHeight(): StringObject {
      return {
        'max-height': `${this.height || this.maxWidth}px`,
        'max-width': `${this.maxWidth}px`,
      };
    },
  },

  updated() {
    this.maxWidth = this.squareSize;
  },

  mounted() {
    this.updateMaxWidth();
  },

  methods: {
    updateMaxWidth() {
      const square = this.$refs.square as HTMLDivElement;
      this.maxWidth = square?.clientWidth;
    },
  },
});
