import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col rounded-4 p-16 border bg-background-active border-border-active-nav gap-12" }
const _hoisted_2 = { class: "flex items-center gap-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppText = _resolveComponent("AppText")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.$slots.icon)
        ? _renderSlot(_ctx.$slots, "icon", { key: 0 })
        : _createCommentVNode("", true),
      _createVNode(_component_AppText, {
        tag: "h4",
        size: _ctx.$appTextProps.TextSize['text-5-bold']
      }, {
        default: _withCtx(() => [
          (_ctx.$slots.title)
            ? _renderSlot(_ctx.$slots, "title", { key: 0 })
            : _createCommentVNode("", true)
        ]),
        _: 3
      }, 8, ["size"])
    ]),
    _createVNode(_component_AppText, {
      tag: "p",
      size: _ctx.$appTextProps.TextSize['text-5-regular']
    }, {
      default: _withCtx(() => [
        (_ctx.$slots.default)
          ? _renderSlot(_ctx.$slots, "default", { key: 0 })
          : _createCommentVNode("", true)
      ]),
      _: 3
    }, 8, ["size"])
  ]))
}