
import { defineComponent } from 'vue';

import MainLogoDark from '@Assets/icons/logo_main.svg';
import MainLogoLight from '@Assets/icons/logo_main_light.svg';

export default defineComponent({
  name: 'BaseMainLogo',
  components: {
    MainLogoDark,
    MainLogoLight,
  },

  computed: {
    classes() {
      return {
        ['cursor-pointer']: this.linkToHome,
        ['default-transition']: this.linkToHome,
        ['hover:opacity-80']: this.linkToHome,
        ['main-logo']: this.linkToHome,
      };
    },
  },

  props: {
    linkToHome: {
      type: Boolean,
      default: false,
    },
    darkMode: {
      type: Boolean,
      default: false,
    },
  },
});
