import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6dd9ea36"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex flex-col gap-24" }
const _hoisted_2 = { class: "flex justify-between items-center gap-24" }
const _hoisted_3 = { class: "flex flex-col gap-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppText = _resolveComponent("AppText")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_BaseCard = _resolveComponent("BaseCard")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cards, (card) => {
      return (_openBlock(), _createBlock(_component_BaseCard, {
        key: card.value,
        class: "max-w-[410px] cursor-pointer",
        onClick: ($event: any) => (_ctx.toggleSelection(card.value)),
        "data-testId": `${card.value}-card`,
        selected: card.value === _ctx.model
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_AppText, {
                size: _ctx.$appTextProps.TextSize['text-4-medium']
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t(card.title)), 1)
                ]),
                _: 2
              }, 1032, ["size"]),
              _createVNode(_component_AppText, {
                size: _ctx.$appTextProps.TextSize['text-5-regular']
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t(card.description)), 1)
                ]),
                _: 2
              }, 1032, ["size"])
            ]),
            _createVNode(_component_font_awesome_icon, {
              class: "w-40 h-40",
              icon: card.icon
            }, null, 8, ["icon"])
          ])
        ]),
        _: 2
      }, 1032, ["onClick", "data-testId", "selected"]))
    }), 128))
  ]))
}