
import { defineComponent, PropType } from 'vue';

import { Size } from './types';

export default defineComponent({
  name: 'BaseTabSelector',

  props: {
    size: {
      type: String as PropType<Size>,
      default: Size.large,
    },
    tabsList: Array as PropType<Array<string>>,
    secondaryTabsList: Array as PropType<Array<string>>,
    selectedTab: String,
    secondarySelectedTab: String,
  },

  emits: ['tabAction'],

  data() {
    return {
      currentTab: this.selectedTab,
      currentSecondaryTab: this.secondarySelectedTab,
    };
  },

  computed: {
    defaultClasses() {
      return {
        [this.textStyle]: true,
      };
    },
    textStyle() {
      switch (this.size) {
        case Size.medium:
          return 'text-5-medium';
        case Size.large:
          return 'text-4-medium';
        default:
          return 'text-4-medium';
      }
    },
  },

  watch: {
    selectedTab(val: string, oldVal: string) {
      if (val !== oldVal) {
        this.currentTab = val;
      }
    },
    secondarySelectedTab(val: string, oldVal: string) {
      if (val !== oldVal) {
        this.currentSecondaryTab = val;
      }
    },
  },

  methods: {
    handleClick(selectedTab: string, isPrimary = true) {
      if (isPrimary) {
        this.currentTab = selectedTab;
      } else {
        this.currentSecondaryTab = selectedTab;
      }
      this.$emit('tabAction', selectedTab, isPrimary);
    },
  },
});
