
import { defineComponent, PropType } from 'vue';
import { ImageResizeProps, IMAGE_SIZES } from '@Ui/ImageResize';

type StringObject = { [key: string]: string };

export default defineComponent({
  name: 'VideoDisplay',

  components: {},

  props: {
    video: {
      type: String,
      required: true,
    },

    testId: {
      type: String,
      default: 'unamed',
    },

    loading: {
      type: Boolean,
    },

    videoResizeProps: Object as PropType<ImageResizeProps>,

    width: Number,

    height: Number,
  },
  setup(props) {
    const videoWidth = IMAGE_SIZES[props.videoResizeProps?.type || 'dibbs_nft_video']?.width;
    const maxWidth = props.width || videoWidth || 100;

    return {
      maxWidth,
    };
  },
  computed: {
    videoProps() {
      const props = {
        src: this.video,
        style: [this.maxHeight],
        class: ['front '],
        height: this.height,
        width: this.width,
      };

      return props;
    },
    loadingSize(): number {
      return this.height || this.maxWidth;
    },
    maxHeight(): StringObject {
      return {
        'max-height': `${this.height || this.maxWidth}px`,
        'max-width': `${this.maxWidth}px`,
      };
    },
  },
});
