import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppText = _resolveComponent("AppText")!
  const _component_AppButton = _resolveComponent("AppButton")!

  return (_openBlock(), _createElementBlock("form", {
    class: "flex flex-col gap-24 max-w-[410px]",
    onSubmit: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$emit('close')), ["prevent"]))
  }, [
    _createVNode(_component_AppText, {
      size: _ctx.$appTextProps.TextSize['text-3-bold']
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('kyc.kyc_information_submitted.title')), 1)
      ]),
      _: 1
    }, 8, ["size"]),
    _createVNode(_component_AppText, null, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('kyc.kyc_information_submitted.descriptions.description_1')), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_AppText, null, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('kyc.kyc_information_submitted.descriptions.description_2')), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_AppText, null, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('kyc.kyc_information_submitted.descriptions.description_3')), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_AppText, null, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('kyc.kyc_information_submitted.descriptions.description_4')), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_AppButton, {
      submit: "",
      wide: "",
      label: _ctx.$t('kyc.kyc_information_submitted.actions.close'),
      size: _ctx.$appButtonProps.Size.large
    }, null, 8, ["label", "size"])
  ], 32))
}