import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/icons/google_authenticator.png'


const _hoisted_1 = { class: "flex justify-between" }
const _hoisted_2 = { class: "flex" }
const _hoisted_3 = ["alt"]
const _hoisted_4 = {
  key: 0,
  class: "fixed w-screen h-screen bg-transparent-500 left-0 top-0 mt-16 z-50"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppText = _resolveComponent("AppText")!
  const _component_ToggleButton = _resolveComponent("ToggleButton")!
  const _component_BaseLoading = _resolveComponent("BaseLoading")!
  const _component_BaseCard = _resolveComponent("BaseCard")!

  return (_openBlock(), _createBlock(_component_BaseCard, null, {
    default: _withCtx(() => [
      _createVNode(_component_AppText, {
        class: "mb-16",
        tag: "h2",
        size: _ctx.$appTextProps.TextSize['text-4-regular']
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('auth.twofa.title')), 1)
        ]),
        _: 1
      }, 8, ["size"]),
      _createVNode(_component_AppText, {
        class: "mb-24",
        size: _ctx.$appTextProps.TextSize['text-5-regular']
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('auth.twofa.description')), 1)
        ]),
        _: 1
      }, 8, ["size"]),
      _createElementVNode("section", _hoisted_1, [
        _createElementVNode("section", _hoisted_2, [
          _createElementVNode("img", {
            class: "max-w-[32px] mr-[10px]",
            src: _imports_0,
            alt: _ctx.$t('auth.twofa.options.alt')
          }, null, 8, _hoisted_3),
          _createElementVNode("div", null, [
            _createVNode(_component_AppText, {
              size: _ctx.$appTextProps.TextSize['text-5-medium']
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('auth.twofa.options.google')), 1)
              ]),
              _: 1
            }, 8, ["size"]),
            _createVNode(_component_AppText, {
              size: _ctx.$appTextProps.TextSize['text-6-regular'],
              color: "text-text-inactive"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('auth.twofa.options.description')), 1)
              ]),
              _: 1
            }, 8, ["size"])
          ])
        ]),
        _createElementVNode("div", {
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleOpenModal && _ctx.handleOpenModal(...args)))
        }, [
          _createVNode(_component_ToggleButton, {
            checked: _ctx.isTwofaEnabled,
            disabled: true
          }, null, 8, ["checked"])
        ])
      ]),
      (_ctx.isLoading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createVNode(_component_BaseLoading)
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}