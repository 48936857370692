import { apollo, DibbsApiException, DibbsErrorHelper, errorHandler } from '@/utils/helpers/api';

import TWO_FACTOR_AUTH_DISABLE_STEP_TWO from '../graphql/mutations/TwoFactorAuthDisableStepTwo.gql';

interface Result {
  twofa_disable_step2: {
    result: boolean;
  };
}

async function verify2FAEmailDisableCode(secret: string): Promise<Result['twofa_disable_step2']> {
  return apollo
    .mutate<Result>({
      mutation: TWO_FACTOR_AUTH_DISABLE_STEP_TWO,
      variables: {
        secret,
      },
    })
    .then(({ data }) => DibbsErrorHelper(data?.twofa_disable_step2))
    .then((data) => data)
    .catch((error) => {
      throw new DibbsApiException(errorHandler(error));
    });
}

export default verify2FAEmailDisableCode;
