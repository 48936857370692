import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex justify-center items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_KycStatus = _resolveComponent("KycStatus")!
  const _component_KycEntityProvideIdentity = _resolveComponent("KycEntityProvideIdentity")!
  const _component_KycInformationSubmitted = _resolveComponent("KycInformationSubmitted")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.showStatus)
      ? (_openBlock(), _createBlock(_component_KycStatus, {
          key: 0,
          bulletPoints: _ctx.bulletPoints,
          bulletPointsText: _ctx.bulletPointsText,
          currentAction: _ctx.currentAction,
          isLoading: _ctx.isLoading,
          isPageLoading: _ctx.isPageLoading,
          onOnKycAction: _ctx.handleAction
        }, null, 8, ["bulletPoints", "bulletPointsText", "currentAction", "isLoading", "isPageLoading", "onOnKycAction"]))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          (_ctx.showProvideIdentity)
            ? (_openBlock(), _createBlock(_component_KycEntityProvideIdentity, {
                key: 0,
                onDone: _ctx.handleAction,
                onHandleBackRoute: _ctx.backToStatus
              }, null, 8, ["onDone", "onHandleBackRoute"]))
            : (_ctx.showInformationSubmitted)
              ? (_openBlock(), _createBlock(_component_KycInformationSubmitted, {
                  key: 1,
                  onClose: _ctx.redirectAfterSubmit
                }, null, 8, ["onClose"]))
              : _createCommentVNode("", true)
        ], 64))
  ]))
}