import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-31852d85"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "warning-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppText = _resolveComponent("AppText")!
  const _component_BaseInput = _resolveComponent("BaseInput")!
  const _component_AppButton = _resolveComponent("AppButton")!

  return (_openBlock(), _createElementBlock("form", {
    onSubmit: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submit && _ctx.submit(...args)), ["prevent"]))
  }, [
    _createVNode(_component_AppText, {
      tag: "h1",
      size: _ctx.$appTextProps.TextSize['text-3-bold']
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('auth.twofa.disable_2fa.title')), 1)
      ]),
      _: 1
    }, 8, ["size"]),
    _createVNode(_component_AppText, { class: "mt-40" }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('auth.twofa.disable_2fa.description_email')), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_BaseInput, {
      modelValue: _ctx.secret,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.secret) = $event)),
      class: "mt-20 max-w-[360px]",
      mask: "######",
      autofocus: "",
      testId: "input-email-code",
      type: _ctx.inputProps.Type['text'],
      placeholder: _ctx.$t('auth.twofa.disable_2fa.enter_code_email')
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('auth.twofa.disable_2fa.email_code')), 1)
      ]),
      _: 1
    }, 8, ["modelValue", "type", "placeholder"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_AppText, {
        size: _ctx.$appTextProps.TextSize['text-6-medium']
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('auth.twofa.input.warning')), 1)
        ]),
        _: 1
      }, 8, ["size"])
    ]),
    _createVNode(_component_AppButton, {
      testId: "btn-disable",
      class: "mt-24 max-w-[360px]",
      submit: "",
      wide: "",
      loading: _ctx.isLoading,
      inactive: _ctx.shoudBeInactive,
      version: _ctx.$appButtonProps.Version.destructive,
      label: _ctx.$t('auth.twofa.disable_2fa.title'),
      onClick: _ctx.submit
    }, null, 8, ["loading", "inactive", "version", "label", "onClick"]),
    _createVNode(_component_AppButton, {
      testId: "btn-resendemail",
      class: "mt-24 max-w-[360px]",
      submit: "",
      wide: "",
      version: _ctx.$appButtonProps.Version.secondary,
      label: _ctx.$t('auth.twofa.disable_2fa.start_over'),
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('resendEmail')))
    }, null, 8, ["version", "label"])
  ], 32))
}