export const addSDK = async (sdkId: string, src: string) => {
  const findScript = document.querySelector(`#${sdkId}`);

  if (findScript) {
    return Promise.resolve();
  }

  const script = document.createElement('script');
  script.async = true;
  script.setAttribute('id', sdkId);
  script.setAttribute('src', src);
  document.head.appendChild(script);

  return new Promise((resolve, reject) => {
    script.onload = (result) => {
      resolve(result);
    };
    script.onerror = (error) => {
      reject(error);
    };
  });
};

export const removeSDK = (path: string) => {
  const script = document.querySelector(path);

  if (script) {
    script.remove();
    return true;
  }
};
