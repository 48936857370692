import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppText = _resolveComponent("AppText")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_AppText, {
      class: "mb-32",
      size: _ctx.$appTextProps.TextSize['text-3-regular']
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString($props.title), 1)
      ]),
      _: 1
    }, 8, ["size"]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.message, (paragraph, index) => {
      return (_openBlock(), _createBlock(_component_AppText, { key: index }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(paragraph), 1)
        ]),
        _: 2
      }, 1024))
    }), 128))
  ], 64))
}