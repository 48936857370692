import { IAddress } from '@Shared/types/address';
import { IEntity, IIndividual } from '@Shared/types/account';
import { ADDRESS_TYPE } from '@Shared/enums/addressType';
import { ACCOUNT_TYPE } from '@Shared/enums/accountType';
import { IAddressForm } from './components/KycAddressForm';

export interface IStoreKycEntityNameFields {
  individualName?: string;
  legalName?: string;
  dba?: string;
  type?: string;
  address?: Array<IAddressForm>;
}

export interface IStoreKycIndividualNameFields {
  firstName?: string;
  middleName?: string;
  lastName?: string;
  phone?: string;
  birthday?: Date;
  ssn?: string;
  address?: IAddressForm;
}

export interface IStoreKycSetEntityAddress {
  newAddress: IAddressForm;
  type?: ADDRESS_TYPE;
}

export enum KycAdditionalDocOptions {
  proofOfAddress = 'proof_of_address',
  proofOfIdentity = 'proof_of_identity',
}

export enum KycStatusActions {
  resendEmail = 'resend_mail',
  suspendedDismiss = 'suspended_dismiss',
  contactSupport = 'contact_support',
  underReviewDismiss = 'under_review_dismiss',
  provideExtraDocumentsPoa = 'provide_extra_documents_poa',
  poaPendingDismiss = 'poa_pending_dismiss',
  provideExtraDocumentsPoi = 'provide_extra_documents_poi',
  poiPendingDismiss = 'poi_pending_dismiss',
  doneDismiss = 'done_dismiss',
  provideIdentity = 'provide_identity',
  provideDocuments = 'provide_documents',
  answerDueDiligence = 'answer_due_diligence',
  resubmitIdentity = 'resubmit_identity',
  resubmitDocuments = 'resubmit_documents',
  resubmitDueDiligence = 'resubmit_due_diligence',
}

export const KycTypes = {
  KycStatusActions,
  KycAdditionalDocOptions,
};

export enum KycStatusOptions {
  pending = 'pending',
  ongoing = 'ongoing',
  accept = 'accept',
  frozen = 'frozen',
  contactCustomerSupport = 'contact_customer_support',
  queue = 'queue',
  requestPoa = 'request_poa',
  poaReviewPending = 'poa_review_pending',
  requestPersonalId = 'request_personal_id',
  personalIdReviewPending = 'personal_id_review_pending',
}

export enum KycStep {
  signup = 'signup',
  piiSsn = 'pii_ssn',
  docv = 'docv',
  ddQuestions = 'dd_questions',
}

export interface KycStepInfo {
  step: KycStep | null;
  status: KycStatusOptions;
}

export enum KycStepsAction {
  name = 'name',
  address = 'address',
  date_of_birth = 'date_of_birth',
  ssn = 'ssn',
  phone = 'phone',
  docv = 'docv',
}

export enum KycStepStatus {
  accept = 'accept',
  pending = 'pending',
  resubmit = 'resubmit',
}

export interface IKycPii {
  address: Array<IAddress>;
  individual: IIndividual;
  entity: IEntity;
}

export interface IKycStep {
  step: KycStep;
  next_step?: KycStep;
  status: KycStepStatus;
}

export interface IKycState {
  id: string;
  status: KycStatusOptions;
  current_step: IKycStep;
  contact_customer_support_reason?: string;
}

export interface IKycInfo {
  account: IAccount;
  email_verified: boolean;
  kyc_pii?: IKycPii;
  kyc_state?: IKycState;
}

export interface IAccount {
  type: ACCOUNT_TYPE;
}

export interface IEntityAttemptLevelUp {
  individual_name?: string;
  legal_name?: string;
  dba?: string;
  type?: string;
  address?: Array<IAddress>;
}

export interface IIndividualAttemptLevelUp {
  first_name?: string;
  middle_name?: string;
  sur_name?: string;
  address?: IAddress;
  phone?: string;
  birthday?: string;
  ssn?: string;
  docv?: string;
  device_session_id?: string;
}
