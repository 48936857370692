import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4de0ea2a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "w-full" }
const _hoisted_2 = { class: "warning-container" }
const _hoisted_3 = { class: "w-full lg:w-[360px]" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppText = _resolveComponent("AppText")!
  const _component_AppButton = _resolveComponent("AppButton")!

  return (_openBlock(), _createElementBlock("form", {
    onSubmit: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.$emit('continue')), ["prevent"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_AppText, {
        tag: "h1",
        size: _ctx.$appTextProps.TextSize['text-3-bold']
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('auth.twofa.backup_code.title')), 1)
        ]),
        _: 1
      }, 8, ["size"]),
      _createVNode(_component_AppText, { class: "mt-40" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('auth.twofa.backup_code.description')), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_AppText, {
        class: "my-24",
        size: _ctx.$appTextProps.TextSize['text-4-medium']
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.recovery), 1)
        ]),
        _: 1
      }, 8, ["size"]),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_AppText, {
          size: _ctx.$appTextProps.TextSize['text-6-medium']
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('auth.twofa.backup_code.warning')), 1)
          ]),
          _: 1
        }, 8, ["size"])
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_AppButton, {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('continue'))),
        label: _ctx.$t('auth.twofa.actions.continue'),
        testId: "btn-continue",
        wide: ""
      }, null, 8, ["label"]),
      _createVNode(_component_AppButton, {
        onClick: _ctx.copyAction,
        label: _ctx.$t('auth.twofa.actions.copy_clipboard'),
        testId: "btn-copy",
        class: "mt-16",
        version: _ctx.$appButtonProps.Version.secondary,
        wide: ""
      }, null, 8, ["onClick", "label", "version"])
    ])
  ], 32))
}