enum Size {
  medium = 'medium',
  large = 'large',
}

const tabSelectorProps = {
  Size,
};

export { tabSelectorProps, Size };
