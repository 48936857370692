import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-00e14b36"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["data-testid", "placeholder", "disabled"]
const _hoisted_2 = ["onClick"]
const _hoisted_3 = {
  key: 0,
  class: "px-12 py-8"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["relative", {
      'pointer-events-none': _ctx.disabled,
    }])
  }, [
    (_ctx.isOpen)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "list-overlay",
          "data-testid": "list-overlay",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeList && _ctx.closeList(...args)))
        }))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.containerClasses),
      onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.openList && _ctx.openList(...args)))
    }, [
      _withDirectives(_createElementVNode("input", {
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.input) = $event)),
        "data-lpignore": "true",
        ref: "inputRef",
        "data-testid": `${_ctx.testId}__selected-item`,
        placeholder: _ctx.placeholder || _ctx.$t('base_select.placeholder'),
        disabled: _ctx.disabled,
        onInput: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.filter && _ctx.filter(...args))),
        onFocus: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.openList && _ctx.openList(...args))),
        onKeydown: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.handleKeyboardActions && _ctx.handleKeyboardActions(...args)))
      }, null, 40, _hoisted_1), [
        [_vModelText, _ctx.input]
      ])
    ], 2),
    (_ctx.isOpen)
      ? (_openBlock(), _createElementBlock("ul", {
          key: 1,
          class: "list-container",
          ref: "scrollContainer",
          style: _normalizeStyle({ maxHeight: _ctx.maxHeight })
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredList, (item, index) => {
            return (_openBlock(), _createElementBlock("li", { key: index }, [
              _createElementVNode("div", {
                ref_for: true,
                ref: "optionsRef",
                class: _normalizeClass(`list-item ${_ctx.focusedItem === index ? 'is-focused' : ''}`),
                onClick: ($event: any) => (_ctx.selectItem(item))
              }, _toDisplayString(item.label), 11, _hoisted_2)
            ]))
          }), 128)),
          (!_ctx.filteredList.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.$t('base_select.empty_list')), 1))
            : _createCommentVNode("", true)
        ], 4))
      : _createCommentVNode("", true)
  ], 2))
}