export default [
  {
    path: '/disable-2fa',
    name: 'disable-2fa',
    component: () => import('@/views/TwofaView/DisableTwofaView.vue'),
  },
  {
    path: '/setup-2fa',
    name: 'setup-2fa',
    component: () => import('@/views/TwofaView/EnableTwofaView.vue'),
    props: true,
  },
  {
    path: '/reset-2fa',
    name: 'reset-2fa',
    component: () => import('@/views/TwofaView/ResetTwofaView.vue'),
    meta: {
      isPublic: true,
    },
  },
];
