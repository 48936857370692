import { defineStore } from 'pinia';

import { IAddress } from '@Shared/types/address';
import { ADDRESS_TYPE } from '@Shared/enums/addressType';
import { IDueDiligenceQuestion } from './components/individual/KycDueDiligenceQuestion/types';

import {
  IEntityAttemptLevelUp,
  IIndividualAttemptLevelUp,
  IStoreKycEntityNameFields,
  IStoreKycIndividualNameFields,
  IStoreKycSetEntityAddress,
  KycStatusActions,
  IKycInfo,
  KycStatusOptions,
  KycStep,
  KycStepStatus,
} from './types';
import { formatAddress } from '@/utils';
import { IAddressForm } from './components/KycAddressForm';

export interface State {
  currentOperatorKycInfo?: IKycInfo;
  dueDiligenceQuestion: IDueDiligenceQuestion | null;
  entity: {
    nameFieldsForm?: IStoreKycEntityNameFields;
    incorporatedAddressForm?: IAddressForm;
    principalAddressForm?: IAddressForm;
  };
  individual: {
    nameFieldsForm?: IStoreKycIndividualNameFields;
    addressForm?: IAddressForm;
  };
}

export const useKycStore = defineStore('kyc', {
  state: (): State => ({
    currentOperatorKycInfo: undefined,
    dueDiligenceQuestion: null,
    entity: {},
    individual: {},
  }),

  actions: {
    setCurrentOperatorKycInfo(kycInfo: IKycInfo) {
      this.currentOperatorKycInfo = kycInfo;
    },

    setCurrentOperatorKycState(kycState: IKycInfo['kyc_state']) {
      if (this.currentOperatorKycInfo) {
        this.currentOperatorKycInfo = {
          ...this.currentOperatorKycInfo,
          kyc_state: kycState,
        };
      }
    },

    setDueDiligenceQuestion(nextQuestion: IDueDiligenceQuestion | null) {
      this.dueDiligenceQuestion = nextQuestion;
    },

    setEntityNameFields(newNameFields: IStoreKycEntityNameFields) {
      this.entity.nameFieldsForm = {
        ...this.entity.nameFieldsForm,
        ...newNameFields,
      };
    },

    setEntityAddresses({ newAddress, type }: IStoreKycSetEntityAddress) {
      if (type === ADDRESS_TYPE.INCORPORATED) {
        this.entity.incorporatedAddressForm = {
          ...this.entity.incorporatedAddressForm,
          ...newAddress,
        };
      }
      if (type === ADDRESS_TYPE.PRINCIPAL) {
        this.entity.principalAddressForm = {
          ...this.entity.principalAddressForm,
          ...newAddress,
        };
      }
    },

    setIndividualAddress(newAddress: IAddressForm) {
      this.individual.addressForm = {
        ...this.individual.addressForm,
        ...newAddress,
      };
    },

    setIndividualNameFields(newNameFields: IStoreKycIndividualNameFields) {
      this.individual.nameFieldsForm = {
        ...this.individual.nameFieldsForm,
        ...newNameFields,
      };
    },
  },

  getters: {
    attemptLevelUpParamsEntity(state): IEntityAttemptLevelUp {
      const nameFieldsForm = state.entity.nameFieldsForm;
      const incorporatedAddressForm = state.entity.incorporatedAddressForm;
      const principalAddressForm = state.entity.principalAddressForm;
      const addresses: Array<IAddress> = [];

      if (principalAddressForm) {
        const address: IAddress = {
          type: ADDRESS_TYPE.PRINCIPAL,
          address_street: principalAddressForm.addressStreet,
          address_city: principalAddressForm.addressCity,
          address_state: principalAddressForm.addressState,
          address_complement: principalAddressForm.addressComplement,
          address_zip: principalAddressForm.addressZip,
          address_country: principalAddressForm.addressCountry,
        };

        addresses.push(address);
      }

      if (incorporatedAddressForm) {
        const address: IAddress = {
          type: ADDRESS_TYPE.INCORPORATED,
          address_street: incorporatedAddressForm.addressStreet,
          address_city: incorporatedAddressForm.addressCity,
          address_state: incorporatedAddressForm.addressState,
          address_complement: incorporatedAddressForm.addressComplement,
          address_zip: incorporatedAddressForm.addressZip,
          address_country: incorporatedAddressForm.addressCountry,
        };
        addresses.push(address);
      }

      const attemptLevelUpParams: IEntityAttemptLevelUp = {
        individual_name: nameFieldsForm?.individualName,
        legal_name: nameFieldsForm?.legalName,
        dba: nameFieldsForm?.dba,
        type: nameFieldsForm?.type,
        address: addresses,
      };

      return attemptLevelUpParams;
    },

    currentAction(state): KycStatusActions {
      // Match email verified first, as it is out of the kyc-related objects
      if (!state.currentOperatorKycInfo?.email_verified) {
        return KycStatusActions.resendEmail;
      }

      // Check for any actions that are not related to ongoing kyc
      const kycStateStatus = state.currentOperatorKycInfo?.kyc_state?.status;

      switch (kycStateStatus) {
        case KycStatusOptions.frozen:
          return KycStatusActions.suspendedDismiss;
        case KycStatusOptions.contactCustomerSupport:
          return KycStatusActions.contactSupport;
        case KycStatusOptions.queue:
          return KycStatusActions.underReviewDismiss;
        case KycStatusOptions.requestPoa:
          return KycStatusActions.provideExtraDocumentsPoa;
        case KycStatusOptions.poaReviewPending:
          return KycStatusActions.poaPendingDismiss;
        case KycStatusOptions.requestPersonalId:
          return KycStatusActions.provideExtraDocumentsPoi;
        case KycStatusOptions.personalIdReviewPending:
          return KycStatusActions.poiPendingDismiss;
      }

      // Check for kyc ongoing actions
      const kycStateCurrentStep = state.currentOperatorKycInfo?.kyc_state?.current_step;
      const kycIsInProgress =
        kycStateStatus === KycStatusOptions.ongoing || kycStateStatus === KycStatusOptions.pending;
      if (kycIsInProgress && kycStateCurrentStep?.status === KycStepStatus.accept) {
        switch (kycStateCurrentStep.next_step) {
          case KycStep.piiSsn:
            return KycStatusActions.provideIdentity;
          case KycStep.docv:
            return KycStatusActions.provideDocuments;
          case KycStep.ddQuestions:
            return KycStatusActions.answerDueDiligence;
        }
      }

      if (kycIsInProgress && kycStateCurrentStep?.status === KycStepStatus.resubmit) {
        switch (kycStateCurrentStep.step) {
          case KycStep.piiSsn:
            return KycStatusActions.resubmitIdentity;
          case KycStep.docv:
            return KycStatusActions.resubmitDocuments;
          case KycStep.ddQuestions:
            return KycStatusActions.resubmitDueDiligence;
        }
      }

      // Fallback to doneDismiss just to be safe
      return KycStatusActions.doneDismiss;
    },

    individualAttemptLevelUpParams(state): IIndividualAttemptLevelUp {
      const nameFieldsForm = state.individual.nameFieldsForm;
      const addressForm = state.individual.addressForm;

      const birthday = nameFieldsForm?.birthday;
      let adjustedBirthday = undefined;
      if (birthday) {
        const year = birthday.getFullYear();
        const monthIndex = birthday.getMonth() ?? 0;
        const month = (monthIndex + 1).toString().padStart(2, '0');
        const day = birthday.getDate().toString().padStart(2, '0');

        adjustedBirthday = `${year}-${month}-${day}`;
      }
      const adjustedPhone = `+1${(nameFieldsForm?.phone || '').replace(/\D/g, '')}`;

      const address: IAddress = {
        address_city: addressForm?.addressCity,
        address_state: addressForm?.addressState,
        address_country: addressForm?.addressCountry,
        address_street: addressForm?.addressStreet,
        address_complement: addressForm?.addressComplement,
        address_zip: addressForm?.addressZip,
        type: ADDRESS_TYPE.PRINCIPAL,
      };

      const attemptLevelUpParams: IIndividualAttemptLevelUp = {
        first_name: nameFieldsForm?.firstName,
        middle_name: nameFieldsForm?.middleName,
        sur_name: nameFieldsForm?.lastName,
        address: address,
        phone: adjustedPhone,
        birthday: adjustedBirthday,
        ssn: nameFieldsForm?.ssn,
      };

      return attemptLevelUpParams;
    },

    readableAddress(state) {
      const address: IAddress = {
        address_city: state.individual.addressForm?.addressCity,
        address_state: state.individual.addressForm?.addressState,
        address_country: state.individual.addressForm?.addressCountry,
        address_street: state.individual.addressForm?.addressStreet,
        address_complement: state.individual.addressForm?.addressComplement,
        address_zip: state.individual.addressForm?.addressZip,
      };

      return formatAddress(address);
    },
  },
});
