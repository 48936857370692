
import { defineComponent } from 'vue';
import VueQrcode from '@chenfengyuan/vue-qrcode';

export default defineComponent({
  name: 'EnableTwoFactorAuthentication',

  components: {
    VueQrcode,
  },

  emits: ['continue', 'addManually', 'error'],

  props: {
    otpauthUrl: {
      type: String,
      required: true,
    },
  },

  mounted() {
    if (this.otpauthUrl) {
      this.$emit('error');
    }
  },
});
