interface Loading {
  active: boolean;
  height?: string | number;
  width?: string | number;
}

enum TextPositionEnum {
  left = 'text-left',
  right = 'text-right',
  center = 'text-center',
  justify = 'text-justify',
}

type TextSize = keyof typeof TextSizeEnum | TextSizeEnum;
type TextPosition = keyof typeof TextPositionEnum | TextPositionEnum;

enum TextSizeEnum {
  'text-1' = 'text-1',
  'text-2' = 'text-2',
  'text-3-regular' = 'text-3-regular',
  'text-3-medium' = 'text-3-medium',
  'text-3-bold' = 'text-3-bold',
  'text-4-regular' = 'text-4-regular',
  'text-4-medium' = 'text-4-medium',
  'text-4-bold' = 'text-4-bold',
  'text-5-regular' = 'text-5-regular',
  'text-5-medium' = 'text-5-medium',
  'text-5-bold' = 'text-5-bold',
  'text-6-regular' = 'text-6-regular',
  'text-6-medium' = 'text-6-medium',
  'text-6-bold' = 'text-6-bold',
}

const appTextProps = {
  TextPosition: TextPositionEnum,
  TextSize: TextSizeEnum,
};

export { appTextProps, Loading, TextPosition, TextSize };
