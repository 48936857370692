import { defineStore } from 'pinia';
import { CryptoAccount, DibbsNft, IRedemptionItem, RedeemStatus } from '@Modules/Crypto';
import { StorageSerializers, useLocalStorage } from '@vueuse/core';
import { ref, Ref } from 'vue';

const useLocal = <T>(id: string, defaultValue: T): Ref<T> => {
  const options = { serializer: StorageSerializers.object };
  return useLocalStorage(id, defaultValue, options);
};

export interface State {
  currentAccount: Ref<CryptoAccount | null>;
  dibbsNftsLoaded: Ref<boolean>;
  dibbsNftList?: Ref<DibbsNft[] | null>;
  dibbsNftListByTokenId?: Ref<{ [key: string | number]: DibbsNft }>;
  redemptionList: Ref<IRedemptionItem[] | null>;
}

export const useWalletStore = defineStore('crypto', {
  state: (): State => ({
    currentAccount: useLocal('currentAccount', null),
    dibbsNftsLoaded: ref(false),
    dibbsNftList: useLocal('dibbsNftList', []),
    dibbsNftListByTokenId: useLocal('dibbsNftListByTokens', {}),
    redemptionList: ref([]),
  }),
  actions: {
    setAccountInfos(accountInfo: CryptoAccount) {
      this.currentAccount = accountInfo;
    },

    setDibbsNftList(dibbsNftList: DibbsNft[]) {
      this.dibbsNftList = dibbsNftList;
      const tokens: { [key: string | number]: DibbsNft } = {};
      dibbsNftList.forEach((nft) => {
        tokens[nft.tokenId] = nft;
      });
      this.dibbsNftListByTokenId = tokens;
    },

    getNftByTokenId(tokenId: string): DibbsNft | null {
      const dibbsNft = this.dibbsNftListByTokenId && this.dibbsNftListByTokenId[tokenId];
      return dibbsNft || null;
    },

    setRedemptionList(redemptionList: IRedemptionItem[]) {
      this.redemptionList = redemptionList;
    },

    checkNftHasRedemption(tokenId: string): boolean {
      const filteredRedemptiom = this.redemptionList?.filter(
        (redemption) => redemption.token_id === tokenId
      );

      return !!filteredRedemptiom && filteredRedemptiom.length > 0;
    },

    disconnectWallet() {
      this.currentAccount = null;
      this.dibbsNftList = [];
      this.dibbsNftListByTokenId = {};
    },
  },
  getters: {
    isWalletConnected(state) {
      const user = state.currentAccount;
      return !!(user && user.address);
    },
    dibbsNftFilteredByRedemption(state) {
      return state.dibbsNftList?.filter((nft) => {
        const hasRedemption = state.redemptionList?.some(
          (redemption) =>
            redemption.token_id == nft.tokenId && redemption.status !== RedeemStatus.cancelled
        );

        return !hasRedemption;
      });
    },
  },
});
