
import { defineComponent, PropType } from 'vue';

import CountryFlag from 'vue-country-flag-next';

import IconWorld from '@/assets/icons/world.svg';

import KycCountrySelector from '@Modules/Kyc/components/KycCountrySelector/KycCountrySelector.vue';
import KycCountryUnavailable from '@Modules/Kyc/components/KycCountryUnavailable/KycCountryUnavailable.vue';

import countries from './countries.json';
import { AVAILABLE_STEPS, IKycCountryData } from './types';

export default defineComponent({
  name: 'KycCountry',

  components: {
    CountryFlag,
    IconWorld,
    KycCountrySelector,
    KycCountryUnavailable,
  },

  props: {
    availableCountries: {
      type: Array as PropType<Array<string>>,
      required: true,
    },
  },

  emits: {
    setCountry: (country: string) => country,
  },

  data(): IKycCountryData {
    return {
      AVAILABLE_STEPS,
      countries,
      selectedCountry: 'US',
      step: 'index',
    };
  },

  computed: {
    selectText(): string {
      return countries.find((item) => item.code === this.selectedCountry)?.name || '';
    },
  },

  methods: {
    backToHome() {
      this.$router.push('/your-items');
    },

    chooseAnotherCountry() {
      this.step = this.AVAILABLE_STEPS.SELECTOR;
    },

    next() {
      const isValidCountry = this.availableCountries.includes(this.selectedCountry);

      if (isValidCountry) {
        this.$emit('setCountry', this.selectedCountry);
      } else {
        this.step = this.AVAILABLE_STEPS.UNAVAILABLE;
      }
    },

    openSelector() {
      this.step = this.AVAILABLE_STEPS.SELECTOR;
    },

    selectCountry(newValue: string) {
      this.selectedCountry = newValue;
      this.step = this.AVAILABLE_STEPS.INDEX;
    },
  },
});
