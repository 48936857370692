import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputPassword = _resolveComponent("InputPassword")!
  const _component_PasswordValidationMessage = _resolveComponent("PasswordValidationMessage")!
  const _component_AppButton = _resolveComponent("AppButton")!

  return (_openBlock(), _createElementBlock("form", {
    onSubmit: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.updatePassword && _ctx.updatePassword(...args)), ["prevent"]))
  }, [
    _createVNode(_component_InputPassword, {
      label: _ctx.$t('auth.reset_password.form.password.label'),
      placeholder: _ctx.$t('auth.reset_password.form.password.placeholder'),
      input: _ctx.newPassword,
      onUpdate: _cache[0] || (_cache[0] = (newValue) => (_ctx.newPassword = newValue, _ctx.validatePassword(newValue)))
    }, null, 8, ["label", "placeholder", "input"]),
    _createVNode(_component_InputPassword, {
      label: _ctx.$t('auth.reset_password.form.confirm_password.label'),
      placeholder: _ctx.$t('auth.reset_password.form.confirm_password.placeholder'),
      input: _ctx.confirmPassword,
      onUpdate: _cache[1] || (_cache[1] = (newValue) => (_ctx.confirmPassword = newValue))
    }, null, 8, ["label", "placeholder", "input"]),
    _createVNode(_component_PasswordValidationMessage, {
      conditions: _ctx.passwordConditions,
      class: "mb-16"
    }, null, 8, ["conditions"]),
    _createVNode(_component_AppButton, {
      submit: "",
      wide: "",
      class: "w-full",
      inactive: !_ctx.isValidPassword,
      loading: _ctx.loading,
      size: _ctx.$appButtonProps.Size.medium,
      label: _ctx.$t('auth.reset_password.form.actions.submit')
    }, null, 8, ["inactive", "loading", "size", "label"])
  ], 32))
}