
import { defineComponent } from 'vue';
import { ITwoFactorResetPayload } from '../../types';
import { TwoFactorInputModal, TwoFactorResetModal } from './components';

export default defineComponent({
  name: 'TwoFactorAuthModal',

  components: {
    TwoFactorInputModal,
    TwoFactorResetModal,
  },

  emits: {
    onTwoFactorInputClose: () => true,

    onForgotTwofa: () => true,

    onTwoFactorInputSubmit: (code: string) => code,

    onTwoFactorResetClose: () => true,

    onTwoFactorResetSubmit: (payload: ITwoFactorResetPayload) => payload,
  },

  methods: {
    onSubmitInput(code: string) {
      this.$emit('onTwoFactorInputSubmit', code);
    },

    onSubmitReset(payload: ITwoFactorResetPayload) {
      this.$emit('onTwoFactorResetSubmit', payload);
    },
  },
});
