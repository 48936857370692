import BigNumber from 'bignumber.js';

const bigNumberToString = (bigNumber: BigNumber) => {
  return bigNumber.toString();
};

const bigNumberToNumber = (bigNumber: BigNumber) => {
  return bigNumber.toNumber();
};

export { BigNumber, bigNumberToString, bigNumberToNumber };
