import { CryptoNetwork } from '@Modules/Crypto';
export const isValidNetwork = async (chainId: number) => {
  const permitedNetworks = [
    CryptoNetwork.ethereum,
    CryptoNetwork.goerli,
    CryptoNetwork.mumbai,
    CryptoNetwork.polygon,
  ];
  return chainId && permitedNetworks.includes(chainId);
};
