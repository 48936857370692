import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-041e1fdc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex flex-col mt-24 gap-24" }
const _hoisted_2 = { class: "country-button border-border-active-nav" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppText = _resolveComponent("AppText")!
  const _component_CountryFlag = _resolveComponent("CountryFlag")!
  const _component_IconWorld = _resolveComponent("IconWorld")!
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_KycCountrySelector = _resolveComponent("KycCountrySelector")!
  const _component_KycCountryUnavailable = _resolveComponent("KycCountryUnavailable")!

  return (_ctx.step === _ctx.AVAILABLE_STEPS.INDEX)
    ? (_openBlock(), _createElementBlock("form", {
        key: 0,
        class: "max-w-[410px]",
        onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.next && _ctx.next(...args)), ["prevent"]))
      }, [
        _createVNode(_component_AppText, {
          size: _ctx.$appTextProps.TextSize['text-3-bold']
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('kyc.kyc_country.title')), 1)
          ]),
          _: 1
        }, 8, ["size"]),
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_AppText, {
              "data-cy": "text-current-country",
              "data-testid": "text-current-country",
              size: _ctx.$appTextProps.TextSize['text-5-bold']
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.selectText), 1)
              ]),
              _: 1
            }, 8, ["size"]),
            _createVNode(_component_CountryFlag, {
              rounded: "",
              size: "big",
              country: _ctx.selectedCountry
            }, null, 8, ["country"])
          ]),
          _createElementVNode("button", {
            class: "country-button",
            "data-cy": "button-all-countries",
            "data-testid": "button-all-countries",
            type: "button",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openSelector && _ctx.openSelector(...args)))
          }, [
            _createVNode(_component_AppText, {
              size: _ctx.$appTextProps.TextSize['text-5-bold']
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('kyc.kyc_country.actions.all_other_countries')), 1)
              ]),
              _: 1
            }, 8, ["size"]),
            _createVNode(_component_IconWorld)
          ])
        ]),
        _createVNode(_component_AppButton, {
          submit: "",
          wide: "",
          inactive: _ctx.availableCountries.length <= 0,
          class: "mt-32",
          testId: "button-next",
          label: _ctx.$t('kyc.kyc_country.actions.next')
        }, null, 8, ["inactive", "label"])
      ], 32))
    : (_ctx.step === _ctx.AVAILABLE_STEPS.SELECTOR)
      ? (_openBlock(), _createBlock(_component_KycCountrySelector, {
          key: 1,
          class: "max-w-[410px]",
          "current-country": _ctx.selectedCountry,
          onSelected: _ctx.selectCountry
        }, null, 8, ["current-country", "onSelected"]))
      : (_ctx.step === _ctx.AVAILABLE_STEPS.UNAVAILABLE)
        ? (_openBlock(), _createBlock(_component_KycCountryUnavailable, {
            key: 2,
            class: "max-w-[410px]",
            onBackToHome: _ctx.backToHome,
            onChooseAnotherCountry: _ctx.chooseAnotherCountry
          }, null, 8, ["onBackToHome", "onChooseAnotherCountry"]))
        : _createCommentVNode("", true)
}