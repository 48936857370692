import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-666ecc73"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "flex justify-center items-center"
}
const _hoisted_2 = ["capture"]
const _hoisted_3 = {
  key: 2,
  class: "flex justify-center items-center"
}
const _hoisted_4 = { class: "image-preview" }
const _hoisted_5 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppText = _resolveComponent("AppText")!
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_BaseIcon = _resolveComponent("BaseIcon")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.label)
      ? (_openBlock(), _createBlock(_component_AppText, {
          key: 0,
          class: "mb-12",
          size: _ctx.$appTextProps.TextSize['text-5-medium']
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.label), 1)
          ]),
          _: 1
        }, 8, ["size"]))
      : _createCommentVNode("", true),
    (!_ctx.selectedImage)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("input", {
            id: "uploadedDocument",
            ref: "uploadedDocument",
            name: "uploadedDocument",
            "data-lpignore": "true",
            class: "hidden",
            type: "file",
            accept: "image/*",
            capture: _ctx.preferCamera ? 'environment' : undefined,
            onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleSelectedImage && _ctx.handleSelectedImage(...args))),
            role: "input"
          }, null, 40, _hoisted_2),
          _createVNode(_component_AppButton, {
            label: _ctx.$t('image_upload.upload_image'),
            wide: "",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.requestImageFromUserDevice(false))),
            size: _ctx.$appButtonProps.Size.medium
          }, null, 8, ["label", "size"]),
          _createVNode(_component_AppButton, {
            class: "ml-24 lg:hidden",
            label: _ctx.$t('image_upload.take_photo'),
            version: _ctx.$appButtonProps.Version.secondary,
            wide: "",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.requestImageFromUserDevice(true))),
            size: _ctx.$appButtonProps.Size.medium
          }, null, 8, ["label", "version", "size"])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("img", {
              src: _ctx.imageSource,
              alt: "Selected image"
            }, null, 8, _hoisted_5),
            _createVNode(_component_BaseIcon, {
              icon: _ctx.AVAILABLE_ICONS['check-circle-far'],
              class: "image-preview__valid-icon"
            }, null, 8, ["icon"])
          ]),
          _createVNode(_component_AppButton, {
            class: "ml-24",
            label: _ctx.$t('image_upload.change_image'),
            version: _ctx.$appButtonProps.Version.secondary,
            onClick: _ctx.reset,
            size: _ctx.$appButtonProps.Size.medium
          }, null, 8, ["label", "version", "onClick", "size"])
        ]))
  ]))
}