import { appToastProps, persistToast } from '@/plugins/components/AppToast';
import { useGlobalToast } from '@/utils/composables';
import {
  DibbsNft,
  getRedemptionData,
  getRedemptionList,
  IRedemptionItem,
  useWalletStore,
} from '@Modules/Crypto';
import { storeToRefs } from 'pinia';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';

export default function useLoadRedemption() {
  const router = useRouter();
  const { t } = useI18n();
  const { showError } = useGlobalToast();

  const isLoading = ref(true);

  const nft = ref<DibbsNft | null>(null);
  const currentRedemption = ref<IRedemptionItem>();
  const { redemptionList } = storeToRefs(useWalletStore());

  function redirectToYourItems() {
    router.push('/your-items');
  }

  function redirectToYourItemsError(message?: string) {
    persistToast({
      open: true,
      dismissable: true,
      message: message || t('errors.admin_generic_error'),
      type: appToastProps.Variations.ERROR,
    });

    router.push('/your-items');
  }

  function redirectToYourItemsSuccess(message: string) {
    persistToast({
      open: true,
      dismissable: true,
      message: message,
      type: appToastProps.Variations.SUCCESS,
    });

    router.push('/your-items');
  }

  async function onPageLoad(redemptionId: string) {
    if (!redemptionId) {
      return redirectToYourItemsError();
    }

    try {
      const redemption = await getRedemptionData({ redemptionId });

      if (!redemption) {
        return redirectToYourItemsError();
      }

      currentRedemption.value = redemption;
      return currentRedemption.value;
    } catch (error) {
      return redirectToYourItemsError();
    } finally {
      isLoading.value = false;
    }
  }

  async function loadRedemptions() {
    try {
      redemptionList.value = await getRedemptionList();
    } catch (error) {
      showError();
    }
  }

  return {
    redirectToYourItems,
    redirectToYourItemsError,
    redirectToYourItemsSuccess,
    onPageLoad,
    isLoading,
    nft,
    currentRedemption,
    loadRedemptions,
    redemptionList,
  };
}
