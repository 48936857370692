import { apollo, DibbsApiException, DibbsErrorHelper, errorHandler } from '@/utils/helpers/api';

import BURN_COSIGNATURE from '../graphql/mutations/BurnCosignature.gql';

interface Params {
  redemptionId: string;
  walletAddress: string;
}

interface Result {
  burn_cosignature: {
    signature: string;
    expirationBlock: number;
  };
}

export async function getBurnCosignature({
  redemptionId,
  walletAddress,
}: Params): Promise<Result['burn_cosignature']> {
  return apollo
    .mutate<Result>({
      mutation: BURN_COSIGNATURE,
      variables: {
        redemptionId,
        walletAddress,
      },
    })
    .then(({ data }) => DibbsErrorHelper(data?.burn_cosignature))
    .catch((error) => {
      throw new DibbsApiException(errorHandler(error));
    });
}
