export default [
  {
    path: '/registry',
    name: 'registry',
    component: () => import('@/views/RegistryView/RegistryView.vue'),
    meta: {
      isPublic: true,
    },
  },
];
