import { createElementVNode as _createElementVNode, vShow as _vShow, normalizeStyle as _normalizeStyle, withDirectives as _withDirectives, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-004efaaf"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["data-testid"]
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppSkeletonLoading = _resolveComponent("AppSkeletonLoading")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives(_createElementVNode("video", {
      class: "video",
      autoplay: "",
      loop: "",
      muted: "",
      ref: "videoDisplay",
      "data-testid": _ctx.testId,
      style: _normalizeStyle([_ctx.maxHeight])
    }, [
      _createElementVNode("source", {
        type: "video/mp4",
        src: _ctx.videoProps.src
      }, null, 8, _hoisted_2)
    ], 12, _hoisted_1), [
      [_vShow, !_ctx.loading]
    ]),
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_AppSkeletonLoading, {
          key: 0,
          width: _ctx.loadingSize,
          height: _ctx.loadingSize
        }, null, 8, ["width", "height"]))
      : _createCommentVNode("", true)
  ], 64))
}