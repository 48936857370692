
import { defineComponent } from 'vue';
import { AVAILABLE_ICONS } from '@Libraries/font-awesome';
import { BaseInput } from '@BaseUi';
import { InfoContainer } from '@Ui/InfoContainer';
import { applySSNMask } from './ssnMask';

export default defineComponent({
  name: 'KycSSNForm',

  components: {
    BaseInput,
    InfoContainer,
  },

  props: {
    isLoading: Boolean,
  },

  emits: {
    setSSN: (ssn: string) => ssn,
  },

  data() {
    return {
      AVAILABLE_ICONS,
      form: {
        ssn: '',
      },
    };
  },

  computed: {
    isValidSSN(): boolean {
      return /^(?!666|000|9\d{2})\d{3}(?!00)\d{2}(?!0{4})\d{4}$/.test(this.ssnNumbers);
    },

    ssnNumbers(): string {
      return String(this.form.ssn).replace(/\D+/g, '');
    },
  },

  methods: {
    handleInputChange() {
      const onlyNumbers = this.ssnNumbers.substring(0, 9);

      if (onlyNumbers.length <= 9) {
        this.form.ssn = applySSNMask(onlyNumbers);
      }
    },

    onSubmit() {
      this.$emit('setSSN', this.ssnNumbers);
    },
  },
});
