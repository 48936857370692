import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-cc3d7774"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "button-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppText = _resolveComponent("AppText")!
  const _component_AppButton = _resolveComponent("AppButton")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_AppText, {
      class: "mt-32",
      size: _ctx.$appTextProps.TextSize['text-5-regular']
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('crypto.connect_to_wallet')), 1)
      ]),
      _: 1
    }, 8, ["size"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_AppButton, {
        class: "mt-32",
        "test-id": "connect-to-wallet-button",
        size: _ctx.$appButtonProps.Size.medium,
        label: _ctx.$t('crypto.connect_metamask'),
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('connectWallet')))
      }, null, 8, ["size", "label"])
    ])
  ]))
}