
import { defineComponent, PropType } from 'vue';
import { Shapes } from './types';

export default defineComponent({
  name: 'AppSkeletonLoading',

  props: {
    borderRadius: {
      type: String,
      default: '4px',
    },

    height: {
      type: [String, Number],
      default: '100%',
    },

    type: {
      type: String as PropType<Shapes>,
      default: Shapes.rectangle,
    },

    width: {
      type: [String, Number],
      default: '100%',
    },
  },

  computed: {
    componentStyle() {
      return {
        borderRadius: this.type === 'circle' ? '100%' : this.borderRadius,
        height: this.currentHeight,
        width: this.type === 'circle' ? this.currentHeight : this.currentWidth,
      };
    },

    currentHeight() {
      return typeof this.height === 'string' ? this.height : `${this.height}px`;
    },

    currentWidth() {
      return typeof this.width === 'string' ? this.width : `${this.width}px`;
    },
  },
});
