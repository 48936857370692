import { apollo, DibbsApiException, DibbsErrorHelper, errorHandler } from '@/utils/helpers/api';

import { IEntityAttemptLevelUp, IKycState } from '../types';
import ENTITYATTEMPT_LEVEL_UP from '../graphql/mutations/EntityAttemptLevelUp.gql';

interface Result {
  attempt_level_up: IKycState;
}

async function entityAttemptLevelUp(variables: IEntityAttemptLevelUp): Promise<IKycState | void> {
  return apollo
    .mutate<Result>({
      mutation: ENTITYATTEMPT_LEVEL_UP,
      variables: { ...variables },
    })
    .then(({ data }) => (data ? DibbsErrorHelper(data.attempt_level_up) : undefined))
    .catch((error) => {
      throw new DibbsApiException(errorHandler(error));
    });
}

export default entityAttemptLevelUp;
