
import { defineComponent } from 'vue';

import BaseIcon from '@/components/ui/BaseIcon/BaseIcon.vue';
import { AVAILABLE_ICONS } from '@Libraries/font-awesome';

export default defineComponent({
  name: 'ImageUpload',

  components: {
    BaseIcon,
  },

  data() {
    return {
      imageSource: '',
      selectedImage: null,
      preferCamera: false,
    };
  },

  methods: {
    requestImageFromUserDevice(preferCamera: boolean) {
      this.preferCamera = preferCamera;
      (this.$refs['uploadedDocument'] as HTMLInputElement).click();
    },

    handleSelectedImage() {
      const imageInput = this.$refs['uploadedDocument'] as HTMLInputElement;
      (this.selectedImage as unknown) = imageInput.files ? imageInput.files[0] : undefined;

      if (this.selectedImage) {
        this.imageSource = URL.createObjectURL(this.selectedImage);
        this.$emit('onImageSelected', this.selectedImage);
      }
    },

    reset() {
      this.selectedImage = null;
      this.imageSource = '';
      this.$emit('onReset');
    },
  },

  props: {
    label: {
      type: String,
      default: '',
    },
  },

  setup() {
    return {
      AVAILABLE_ICONS,
    };
  },
});
