import getErrorMessage from '../messages';

interface AppErrorException {
  name?: string;
  description?: string;
  code: string;
  module?: string;
  message?: string;
}

export class AppError extends Error {
  description;
  code;
  message;
  module?;

  constructor(error: AppErrorException) {
    super(error.name);
    this.name = error.name || 'AppError';
    this.description = error.description;
    this.code = error.code;
    this.message = error.message || getErrorMessage(error.code, error.module);
    this.module = error.module || null;
  }
}
