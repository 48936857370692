
import { defineComponent } from 'vue';
import { BaseIcon } from '../BaseIcon';

export default defineComponent({
  name: 'BaseRadio',

  emits: {
    'update:modelValue': (value: string | number) => value,
  },

  components: {
    BaseIcon,
  },

  props: {
    isSelected: Boolean,

    label: String,

    modelValue: [String, Number],

    radioInputId: String,

    value: [Number, String],
  },

  computed: {
    model: {
      get() {
        return this.modelValue;
      },

      set(value: string) {
        this.$emit('update:modelValue', value);
      },
    },
  },
});
