import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-full max-w-[478px]" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppText = _resolveComponent("AppText")!
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_BaseModal = _resolveComponent("BaseModal")!

  return (_openBlock(), _createBlock(_component_BaseModal, _normalizeProps(_guardReactiveProps(_ctx.$attrs)), {
    title: _withCtx(() => [
      _createVNode(_component_AppText, {
        tag: "h1",
        class: "w-full max-w-[478px]",
        size: _ctx.$appTextProps.TextSize['text-3-bold']
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('auth.logout.confirmation_modal.title')), 1)
        ]),
        _: 1
      }, 8, ["size"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_AppText, {
          tag: "p",
          class: "w-full max-w-[478px] mb-24",
          size: _ctx.$appTextProps.TextSize['text-5-regular']
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('auth.logout.confirmation_modal.message')), 1)
          ]),
          _: 1
        }, 8, ["size"]),
        _createVNode(_component_AppButton, {
          wide: "",
          class: "mb-12",
          testId: `${_ctx.testId}logout`,
          inactive: _ctx.loading,
          loading: _ctx.loading,
          size: _ctx.$appButtonProps.Size.medium,
          label: _ctx.$t('auth.logout.confirmation_modal.log_out_btn'),
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('onLogout')))
        }, null, 8, ["testId", "inactive", "loading", "size", "label"]),
        _createVNode(_component_AppButton, {
          wide: "",
          class: "mb-12",
          testId: `${_ctx.testId}_cancel`,
          inactive: _ctx.loading,
          loading: _ctx.loading,
          size: _ctx.$appButtonProps.Size.medium,
          label: _ctx.$t('auth.logout.confirmation_modal.cancel_btn'),
          version: _ctx.$appButtonProps.Version.secondary,
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('cancel')))
        }, null, 8, ["testId", "inactive", "loading", "size", "label", "version"])
      ])
    ]),
    _: 1
  }, 16))
}