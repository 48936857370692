import { App } from 'vue';
import AppToast from './AppToast.vue';
import { appToastProps } from './types';

export default {
  install: (app: App) => {
    app.component('AppToast', AppToast);
    app.config.globalProperties.$appToastProps = appToastProps;
  },
};
