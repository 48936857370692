export function hasSpecialCharacters(input?: string) {
  if (!input) return false;

  return /[\\^±!@£$%&*_+¡€#¢§¶•ªº«<>?/:;|=]/.test(input);
}

export function isMiddleNameValid(middleName?: string) {
  if (middleName) {
    return !hasSpecialCharacters(middleName) && middleName.trim().length > 1;
  }
  return true;
}

export function isLastNameValid(lastName?: string) {
  return !!lastName && !hasSpecialCharacters(lastName) && lastName.trim().length > 1;
}

export function isFirstNameValid(firstName?: string) {
  return !!firstName && !hasSpecialCharacters(firstName) && firstName.trim().length > 1;
}

export function isFilled(value?: string | number | Date) {
  if (value) {
    value = value.toString();
    return value.trim().length > 0;
  }
  return false;
}

export function isPhoneValid(phone?: string) {
  // TODO: Some countries have 10 and some have 11 digits in the phone number (excluding country code).
  // We need to revisit the phone masking in the future once we open for more countries.
  return !!phone && phone.length === 12;
}

export function isEmailValid(email?: string) {
  const regex = /^[\w+.]+@\w+\.\w{2,}(?:\.\w{2})?$/;
  return !!email && regex.test(email);
}
