
import { defineComponent } from 'vue';
import { isPasswordValid } from '@Helpers/PasswordValidation/PasswordValidation';
import { BaseCheckbox, BaseInput, inputProps } from '@BaseUi';
import { InputPassword } from '@Ui/InputPassword';
import { isEmailValid } from '@/utils';
import { ISignupFormPayload } from '../../types';
import PasswordValidationMessage from '@Modules/Auth/components/PasswordValidationMessage/PasswordValidationMessage.vue';
import { usePasswordValitation } from '@Modules/Auth';

export default defineComponent({
  name: 'SignUpForm',

  setup() {
    const { validatePassword, passwordConditions, isAllValid } = usePasswordValitation();
    return {
      inputProps,
      isPasswordValid,
      isEmailValid,
      validatePassword,
      passwordConditions,
      isAllValid,
    };
  },

  emits: {
    onSignup: (payload: ISignupFormPayload) => payload,
  },

  components: { BaseCheckbox, BaseInput, InputPassword, PasswordValidationMessage },

  props: {
    canSubmit: {
      type: Boolean,
      default: false,
    },

    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      email: '',
      password: '',
      termsAndConditions: false,
    };
  },

  computed: {
    isFormReady() {
      return (
        isEmailValid(this.email) &&
        !!this.email &&
        !!this.password &&
        !this.hasPasswordError &&
        !this.loading &&
        !!this.termsAndConditions &&
        !!this.canSubmit
      );
    },

    hasPasswordError() {
      return !this.isAllValid;
    },
  },

  methods: {
    setPassword(password: string) {
      this.password = password;
      this.validatePassword(password);
    },

    setTermsAndConditions(term: boolean) {
      this.termsAndConditions = term;
    },

    onSignup() {
      this.$emit('onSignup', {
        email: this.email,
        password: this.password,
      });
    },
  },
});
