export default [
  {
    path: '/due-diligence',
    name: 'due-diligence',
    component: () => import('@/views/DueDiligenceView/DueDiligenceIndexView.vue'),
  },
  {
    path: '/due-diligence/:questionId',
    name: 'due-diligence-question',
    component: () => import('@/views/DueDiligenceView/DueDiligenceView.vue'),
  },
];
