
import { inputProps } from '@BaseComponents/BaseInput';
import BaseInput from '@/components/base/BaseInput/BaseInput.vue';
import {
  hasSpecialCharacters,
  isFirstNameValid,
  isPhoneValid,
  isMiddleNameValid,
  isLastNameValid,
} from '@/utils';
import { computed, defineComponent, PropType } from 'vue';
import { useI18n } from 'vue-i18n';
import { IStoreKycIndividualNameFields } from '../../../types';
export default defineComponent({
  name: 'KycIndividualNameInputs',

  emits: ['update:modelValue'],

  props: {
    modelValue: {
      type: Object as PropType<IStoreKycIndividualNameFields>,
      required: true,
    },

    readonly: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit }) {
    const { t } = useI18n();
    const form = computed({
      get: () => props.modelValue,
      set: (value) => emit('update:modelValue', value),
    });

    function invalidFieldError(fieldName: string) {
      return t('forms.errors.invalid_field', { field_name: fieldName });
    }

    return {
      hasSpecialCharacters,
      inputProps,
      form,
      isPhoneValid,
      invalidFieldError,
      isFirstNameValid,
      isMiddleNameValid,
      isLastNameValid,
    };
  },

  components: { BaseInput },
});
