export default [
  {
    path: '/your-items',
    name: 'your-items',
    component: () => import('@Views/CryptoView/CryptoView.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'your-items-redirect',
    redirect: '/your-items',
  },
];
