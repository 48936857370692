
import { defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';
import { storeToRefs } from 'pinia';
import { BaseCard, BaseLoading } from '@BaseUi';
import { ToggleButton } from '@Ui/ToggleButton';
import { useOperatorStore } from '@Modules/Operator/store';
import { useGlobalToast } from '@/utils/composables';
import { useTwoFa } from '../../composables';

export default defineComponent({
  name: 'TwoFactorAuthPanel',

  components: {
    BaseCard,
    ToggleButton,
    BaseLoading,
  },

  setup() {
    const isLoading = ref(false);
    const { showError } = useGlobalToast();
    const { isTwofaEnabled } = storeToRefs(useOperatorStore());

    const router = useRouter();
    const { send2FAActivationEmail } = useTwoFa();

    async function handleOpenModal() {
      try {
        isLoading.value = true;

        if (!isTwofaEnabled.value) {
          await send2FAActivationEmail();
        } else {
          router.push('/disable-2fa');
        }
      } catch (err) {
        showError(err as AppError);
      } finally {
        isLoading.value = false;
      }
    }

    return {
      isLoading,
      isTwofaEnabled,
      handleOpenModal,
    };
  },
});
