
import { defineComponent } from 'vue';
import { AVAILABLE_ICONS } from '@Libraries/font-awesome';
import BaseIcon from '@/components/ui/BaseIcon/BaseIcon.vue';

export default defineComponent({
  name: 'StatusLabel',

  components: { BaseIcon },

  props: {
    active: {
      type: Boolean,
      default: false,
    },

    label: {
      type: String,
      required: true,
    },

    loading: {
      type: Boolean,
      default: false,
    },
  },

  setup() {
    return {
      AVAILABLE_ICONS,
    };
  },
});
