
import { defineComponent } from 'vue';
import { CardSelector } from '@Ui/CardSelector';
import { IKycEntityTypeData } from './types';
import { ACCOUNT_TYPE } from '@Shared/enums/accountType';
import { EntityTypesCards } from '../../providers';

export default defineComponent({
  name: 'EntityTypeSelector',

  components: {
    CardSelector,
  },

  props: {
    btnLabel: String,
    btnDisabled: Boolean,
    loading: Boolean,
  },

  emits: {
    onEntityTypeClick: () => true,
    onEntityTypeSelect: (newEntity: ACCOUNT_TYPE) => newEntity,
  },

  data(): IKycEntityTypeData {
    return {
      cards: EntityTypesCards,
      entityType: undefined,
    };
  },

  watch: {
    entityType(newEntity: ACCOUNT_TYPE) {
      this.$emit('onEntityTypeSelect', newEntity);
    },
  },

  methods: {
    onClick() {
      this.$emit('onEntityTypeClick');
    },
  },
});
